export default {
    title: 'विद्यालय',
    schoolName: 'विद्यालयको नाम',
    address: 'विद्यालयको ठेगाना',
    save: 'सेभ गर्नुहोस्',
    cancel: 'रद्द गर्नुहोस्',
    addSchool: 'विद्यालय थप्नुहोस्',
    dob: 'जन्म मिति',
    IEMISNumber: 'आईईएमआईएस नम्बर',
}
