import {Avatar, Badge, Box, Divider, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";



export default function UpCommingReviews({upcommingreviews}) {
    console.log(upcommingreviews);
    const navigate = useNavigate();

    const { t } = useTranslation('studentLocal');
    return (<Box background={"#ffffff"} p={"12px"}>
            <Text fontSize="20px" fontWeight={900} mb={"12px"}>
                {t('upcommingReview')}
            </Text>
            {upcommingreviews.map((s,index) =>
                index<6 &&
                <Box onClick={()=>navigate(`/students-profile/${s.studentId}`)} _hover={{color:'blue.500',background:'gray.100'}} cursor={'pointer'} >
                <Flex justifyContent={"space-between"}>
                    <Flex>


                        <Avatar src={s.studentImage} name={s.studentName}/>
                        <Box ml='3'>
                            <Text fontWeight='bold'>
                                {s.studentName}
                            </Text>
                            <Text fontSize='sm'>{`IEP of ${s.year}`}</Text>
                        </Box>
                    </Flex>
                    <Box>
                        <Badge ml='1' colorScheme={s%2===0?'green':'yellow'}>
                            {new Date(s.reviewTimestamp).toDateString()}
                        </Badge>
                    </Box>
                </Flex>


                <Divider mt={"6px"} mb={"12px"} color={"#1b73e8"} borderWidth={"1px"}/>
            </Box>)}
        </Box>)
}
