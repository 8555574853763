import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import HomePage from "../pages/dashboard/HomePage";
import LoginPage from "../pages/login/LoginPage";
import StudentsPage from "../pages/student/StudentsPage";
import ManageStudent from "../pages/student/ManageStudent";
import {isLogin} from "../utils/common";
import ManageSchool from "../pages/school/ManageSchool";
import SchoolPage from "../pages/school/SchoolPage";
import UserPage from "../pages/users/UserPage";
import ManageUsers from "../pages/users/ManageUsers";
import StudentProfile from "../pages/iep/StudentProfile";
import IepPage from "../pages/iep/IepPage";
import IepSteps from "../pages/iep/IepSteps";
import StudentDashboard from "../pages/dashboard/StudentDashboard";
import ObjectivesGolasReviewDashboard from '../pages/dashboard/ObjectivesGolasReviewDashboard';

export const ROOT_PATH = '/';
export const LOGIN = '/login';
export const STUDENTS = '/students';
export const MY_STUDENTS = '/my_students';
export const MANAGESTUDENTS = '/students/:id';
export const STUDENTPROFILE = '/students-profile/:id';
export const MANAGESCHOOL = '/school/:id';
export const SCHOOL = '/school';
export const USER = '/user';
export const MANAGEUSER = '/user/:id';
export const IEP_PAGE = '/iep/:iepId';
export const IEP_STEPS = '/iep/:iepId/:step';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_STUDENT = '/dashboard-student';
export const DASHBOARD_ObjectivesGolasReview = '/dashboard-objectives';
const PageRoutes = (): JSX.Element => {
    const navigate = useNavigate();

    useEffect(() => {
        if(!isLogin()){
            navigate('/login');
        }
        // eslint-disable-next-line no-restricted-globals
    }, [location.pathname]);

if(isLogin()) {
    // @ts-ignore
    return (
        <Routes>
            <Route path={ROOT_PATH} element={<Navigate replace to={MY_STUDENTS} />}/>
            <Route path={LOGIN} element={<Navigate replace to={ROOT_PATH} />}/>
            <Route path={STUDENTS} element={<StudentsPage/>}/>
            <Route path={MY_STUDENTS} element={<StudentsPage/>}/>
            <Route path={MANAGESTUDENTS} element={<ManageStudent/>}/>
            <Route path={MANAGESCHOOL} element={<ManageSchool shouldReturn={false} role="route" returnUserID={(id:string)=>{console.log(id)}}/>}/>
            <Route path={SCHOOL} element={<SchoolPage/>}/>
            <Route path={USER} element={<UserPage/>}/>
            <Route path={MANAGEUSER} element={<ManageUsers shouldReturn={false} role="route" returnUserID={(id:string)=>{console.log(id)}}/>}/>
            <Route path={STUDENTPROFILE} element={<StudentProfile/>}/>
            <Route path={IEP_PAGE} element={<IepPage/>}/>
            <Route path={IEP_STEPS} element={<IepSteps/>}/>
            <Route path={DASHBOARD} element={<HomePage/>}/>
            <Route path={DASHBOARD_STUDENT} element={<StudentDashboard/>}/>
            <Route path={DASHBOARD_ObjectivesGolasReview} element={<ObjectivesGolasReviewDashboard/>}/>
            {/*<PrivateRoute exact path={PATH_ADMINISTRATION} userIsAuth={isAuthenticated} component={AdministrationLayout} />*/}
        </Routes>
    );
}
return  <Routes>
    <Route path={ROOT_PATH} element={<Navigate replace to={LOGIN} />}/>
    <Route path={LOGIN} element={<LoginPage/>}/>

</Routes>
};

export default PageRoutes;
