import {
    Box,
    Button,
    Card,
    Divider,
    Flex, IconButton,
    Image, Input,
    Skeleton,
    SkeletonCircle,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {RelationShip} from "../../interfaes/student";
import {Roles} from "../../interfaes/user";
import {IEP} from "../../interfaes/iep";
import {GiRollingEnergy, GiStairsGoal} from 'react-icons/gi';
import {GoChecklist} from 'react-icons/go';
import {MdBuild, MdDelete, MdRateReview} from 'react-icons/md';
import {AiOutlineFileDone} from 'react-icons/ai';
import {addDailyLog, completeIEP, deleteLog, onGetLogs, onGetSingleIEP, updateDailyLog} from "../../http/iepAPis";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import { IoMdDoneAll } from "react-icons/io";
import {GrEdit} from "react-icons/gr";
import {timeDifference} from "../../utils/common";
import {NepaliDatePicker} from "nepali-datepicker-reactjs";
import NepaliDateConverter from "nepali-date-converter";
import NepaliDate from "nepali-date-converter";


export default function IepPage() {
    const {t} = useTranslation('iep');
    const {state} = useLocation();
    const navigate = useNavigate();
    const toast = useToast()
    const {isOpen, onOpen, onClose} = useDisclosure();


    const [loading, setLoading] = useState(true);
    const [managingLog, setManagingLog] = useState(false);
    const [loadingIEPCOMPLETE, setLoadingIEPCOMPLETE] = useState(false);


    const isIEPInProgress = (): string => {
        let color: string = 'white';
        const iep: IEP = state.iep;
        if (iep?.completedSteps?.includes(1)) {
            color = '#00ff48';
            return color;
        }

        if (iep.iepSteps?.strengthAndLimitations) {
            for (let i = 0; i < iep.iepSteps?.strengthAndLimitations.length; i++) {
                if (iep.iepSteps?.strengthAndLimitations[i].withAnswer) {
                    color = 'blue.100';
                    break;
                }
            }
        }
        return color;


    }

    const isIEPInProgressForCheckList = (): string => {
        let color: string = 'white';
        const iep: IEP = state.iep;
        if (iep?.completedSteps?.includes(2)) {
            color = '#00ff48';
        }
        return color;


    }
    const [date, setDate] = useState<string>('');


    const isIEPInProgressForCheckListFinal = (): boolean => {
        let hasAnswers: boolean = false;
        // const iep: IEP = state.iep;
        //
        // if(iep.iepSteps?.finalChecklist) {
        //     for (let i = 0; i < iep.iepSteps?.finalChecklist.length; i++) {
        //         if(iep.iepSteps.finalChecklist[i].checklist) {
        //             for (let j = 0; j < iep.iepSteps.finalChecklist[i].checklist.length; j++) {
        //                 if (iep.iepSteps.finalChecklist[i].checklist[j].withAnswer) {
        //                     hasAnswers = true;
        //                     break;
        //                 }
        //             }
        //         }
        //     }
        // }
        return hasAnswers;


    }

    const isIEPInProgressForGoals = (): boolean => {
        let hasAnswers: boolean = false;
        return hasAnswers;


    }

    const {iepId} = useParams();

    const onCompleteIEP = async () => {
        if(logToEdit){
            try{
                setLoadingIEPCOMPLETE(true);
                await deleteLog(logToEdit.id);
                toast({
                    title: 'Log Deleted',
                    description: "Log Deleted Successfully",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                setLogToEdit(undefined);
                onClose();
                onGetLog();
            }catch (e) {
                console.log(e);
                toast({
                    title: 'Failed',
                    description: "Could not add log at the moment.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }finally {
                setLoadingIEPCOMPLETE(false);
            }
        }else {
            try {
                if (iepId) {
                    setLoadingIEPCOMPLETE(true);
                    await completeIEP(iepId);
                    toast({
                        title: 'IEP Completed',
                        description: "IEP Completed Successfully",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                    onClose();
                    navigate(-1);
                }
            } catch (e) {
                console.log(e);
                toast({
                    title: 'IEP Failed',
                    description: "Could not complete IEP at the moment.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            } finally {
                setLoadingIEPCOMPLETE(false)
            }
        }
    }


    const [iepFormAPI, setIEPFormAPI] = useState<IEP>();
    const [log, setLog] = useState<string>('');
    const [time, setTime] = useState<string>('');
    const [logToEdit, setLogToEdit] = useState<any>(undefined);
    const [logObj, setLogObj] = useState<any>();

    const getIEP = async () => {
        if (iepId && state) {
            try {
                const response = await onGetSingleIEP(state?.student.studentId, iepId);
                setIEPFormAPI(response.data);
            } catch (e) {
                console.log(e);
            }
            setLoading(false)
        }
    }
    useEffect(() => {
        setLoading(true)
        onGetLog();
        getIEP();
    }, [state, iepId]);

    useEffect(() => {
        const handleBackNavigation = () => {
            getIEP();
        };

        // Listen for the popstate event
        window.addEventListener('popstate', handleBackNavigation);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handleBackNavigation);
        };
    }, []);

    const handleLog=async () => {
        try {
            if(iepId) {
                setManagingLog(true);
                if(logToEdit){
                    await updateDailyLog(iepId, log,logToEdit.id,date,time);
                }else{
                    await addDailyLog(iepId, log,date,time);
                }

                onGetLog();
                setLog('');
                setTime('');
                setDate('');
            }
        } catch (e) {
            console.log(e)
        }finally {
            setManagingLog(false);
            setLogToEdit(undefined);
        }
    }

    const onGetLog=async () => {
        try {
            if(iepId) {
                setManagingLog(true);
                const response = await onGetLogs(iepId);
                setLogObj(response.data.content);
            }
        } catch (e) {
            console.log(e);
        }finally {
            setManagingLog(false);
        }
    }
    const materialColors = [
        '#2196F3', '#4CAF50', '#FFC107', '#E91E63', '#9C27B0',
        '#FF9800', '#795548', '#00BCD4', '#8BC34A', '#FF5722'
    ];

    if (state && !loading) {
        const parent = state.student.relationships?.filter((r: RelationShip) => r.relation === Roles.PARENT);
        // @ts-ignore
        const iep: IEP = iepFormAPI;

        return (<Box>
            <Flex justifyContent="space-between">
                <Text fontSize="30px" fontWeight={900}>
                    {t('iep')} : {iep?.year}
                </Text>
            </Flex>
            <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
                <Flex>
                    <Box w={"10%"}>
                        <Image
                            borderRadius='full'
                            w={"100%"}
                            src={state.studentImage}
                            alt='Dan Abramov'
                            fallbackSrc='https://via.placeholder.com/150'
                        />
                    </Box>
                    <Box w={"2%"}/>
                    <Box w={"78%"}>
                        <Text fontWeight={'900'} fontSize={'24px'}>
                            {state.student.fullName}
                        </Text>
                        <Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                            {`${t('address')} : ${state.student.province} | ${state.student.district} | ${state.student.localLevel} | ${state.student.localAddress}`}
                        </Text>
                        {parent && (<Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                            {`${t('parentInformation')} : ${parent[0].relatedUser.displayName} | ${t('relation')} : ${parent[0].relation} | ${t('contact')} : ${parent[0].relatedUser.phone}`}
                        </Text>)}
                        <Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                            {`${t('level')} : ${iep.level} | ${t('section')} : ${iep.section} | ${t('roll')} : ${iep.roll}`}
                        </Text>
                    </Box>
                </Flex>
                <Text fontSize="24px" fontWeight={900} mt={'12px'}>
                    {t('iepProcess')}
                </Text>
                <Box display='grid'
                     mt={'12px'}
                     gridTemplateColumns={'24% 24% 24% 24%'}
                     gridGap='14px'>


                    <Card p={'12px'}
                          border={iep?.completedSteps?.includes(1) ? '1px solid green' : '1px solid'}
                          _hover={{background:  '#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(1) ? 'double':'dashed' }
                          cursor={'pointer'}

                          onClick={() => {

                              navigate(`/iep/${iep.iepId}/step1`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })

                          }
                          }
                         >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <GiRollingEnergy size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step1')}</Text>
                                    {iep?.completedSteps?.includes(1)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                        )}
                                </Flex>

                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('stepTitle')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                    <Card p={'12px'}

                          border={iep?.completedSteps?.includes(2) ? '1px solid green' : '1px solid'}
                          _hover={{background: '#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(2) ? 'double':'dashed' }
                          cursor={'pointer'}
                          onClick={() => {
                              navigate(`/iep/${iep.iepId}/step2`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })
                          }}
                    >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <GoChecklist size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step2')}</Text>
                                    {iep?.completedSteps?.includes(2)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                    )}
                                </Flex>
                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('step2Title')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                    <Card p={'12px'}
                          border={iep?.completedSteps?.includes(3) ? '1px solid green' : '1px solid'}
                          _hover={{background:  '#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(3) ? 'double':'dashed' }
                          cursor={'pointer'}
                          onClick={() => {

                              navigate(`/iep/${iep.iepId}/step3`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })

                          }
                          }
                    >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <GiStairsGoal size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step3')}</Text>
                                    {iep?.completedSteps?.includes(3)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                    )}
                                </Flex>
                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('step3Title')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                    <Card p={'12px'}
                          border={iep?.completedSteps?.includes(4) ? '1px solid green' : '1px solid'}
                          _hover={{background: '#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(4) ? 'double':'dashed' }
                          cursor={'pointer'}
                          onClick={() => {

                              navigate(`/iep/${iep.iepId}/step4`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })
                          }

                          }
                    >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <MdRateReview size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step4')}</Text>
                                    {iep?.completedSteps?.includes(4)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                    )}
                                </Flex>
                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('step4Title')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                    <Card p={'12px'}
                          border={iep?.completedSteps?.includes(5) ? '1px solid green' : '1px solid'}
                          _hover={{background:'#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(5) ? 'double':'dashed' }
                          cursor={'pointer'}
                          onClick={() => {
                              navigate(`/iep/${iep.iepId}/step5`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })

                          }}
                         >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <MdRateReview size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step5')}</Text>
                                    {iep?.completedSteps?.includes(5)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                    )}
                                </Flex>
                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('step5Title')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                    <Card p={'12px'}
                          onClick={() => {

                              navigate(`/iep/${iep.iepId}/step6`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })

                          }}
                          border={iep?.completedSteps?.includes(6) ? '1px solid green' : '1px solid'}
                          _hover={{background:'#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(6) ? 'double':'dashed' }
                          cursor={'pointer'}
                     >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <MdRateReview size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step6')}</Text>
                                    {iep?.completedSteps?.includes(6)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                    )}
                                </Flex>
                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('step6Title')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                    <Card p={'12px'}

                          onClick={() => {
                              navigate(`/iep/${iep.iepId}/step7`, {
                                  state: {
                                      student: state.student,
                                      iep: state.iep
                                  }
                              })

                          }}
                          border={iep?.completedSteps?.includes(7) ? '1px solid green' : '1px solid'}
                          _hover={{background:  '#f5f1f1'}}
                          borderStyle={iep?.completedSteps?.includes(7) ? 'double':'dashed' }
                          cursor={'pointer'}
                         >
                        <Flex>
                            <Flex alignItems='center'
                                  justifyContent='center'>
                                <AiOutlineFileDone size={"40px"}/>
                            </Flex>
                            <Box ml={"12px"}>
                                <Flex justifyContent={"space-between"}>
                                    <Text fontWeight={'900'} fontSize={'16px'}>{t('step7')}</Text>
                                    {iep?.completedSteps?.includes(7)  && (
                                        <IoMdDoneAll color={'#2b733f'} size={'20px'}/>
                                    )}
                                </Flex>
                                <Text fontWeight={'500'} fontSize={'16px'} color={'gray.500'}>{t('step7Title')}</Text>

                            </Box>
                        </Flex>


                    </Card>
                </Box>
            </Box>
            <Flex mt={'16px'} justifyContent={'flex-end'}>
                <Button colorScheme='green' size='lg' onClick={() => {
                    if (iep.completedSteps?.length === 7) {
                        onOpen()
                    } else {
                        toast({
                            title: 'Complete All The Steps!',
                            description: "You need to complete all the steps to complete IEP.",
                            status: 'warning',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                }
                }>
                    {t('completeIEP')}
                </Button>
            </Flex>
            <Divider mt={'12px'} mb={'12px'}/>
            <Box background={'white'} p={'12px'} borderRadius={'12px'}>
                <Text fontSize="30px" fontWeight={900}>
                    {t('logs')}
                </Text>
                <Flex mt={'12px'} p={'10px'} background={'yellow.100'}>
                    <Box w={'20%'} mr={'4px'}>
                    <NepaliDatePicker inputClassName="form-control"
                                      className=""

                                      value={date}
                        onChange={(value: string) => {


                            setDate(value)
                        }}
                                      options={{ calenderLocale: "en", valueLocale: "en" }}

                    />
                    </Box>
                    <Input
                        w={'70%'}
                        onChange={(e) => setLog(e.target.value)}
                        value={log}
                        mr={'4px'}
                        variant={"filled"} placeholder={t('addLogs')} required/>
                    <Input
                        w={'10%'}
                        onChange={(e) => setTime(e.target.value)}
                        value={time}
                        mr={'4px'}
                        type={'number'}

                        variant={"filled"} placeholder={t('addTime')} required/>
                    <Button leftIcon={<MdBuild />} colorScheme='pink' variant='solid' ml={'12px'} isDisabled={!log || managingLog ||!date } isLoading={managingLog} onClick={handleLog}>
                        {t('save')}
                    </Button>
                </Flex>
                <Divider mt={'12px'} mb={'12px'}/>
                {logObj && (
                <Box >
                    {logObj.map((e:any,index:number)=>
                    <Flex background={materialColors[index]} mt={'12px'} p={'10px'} borderRadius={'10px'} justifyContent={'space-between'} alignContent={'center'}>
                        <Box>

                            <Text fontSize={'16px'} fontWeight={'900'}>{e.remarks}</Text>
                            <Text fontSize={'14px'} color={'grey.500'}>{e.dateString} | {e.workHours} hours</Text>
                        </Box>
                        <Flex>
                            <IconButton
                                colorScheme='gray'
                                aria-label='Search database'
                                icon={ <GrEdit size="20px" color={"red"}/>}
                                onClick={()=>{
                                    setLogToEdit(e);
                                    setLog(e.remarks);
                                    setTime(e.workHours);
                                    setDate(e.dateString);

                                }}
                            />
                            <IconButton
                                ml={'10px'}
                                colorScheme='gray'
                                aria-label='Search database'
                                icon={ <MdDelete size="20px" color={"red"}/>}
                                onClick={()=>{
                                    setLogToEdit(e);
                                    onOpen();

                                }}
                            />
                        </Flex>
                    </Flex>
                    )}
                </Box>
                    )}
            </Box>
            <CoustomAlertDialog
                onClose={()=>{
                    setLogToEdit(undefined);
                    onClose();
                }}
                onOpen={onOpen}
                isOpen={isOpen}
                onMainAction={onCompleteIEP}
                loading={loadingIEPCOMPLETE}
                warning={logToEdit?false:true}
            />
        </Box>)
    } else {
        return (
            <Box>
                <Skeleton height='30px' width={'60%'}/>
                <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
                    <Flex>
                        <Box w={"10%"}>
                            <SkeletonCircle size='30' />
                        </Box>
                        <Box w={"2%"}/>
                        <Box w={"78%"}>
                            <Skeleton height='20px' width={'60%'} />
                            <Skeleton height='20px' width={'40%'} />
                            <Skeleton height='20px' width={'20%'} />
                        </Box>
                    </Flex>
                <Box display='grid'
                     mt={'12px'}
                     gridTemplateColumns={'24% 24% 24% 24%'}
                     gridGap='14px'>
                    {[1, 2, 3, 4, 5, 6, 7].map(() =>
                        <Skeleton height='100px'/>
                    )}

                </Box>
                </Box>
            </Box>);
    }
}
