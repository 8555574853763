export default {
    displayName: 'पूरा नाम',
    gender: 'लिङ्ग',
    address: 'ठेगाना',
    phone: 'फोन',
    title: 'प्रयोगकर्ताहरू',
    addUsers: 'प्रयोगकर्ता थप्नुहोस्',
    userList: 'प्रयोगकर्ताहरूको सूची',
    description: 'विवरण',
    male: 'पुरुष',
    female: 'महिला',
    others: 'अन्य',
    uploadPhoto: 'तस्बिर अपलोड गर्नुहोस्',
    TEACHER: 'शिक्षक',
    PARENT: 'अभिभावक',
    ADMIN: 'प्रशासक',
    MODERATOR: 'मोडरेटर',
    THERAPIST: 'थेरापिस्ट',
    selectRole:'भूमिका चयन गर्नुहोस्',
    password:'पासवर्ड',
    save:'सेभ गर्नुहोस्',
    cancel:'रद्द गर्नुहोस्',
    roles:'भूमिकाहरू',
    edit:'प्रयोगकर्ता सम्पादन गर्नुहोस्',
    delete:'प्रयोगकर्ता हटाउनुहोस्',
    changePassword:'पासवर्ड परिवर्तन गर्नुहोस्',
    resSetPassword:'पासवर्ड पुन: सेट गर्नुहोस्:',
    changeRole:'भूमिका परिवर्तन गर्नुहोस्',
    associatedStudentsCount:'विद्यार्थी गणना',
}
