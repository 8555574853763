import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel,
    Box,
    Button,
    Card,
    Flex,
    FormControl,
    FormLabel,
    IconButton, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Select, Skeleton, Stack,
    Text,
    Textarea, useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {
    addBulkAnswers,
    addNewGoal,
    addNewObjectives,
    completeIEPStep,
    deleteQuestion, deleteQuestionGoal, deleteQuestionObjectives, onGetHistory,
    onSaveAnswers
} from "../../http/iepAPis";
import {MdAdd, MdHistory} from "react-icons/all";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {MdDelete} from "react-icons/md";
import {canAddStudent} from "../../utils/common";

export default function GoalsAndObjectivesView({goalsAndObjectives,iepId,onGetData,isCompleted,reviewStep,goalId,onUpdateObjetives}){
    const toast = useToast()

    const {t} = useTranslation('iep');
    const [bulkAnswers,setBulkAnswers]=useState(new Map());
    const [loadingObjectives, setLoadingObjectives] = useState(false);
    const [loadingGoal, setLoadingGoal] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [isDelete,setIsDelete]=useState(undefined);
    const [deleteGoal,setIsDeleteGoal]=useState(undefined);
    const { isOpen:isOpenHistory, onOpen:onOpenHistory, onClose:onCloseHistory } = useDisclosure()
    const [loadingHistory,setLoadingHistory]=useState(false);
    const [historyRes,setHistoryRest]=useState([]);


    const btnRef = React.useRef(null)
    const onGetVersionHistory=async (id) => {
        try {
            setLoadingHistory(true);
            onOpenHistory();
            const response = await onGetHistory(iepId,id);
            setHistoryRest(response.data);
            setLoadingHistory(false);
        }catch (e) {
            console.log(e);
            setLoadingHistory(false);
        }
    }

    const handleAddGoal=async () => {
        try {
            setLoadingGoal(true);
            await handleaAddBulkAnswers(false);
            await addNewGoal(iepId);
            toast({
                title: 'New Goal Added!!!',
                description: "New Goal Added Successfully",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            onGetData();
        } catch (e) {
            console.log(e)
            toast({
                title: 'Error!!!',
                description: "Could not add goal at the moment",
                status: 'error',
                duration: 3000,
                isClosable: true,
            });

        }finally {
            setLoadingGoal(false);
        }
    }
    const handleAddObjectives=async (goalQuestionID,quater) => {
        try {
            setLoadingObjectives(true);
            await handleaAddBulkAnswers(false);
            await addNewObjectives(iepId,goalQuestionID,quater);
            onGetData();
            toast({
                title: 'Success!!',
                description: "Objectives Added Successfully",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (e) {
            console.log(e)
            toast({
                title: 'Error!!',
                description: "Objectives Could not be added at the moment",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            setLoadingObjectives(false);
        }
    }

    const handleaAddBulkAnswers=async (showtoast) => {
        try {
            setLoading(true);
            await addBulkAnswers([...bulkAnswers.values()]);
            onGetData();
            if(showtoast) {
                toast({
                    title: 'Step Saved',
                    description: "Goal and Objective Step Saved",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } catch (e) {
            console.log(e)
            if(showtoast) {
                toast({
                    title: 'Error!!!',
                    description: "Could Not Save Goal and Objective Step",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }finally {
            setLoading(false);
        }
    }

    const handleChange=(key,value)=>{
        setBulkAnswers((prev)=>{
            const newMap = new Map(prev);
            newMap.set(key,value);
            return newMap;
        })    }

    useEffect(()=>{
        setBulkAnswerValue();
    },[goalsAndObjectives])
    const setBulkAnswerValue=()=>{
        if(Object.keys(goalsAndObjectives).length !== 0) {
            let map = new Map();
            goalsAndObjectives.firstQuarterGoals.forEach((goal) => {
                if (goal?.area?.answer?.content) {
                    const obj = {
                        "questionId": goal.area.questionId,
                        "iepId": iepId,
                        "type": goal.area.type,
                        "content": goal.area.answer.content,
                        "remarks": ""
                    };
                    map.set(goal.area.questionId, obj)
                }
                if (goal?.subArea?.answer?.content) {
                    const objSubArea = {
                        "questionId": goal.subArea.questionId,
                        "iepId": iepId,
                        "type": goal.subArea.type,
                        "content": goal.subArea.answer.content,
                        "remarks": ""
                    };
                    map.set(goal.subArea.questionId, objSubArea)
                }
                if (goal?.goal?.answer?.content) {
                    const objGoal = {
                        "questionId": goal.goal.questionId,
                        "iepId": iepId,
                        "type": goal.goal.type,
                        "content": goal.goal.answer.content,
                        "remarks": goalId===undefined?"":goal.goal.questionId
                    };
                    map.set(goal.goal.questionId, objGoal)
                }


                goal.objectives.forEach((o) => {
                    if (o.objectiveAnswer?.content) {
                        const objective = {
                            "questionId": o.objectiveQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.objectiveAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.objectiveQuestionId, objective)
                    }
                    if (o.activityAnswer?.content) {
                        const activity = {
                            "questionId": o.activityQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.activityAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.activityQuestionId, activity)
                    }
                    if (o.materialAnswer?.content) {
                        const material = {
                            "questionId": o.materialQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.materialAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.materialQuestionId, material)
                    }
                    if (o.responsibleAnswer?.content) {
                        const responsible = {
                            "questionId": o.responsibleQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.responsibleAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.responsibleQuestionId, responsible)
                    }
                    if (o.communicationStrategyAnswer?.content) {
                        const responsible = {
                            "questionId": o.communicationStrategyQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.communicationStrategyAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.communicationStrategyQuestionId, responsible)
                    }
                })
            });
            goalsAndObjectives.secondQuarterGoals.forEach((goal) => {
                goal.objectives.forEach((o) => {
                    if (o.objectiveAnswer?.content) {
                        const objective = {
                            "questionId": o.objectiveQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.objectiveAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.objectiveQuestionId, objective)
                    }
                    if (o.activityAnswer?.content) {
                        const activity = {
                            "questionId": o.activityQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.activityAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.activityQuestionId, activity)
                    }
                    if (o.materialAnswer?.content) {
                        const material = {
                            "questionId": o.materialQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.materialAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.materialQuestionId, material)
                    }
                    if (o.responsibleAnswer?.content) {
                        const responsible = {
                            "questionId": o.responsibleQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.responsibleAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.responsibleQuestionId, responsible)
                    }
                    if (o.communicationStrategyAnswer?.content) {
                        const responsible = {
                            "questionId": o.communicationStrategyQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.communicationStrategyAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.communicationStrategyQuestionId, responsible)
                    }
                })
            });
            goalsAndObjectives.thirdQuarterGoals.forEach((goal) => {
                goal.objectives.forEach((o) => {
                    if (o.objectiveAnswer?.content) {
                        const objective = {
                            "questionId": o.objectiveQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.objectiveAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.objectiveQuestionId, objective)
                    }
                    if (o.activityAnswer?.content) {
                        const activity = {
                            "questionId": o.activityQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.activityAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.activityQuestionId, activity)
                    }
                    if (o.materialAnswer?.content) {
                        const material = {
                            "questionId": o.materialQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.materialAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.materialQuestionId, material)
                    }
                    if (o.responsibleAnswer?.content) {
                        const responsible = {
                            "questionId": o.responsibleQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.responsibleAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.responsibleQuestionId, responsible)
                    }
                    if (o.communicationStrategyAnswer?.content) {
                        const responsible = {
                            "questionId": o.communicationStrategyQuestionId,
                            "iepId": iepId,
                            "type": 'TEXT',
                            "content": o.communicationStrategyAnswer?.content,
                            "remarks": goalId===undefined?"":goal.goal.questionId
                        };
                        map.set(o.communicationStrategyQuestionId, responsible)
                    }
                })
            });
            setBulkAnswers(map);
        }
    }
    const handleClose = () => {
        setIsDelete(undefined);
        setIsDeleteGoal(false);
        onClose();


    }
    const onCompleteIEP = async () => {
        try {
            setLoading(true)
            if(isDelete){
                if(deleteGoal){
                    await deleteQuestionGoal(isDelete);
                }else{
                    await deleteQuestionObjectives(isDelete);
                }
                handleClose();

                toast({
                    title: 'Delete',
                    description: "Deleted Succesfully",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            }else{
                await completeIEPStep(iepId,3,!isCompleted);
                toast({
                    title: 'Step Completed',
                    description: "Goal and Objective Step Completed",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                navigate(-1);
            }

            onGetData();
        } catch (e) {
            if(isDelete){
                toast({
                    title: 'Error',
                    description: "Could not delete",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                setIsDelete(undefined);
            }else {
                toast({
                    title: 'Error',
                    description: "Goal and Objective Could not be completed !",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        } finally {
            setLoading(false);
            handleClose();
        }


    }

    const shouldBeVisiblae=(currentGoalId)=>{
        if(goalId===undefined){
            return true;
        }
        if(goalId===currentGoalId){
            return true;
        }
        return false;
    }
    const onReviewHistory=()=>{
        onUpdateObjetives(bulkAnswers);

    }


    const onGetSubArea=(qID)=>{
        const data=bulkAnswers.get(qID);
        if(data){
            const view=[];
            if(data.content==='Basic'){
            ['Independence Skills', 'Gross Motors Skills', 'Fine Motor Skills', 'Social Skills', 'Emotional Skills', 'Communication Skills'].forEach((v)=>{
                view.push(<option value={v}>{v}</option>)
            })
                return view;
                //if Language- Pre learning, Reading Skills,
                // if Math- Math Skills, And if Others- Other Skills)
            }
            if(data.content==='Language'){
                ['Pre learning','Reading Skills'].forEach((v)=>{
                    view.push(<option value={v}>{v}</option>)
                })
                return view;


            }
            if(data.content==='Maths'){
                ['Math Skills'].forEach((v)=>{
                    view.push(<option value={v}>{v}</option>)
                })
                return view;
                //if Language- Pre learning, Reading Skills,
                // if Math- , And if Others- Other Skills)
            }
            if(data.content==='Others'){
                ['Other Skills'].forEach((v)=>{
                    view.push(<option value={v}>{v}</option>)
                })
                return view;
            }
        }
       return <Box/>
    }



    // @ts-ignore
    return(
        <Box mt={'12px'}>
            {goalId===undefined && (
            <Flex justify={'space-between'}>
            <Text fontWeight={'900'} fontSize={'16px'}>{t('step3')} : {t('step3Title')}</Text>
            <Button rightIcon={<MdAdd />} colorScheme='blue' variant='solid' onClick={handleAddGoal}  isLoading={loadingGoal} isDisabled={loadingGoal || isCompleted ||!canAddStudent()}>
                {t('addGoal')}
            </Button>
            </Flex>
            )}
            {Object.keys(goalsAndObjectives).length !== 0 && (
            <Box>
            <Accordion defaultIndex={[0]} allowMultiple>
            {goalsAndObjectives?.firstQuarterGoals?.map((v,index)=>
                shouldBeVisiblae(v?.goal?.questionId) &&
                <AccordionItem mt={'12px'}>
                    <h2>
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontWeight={900}>
                                Goal {index+1} {v.goal?bulkAnswers.get(v.goal.questionId)?bulkAnswers.get(v.goal.questionId).content:'':''}
                            </Box>
                            {!goalId && (
                            <IconButton
                                justifyItems={'center'}
                                isRound={true}
                                variant='solid'
                                colorScheme='red'
                                aria-label='Done'
                                fontSize='20px'
                                ml={'12px'}
                                isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                                icon={<MdDelete />}
                                onClick={()=>{
                                    setIsDelete(v.goal.questionId);
                                    setIsDeleteGoal(true);
                                    onOpen();
                                }}
                            />
                                )}
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel >

            <Box border={'1px solid blue'} borderRadius={'10px'} p={'12px'} mt={'12px'} background={'blue.100'}>
                {!goalId && (
                <Flex>
                    <Box w={'30%'}>
                        <Text> {t('area')}</Text>
                        {v.area && (
                        <Select placeholder='Area'  variant='filled'
                                isDisabled={isCompleted || !canAddStudent()}
                                _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                value={bulkAnswers.get(v.area.questionId)?bulkAnswers.get(v.area.questionId).content:''}
                                onChange={(e)=>{
                                    const obj={
                                        "questionId": v.area.questionId,
                                        "iepId": iepId,
                                        "type": v.area.type,
                                        "content": e.target.value,
                                        "remarks": ""
                                    };
                                    handleChange(v.area.questionId,obj)}
                                }>
                            {['Basic', 'Language', 'Maths' , 'Others'].map((l)=>
                                <option value={l}>{l}</option>
                            )}


                        </Select>)}
                        <Text mt={'12px'}> {t('subArea')}</Text>
                        {v.subArea && (
                        <Select placeholder='Sub Area'   variant='filled'
                                isDisabled={isCompleted || !canAddStudent() || !bulkAnswers.get(v.area.questionId)}
                                _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                value={bulkAnswers.get(v.subArea.questionId)?bulkAnswers.get(v.subArea.questionId).content:''}
                                onChange={(e)=>{
                                    const obj={
                                        "questionId": v.subArea.questionId,
                                        "iepId": iepId,
                                        "type": v.subArea.type,
                                        "content": e.target.value,
                                        "remarks": ""
                                    };
                                    handleChange(v.subArea.questionId,obj)}
                                }
                        >
                            {onGetSubArea(v.area.questionId)}
                        </Select>
                            )}
                    </Box>
                    <Box w={'70%'} ml={'16px'}>
                        <Text> {t('goal')}</Text>
                        {v.goal && (
                        <Textarea placeholder= {t('enterGoal')}
                                  isDisabled={isCompleted || !canAddStudent()}
                                  _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                  value={bulkAnswers.get(v.goal.questionId)?bulkAnswers.get(v.goal.questionId).content:''}
                                  onChange={(e)=>{
                                      const obj={
                                          "questionId": v.goal.questionId,
                                          "iepId": iepId,
                                          "type": v.goal.type,
                                          "content": e.target.value,
                                          "remarks": ""
                                      };
                                      handleChange(v.goal.questionId,obj)}
                                  }
                                  variant='filled'/>
                            )}
                    </Box>
                </Flex>
                    )}
                {reviewStep===0 && (
                <Box>
                <Flex justifyContent={'space-between'} mt={'12px'} mb={'12px'}>
                    <Text mt={'12px'} fontSize={'16px'} fontWeight={900} color={'blue'}> {t('objectFristQtr')}</Text>
                    <IconButton

                        isRound={true}
                        variant='solid'
                        colorScheme='teal'
                        aria-label='Done'
                        fontSize='20px'
                        isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                        icon={<MdAdd />}
                        onClick={()=>handleAddObjectives(goalsAndObjectives?.firstQuarterGoals[index]?.goal.questionId,1)}
                    />
                </Flex>

                {goalsAndObjectives?.firstQuarterGoals[index]?.objectives.length>0  &&(
                <Box border={'1px solid'}  borderRadius={'10px'} p={'10px'}>
                    <Flex mt={'12px'}>
                        <Text w={'20%'} fontWeight={900}>{t('objectives')}</Text>
                        <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('activities')}</Text>
                        <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('materials')}</Text>
                        <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('responsible')}</Text>
                        <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('communication')}</Text>
                    </Flex>
                    {goalsAndObjectives?.firstQuarterGoals[index]?.objectives?.map((o)=>
                    <Flex mt={'8px'} alignItems= 'center'>
                        <Box w={'20%'}> <Textarea placeholder= {t('enterObjectives')} variant='filled'
                                                  isDisabled={isCompleted || !canAddStudent()}
                                                  _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                  value={bulkAnswers.get(o.objectiveQuestionId)?bulkAnswers.get(o.objectiveQuestionId).content:''}
                                                  onChange={(e)=>{
                                                      const obj={
                                                          "questionId": o.objectiveQuestionId,
                                                          "iepId": iepId,
                                                          "type": 'TEXT',
                                                          "content": e.target.value,
                                                          "remarks": ""
                                                      };

                                                      handleChange(o.objectiveQuestionId,obj)}
                                                  }
                        /></Box>
                        <Box w={'20%'} ml={'12px'}> <Textarea placeholder= {t('enterActivites')}
                                                              isDisabled={isCompleted || !canAddStudent()}
                                                              _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                              value={bulkAnswers.get(o.activityQuestionId)?bulkAnswers.get(o.activityQuestionId).content:''}
                                                              onChange={(e)=>{
                                                                  const obj={
                                                                      "questionId": o.activityQuestionId,
                                                                      "iepId": iepId,
                                                                      "type": 'TEXT',
                                                                      "content": e.target.value,
                                                                      "remarks": ""
                                                                  };
                                                                  handleChange(o.activityQuestionId,obj)}
                                                              }
                                                              variant='filled'/></Box>
                        <Box w={'20%'} ml={'12px'}> <Textarea placeholder={t('enterMaterials')}
                                                              _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                              isDisabled={isCompleted || !canAddStudent()}
                                                              value={bulkAnswers.get(o.materialQuestionId)?bulkAnswers.get(o.materialQuestionId).content:''}
                                                              onChange={(e)=>{
                                                                  const obj={
                                                                      "questionId": o.materialQuestionId,
                                                                      "iepId": iepId,
                                                                      "type": 'TEXT',
                                                                      "content": e.target.value,
                                                                      "remarks": ""
                                                                  };
                                                                  handleChange(o.materialQuestionId,obj)}
                                                              }
                                                              variant='filled'/></Box>
                        <Box w={'20%'} ml={'12px'}> <Textarea placeholder={t('enterResponsible')}
                                                              _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                              isDisabled={isCompleted || !canAddStudent()}
                                                              value={bulkAnswers.get(o.responsibleQuestionId)?bulkAnswers.get(o.responsibleQuestionId).content:''}
                                                              onChange={(e)=>{
                                                                  const obj={
                                                                      "questionId": o.responsibleQuestionId,
                                                                      "iepId": iepId,
                                                                      "type": 'TEXT',
                                                                      "content": e.target.value,
                                                                      "remarks": ""
                                                                  };
                                                                  handleChange(o.responsibleQuestionId,obj)}
                                                              }
                                                              variant='filled'/></Box>
                        <Box w={'20%'} ml={'12px'}> <Textarea placeholder={t('communication')}
                                                              _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                              isDisabled={isCompleted || !canAddStudent()}
                                                              value={bulkAnswers.get(o.communicationStrategyQuestionId)?bulkAnswers.get(o.communicationStrategyQuestionId).content:''}
                                                              onChange={(e)=>{
                                                                  const obj={
                                                                      "questionId": o.communicationStrategyQuestionId,
                                                                      "iepId": iepId,
                                                                      "type": 'TEXT',
                                                                      "content": e.target.value,
                                                                      "remarks": ""
                                                                  };
                                                                  handleChange(o.communicationStrategyQuestionId,obj)}
                                                              }
                                                              variant='filled'/></Box>
                        <IconButton
                            justifyItems={'center'}
                            isRound={true}
                            variant='solid'

                            colorScheme='red'
                            aria-label='Done'
                            fontSize='20px'
                            ml={'12px'}
                            isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                            icon={<MdDelete />}
                            onClick={()=>{
                                setIsDelete(o.objectiveQuestionId);
                                onOpen();
                            }}
                        />
                        {o.containsPreviousObjectives && (
                            <IconButton
                                justifyItems={'center'}
                                isRound={true}
                                variant='solid'

                                colorScheme='purple'
                                aria-label='Done'
                                fontSize='20px'
                                ml={'12px'}
                                isLoading={loadingObjectives} isDisabled={loadingObjectives || !canAddStudent()}
                                icon={<MdHistory />}
                                onClick={()=>{
                                    onGetVersionHistory(o.shiftedObjectiveId);
                                    onOpenHistory();
                                }}
                            />)}
                    </Flex>
                    )}
                </Box>
                    )}
                </Box>
                )}
                {reviewStep===0 || reviewStep===4 ?(
                <Box>

                <Flex justifyContent={'space-between'} mt={'12px'} mb={'12px'}>
                    <Text mt={'12px'} fontSize={'16px'} fontWeight={900} color={'blue'}>{t('objectiveSecondQtr')}</Text>
                    <IconButton
                        isRound={true}
                        variant='solid'
                        colorScheme='teal'
                        aria-label='Done'
                        fontSize='20px'
                        isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                        icon={<MdAdd />}
                        onClick={()=>handleAddObjectives(goalsAndObjectives?.secondQuarterGoals[index]?.goal.questionId,2)}
                    />
                </Flex>

                {goalsAndObjectives?.secondQuarterGoals[index]?.objectives.length>0 &&(
                    <Box border={'1px solid'}  borderRadius={'10px'} p={'10px'}>
                        <Flex mt={'12px'}>
                            <Text w={'20%'} fontWeight={900}>{t('objectives')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('activities')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('materials')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('responsible')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('communication')}</Text>
                        </Flex>
                        {goalsAndObjectives?.secondQuarterGoals[index]?.objectives?.map((o)=>
                            <Flex mt={'8px'}>
                                <Box w={'20%'}> <Textarea placeholder='Enter Objaectives' variant='filled'
                                                          isDisabled={isCompleted || !canAddStudent()}
                                                          _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                          value={bulkAnswers.get(o.objectiveQuestionId)?bulkAnswers.get(o.objectiveQuestionId).content:''}
                                                          onChange={(e)=>{

                                                              const obj={
                                                                  "questionId": o.objectiveQuestionId,
                                                                  "iepId": iepId,
                                                                  "type": 'TEXT',
                                                                  "content": e.target.value,
                                                                  "remarks": ""
                                                              };
                                                              handleChange(o.objectiveQuestionId,obj)}
                                                          }
                                /></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder='Enter Activities'
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      value={bulkAnswers.get(o.activityQuestionId)?bulkAnswers.get(o.activityQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.activityQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.activityQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder='Enter Materials'
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      value={bulkAnswers.get(o.materialQuestionId)?bulkAnswers.get(o.materialQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.materialQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.materialQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder='Enter Responsible'
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      value={bulkAnswers.get(o.responsibleQuestionId)?bulkAnswers.get(o.responsibleQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.responsibleQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.responsibleQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder={t('communication')}
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      value={bulkAnswers.get(o.communicationStrategyQuestionId)?bulkAnswers.get(o.communicationStrategyQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.communicationStrategyQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.communicationStrategyQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <IconButton
                                    justifyItems={'center'}
                                    isRound={true}
                                    variant='solid'

                                    colorScheme='red'
                                    aria-label='Done'
                                    fontSize='20px'
                                    ml={'12px'}
                                    isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                                    icon={<MdDelete />}
                                    onClick={()=>{
                                        setIsDelete(o.objectiveQuestionId);
                                        onOpen();
                                    }}
                                />
                                {o.containsPreviousObjectives && (
                                    <IconButton
                                        justifyItems={'center'}
                                        isRound={true}
                                        variant='solid'

                                        colorScheme='purple'
                                        aria-label='Done'
                                        fontSize='20px'
                                        ml={'12px'}
                                        isLoading={loadingObjectives} isDisabled={loadingObjectives  || !canAddStudent()}
                                        icon={<MdHistory />}
                                        onClick={()=>{
                                            onGetVersionHistory(o.shiftedObjectiveId);
                                            onOpenHistory();
                                        }}
                                    />)}
                            </Flex>
                        )}
                    </Box>
                )}
                </Box>
                ):''}
                <Flex justifyContent={'space-between'} mt={'12px'} mb={'12px'}>
                    <Text mt={'12px'} fontSize={'16px'} fontWeight={900} color={'blue'}>Set Objectives For Third  Quater</Text>
                    <IconButton
                        isRound={true}
                        variant='solid'
                        colorScheme='teal'
                        aria-label='Done'
                        fontSize='20px'
                        icon={<MdAdd />}
                        isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                        onClick={()=>handleAddObjectives(goalsAndObjectives?.thirdQuarterGoals[index]?.goal.questionId,3)}
                    />
                </Flex>

                {goalsAndObjectives?.thirdQuarterGoals[index]?.objectives.length>0 && (
                    <Box border={'1px solid'}  borderRadius={'10px'} p={'10px'}>
                        <Flex mt={'12px'}>
                            <Text w={'20%'} fontWeight={900}>{t('objectives')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('activities')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('materials')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('responsible')}</Text>
                            <Text w={'20%'} ml={'12px'} fontWeight={900}>{t('communication')}</Text>
                        </Flex>
                        {goalsAndObjectives?.thirdQuarterGoals[index]?.objectives?.map((o)=>
                            <Flex mt={'8px'}>
                                <Box w={'20%'}> <Textarea placeholder='Enter Objaectives' variant='filled'
                                                          _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                          isDisabled={isCompleted || !canAddStudent()}
                                                          value={bulkAnswers.get(o.objectiveQuestionId)?bulkAnswers.get(o.objectiveQuestionId).content:''}
                                                          onChange={(e)=>{
                                                              const obj={
                                                                  "questionId": o.objectiveQuestionId,
                                                                  "iepId": iepId,
                                                                  "type": 'TEXT',
                                                                  "content": e.target.value,
                                                                  "remarks": ""
                                                              };
                                                              handleChange(o.objectiveQuestionId,obj)}
                                                          }
                                /></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder='Enter Activities'
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      value={bulkAnswers.get(o.activityQuestionId)?bulkAnswers.get(o.activityQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.activityQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.activityQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder='Enter Materials'
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      value={bulkAnswers.get(o.materialQuestionId)?bulkAnswers.get(o.materialQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.materialQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.materialQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder='Enter Responsible'
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      value={bulkAnswers.get(o.responsibleQuestionId)?bulkAnswers.get(o.responsibleQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.responsibleQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.responsibleQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <Box w={'20%'} ml={'12px'}> <Textarea placeholder={t('communication')}
                                                                      _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                                                      isDisabled={isCompleted || !canAddStudent()}
                                                                      value={bulkAnswers.get(o.communicationStrategyQuestionId)?bulkAnswers.get(o.communicationStrategyQuestionId).content:''}
                                                                      onChange={(e)=>{
                                                                          const obj={
                                                                              "questionId": o.communicationStrategyQuestionId,
                                                                              "iepId": iepId,
                                                                              "type": 'TEXT',
                                                                              "content": e.target.value,
                                                                              "remarks": ""
                                                                          };
                                                                          handleChange(o.communicationStrategyQuestionId,obj)}
                                                                      }
                                                                      variant='filled'/></Box>
                                <IconButton
                                    justifyItems={'center'}
                                    isRound={true}
                                    variant='solid'

                                    colorScheme='red'
                                    aria-label='Done'
                                    fontSize='20px'
                                    ml={'12px'}
                                    isLoading={loadingObjectives} isDisabled={loadingObjectives || isCompleted || !canAddStudent()}
                                    icon={<MdDelete />}
                                    onClick={()=>{
                                        setIsDelete(o.objectiveQuestionId);
                                        onOpen();
                                    }}
                                />
                                {o.containsPreviousObjectives && (
                                    <IconButton
                                        justifyItems={'center'}
                                        isRound={true}
                                        variant='solid'

                                        colorScheme='purple'
                                        aria-label='Done'
                                        fontSize='20px'
                                        ml={'12px'}
                                        isLoading={loadingObjectives} isDisabled={loadingObjectives || !canAddStudent()}
                                        icon={<MdHistory />}
                                        onClick={()=>{
                                            onGetVersionHistory(o.shiftedObjectiveId);
                                            onOpenHistory();
                                        }}
                                    />)}
                            </Flex>
                        )}
                    </Box>
                )}
            </Box>
                    </AccordionPanel>
                </AccordionItem>
            )}
            </Accordion>
                {!goalId ?
            <Flex mt={'16px'} justifyContent={'flex-end'}>


                <Button colorScheme='gray' size='lg' mr={'12px'} onClick={()=>navigate(-1)}>
                    {t('back')}
                </Button>
                {!isCompleted && (
                <Button colorScheme='blue' size='lg' onClick={()=>handleaAddBulkAnswers(true)} mr={'12px'}  isLoading={loading} isDisabled={loading}>{t('save')}</Button>
                    )}
                    <Button colorScheme='green' size='lg' onClick={()=>onOpen()} isDisabled={!canAddStudent()}>
                        {isCompleted?t('redoStep'):t('completeStep')}
                </Button>
            </Flex>:
                    <Flex mt={'16px'} justifyContent={'flex-end'}>


                        <Button colorScheme='gray' size='lg' mr={'12px'} onClick={()=>onReviewHistory()}>
                           Update
                        </Button>
                    </Flex>}
            </Box>
                )}
            <CoustomAlertDialog
                onClose={handleClose}
                onOpen={onOpen}
                isOpen={isOpen}
                onMainAction={onCompleteIEP}
                loading={loading}
                warning={isDelete?false:true}
            />
            {isOpenHistory && (
                <Modal
                    onClose={onCloseHistory}
                    finalFocusRef={btnRef}
                    isOpen={isOpenHistory}
                    scrollBehavior={'inside'}
                    size={'6xl'}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>History</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {loadingHistory?
                                <Stack>
                                    <Skeleton height='20px' />
                                    <Skeleton height='20px' />
                                    <Skeleton height='20px' />
                                </Stack>:
                                <Box border={'1px solid'}>
                                    <Flex background={'#1b73e8'} p={'12px'}>
                                        <Text w={'20%'} fontWeight={900} color={'#ffffff'} fontSize={'20px'}>Objectives</Text>
                                        <Text w={'20%'} ml={'12px'} fontWeight={900} color={'#ffffff'} fontSize={'20px'}>Activities</Text>
                                        <Text w={'16%'} ml={'12px'} fontWeight={900} color={'#ffffff'} fontSize={'20px'}>Materials</Text>
                                        <Text w={'16%'} ml={'12px'} fontWeight={900} color={'#ffffff'} fontSize={'20px'}>Responsible</Text>
                                        <Text w={'14%'} ml={'12px'} fontWeight={900} color={'#ffffff'} fontSize={'20px'}>Created Date</Text>
                                        <Text w={'14%'} ml={'12px'} fontWeight={900} color={'#ffffff'} fontSize={'20px'}>Revised Date</Text>
                                    </Flex>
                                    {
                                        historyRes.length>0?
                                        historyRes.map((i,index)=>
                                            <Box>

                                        <Flex p={'6px'} background={index%2!==0?'#ffffff':'#eeeeee'}>
                                            <Text fontWeight={'500'} fontSize={'16px'} color={'#000000'} w={'20%'}>{i.objective}</Text>
                                            <Text fontWeight={'500'} fontSize={'16px'} color={'#484545'} w={'20%'}>{i.activity}</Text>
                                            <Text fontWeight={'500'} fontSize={'16px'} color={'#484545'} w={'16%'}>{i.material}</Text>
                                            <Text fontWeight={'500'} fontSize={'16px'} color={'#484545'} w={'16%'}>{i.responsible}</Text>
                                            <Text fontWeight={'500'} fontSize={'16px'} color={'#484545'} w={'14%'}>{new Date(i.created).toDateString()}</Text>
                                            <Text fontWeight={'500'} fontSize={'16px'} color={'#484545'} w={'14%'}>{new Date(i.answeredTimestamp).toDateString()}</Text>
                                        </Flex>
                                            </Box>

                                    ):
                                            <Text fontWeight={'500'} p={'10px'} fontSize={'16px'} color={'#000000'} w={'40%'}>No Histroy found.</Text>}
                                </Box>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onCloseHistory}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </Box>
    )

}
