import {
    Box,
    Button,
    Checkbox,
    Divider,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel, IconButton,
    Input,
    InputGroup,
    InputLeftElement, InputRightElement,
    Modal, ModalContent, ModalOverlay,
    Select,
    Text, useDisclosure
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {NepaliDatePicker} from "nepali-datepicker-reactjs";
import React, {useEffect, useState} from "react";
import "nepali-datepicker-reactjs/dist/index.css"
import {localeType} from "nepali-datepicker-reactjs/dist/NepaliDatePicker/Types";
import {DisabilityType, Student} from "../../interfaes/student";
import addressData from '../../data/address.json';
import addressDataNepali from '../../data/address_ne.json';
import DistictData from '../../data/District.json';
import DistictDataNepali from '../../data/District_ne.json';
import LocalLevelData from '../../data/localLevelData.json';
import LocalLevelDataNepali from '../../data/localLevelDataNe.json';
import {Address, District, Municipality} from "../../interfaes/address";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {DEFAULTSTUDENT} from "../../interfaes/fixture";
import _find from "lodash/find";
import _filter from "lodash/filter";
import {onGetSchools} from "../../http/school";
import {School} from "../../interfaes/school";
import {onGetParent, onGetTherapist, onGetUsers} from "../../http/users";
import {Roles, User} from "../../interfaes/user";
import Joi from "joi";
import {errorFormat, FormattedError, getErrorMessage, hasErrorMessage} from "../../utils/formUtils";
import {onAddNewStudent, onUpdateStudent} from "../../http/student";
import ImageUpload from "../../components/ImageUpload";
import NepaliDateConverter from "nepali-date-converter";
import NepaliDate from "nepali-date-converter";
import {MdAdb, MdAdd} from "react-icons/all";
import ManageUsers from "../users/ManageUsers";
import ManageSchool from "../school/ManageSchool";
import { FaArrowLeft } from "react-icons/fa";




export default function ManageStudent() {
    const [disability, setDisability] = useState('false');

    const schema = Joi.object({
        fullName: Joi.string()
            .required(),

        dob: Joi.date().required(),
        gender: Joi.string().optional(),
        language: Joi.string().required(),
        disabilityType: disability === 'true' ? Joi.string().required() : Joi.string().allow('').optional(),
        disabilityId: disability === 'true' ? Joi.string().required() : Joi.string().allow('').optional(),
        serviceArea: Joi.string().required(),
        parentId: Joi.string().required(),
        schoolId: Joi.string().optional().allow('').allow(null),
        therapistId: Joi.string().optional().allow('').allow(null),
        province: Joi.string().required(),
        district: Joi.string().required(),
        localLevel: Joi.string().required(),
        localAddress: Joi.string().required(),
        tempProvince: Joi.string().required(),
        tempDistrict: Joi.string().required(),
        tempLocalLevel: Joi.string().required(),
        tempLocalAddress: Joi.string().required(),
        category:Joi.string().optional().allow('').allow(null),
        specialDisability:Joi.required(),
        IEMISNumber:Joi.string().optional().allow('').allow(null),
        contact:Joi.required(),
        studentImage:Joi.optional(),
        disabilityDetail:Joi.optional(),
    });
    const [showLanguageOthers, setShowLanguageOthers] = useState<boolean>(false);
    const [showServiceAreaOthers, setShowServiceAreaOthers] = useState<boolean>(false);

    const {state} = useLocation();

    const [loading, setLoading] = useState<boolean>(false);
    const [copyAddress, setCopyAddress] = useState<boolean>(false);

    const {id} = useParams();
    const navigate = useNavigate();
    const [date, setDate] = useState<string>("2049-02-28");
    const [district, setDistrict] = useState<number>(0);
    const [districtTemp, setDistrictTemp] = useState<number>(0);
    const {t, i18n} = useTranslation('studentLocal');

    const [localLevel, setLocalLevel] = useState<number>(0);
    const [localLevelTemp, setLocalLevelTemp] = useState<number>(0);

    const [addInModal,setAddInModal]=useState<string>('');


    const [student, setStudent] = useState<Student>(
        state?{
                "fullName": state.fullName,
                "dob": state.dob,
                "gender": state.gender,
                "language": state.language,
                "disabilityType": state.disabilityType,
                "category": state.category,
                "specialDisability": state.specialDisability,
                "contact": state.contact,
                "schoolId": state.schoolId,
                "province": state.province,
                "district": state.district,
                "localLevel": state.localLevel,
                "localAddress": state.localAddress,
                "serviceArea": state.serviceArea,
                "parentId": state.parentId,
                "therapistId": state.therapistId,
                "IEMISNumber": state.IEMISNumber,
                "disabilityId": state.disabilityId,
                studentImage:state.studentImage,
            disabilityDetail:state.disabilityDetail,
                tempProvince:state.tempProvince,
                tempDistrict:state.tempDistrict,
                tempLocalLevel:state.tempLocalLevel,
                tempLocalAddress:state.tempLocalAddress



            }:
            DEFAULTSTUDENT);


    useEffect(() => {
        if(state) {
            onGetDOB(state.dob);
            const id = _find(address, ['name', state.province]);
            setDistrict(id?id.id:0);
            const id1 = _find(address, ['name', state.tempProvince]);
            setDistrictTemp(id1?id1.id:0);
            const dis = _find(districts, ['name', state.district]);
            setLocalLevel(dis?dis.id:0);
            const dis1 = _find(districts, ['name', state.tempDistrict]);
            setLocalLevelTemp(dis1?dis1.id:0);
            setDisability(state.disabilityType!==''?'true':'false')
            if(!['NEPALI', 'RAUTE', 'MAITHALI', 'TAMANG', 'NEWARI', 'KIRAT', 'OTHERS'].includes(state.language)){
                setShowLanguageOthers(true);
            }
            if(!['COMMUNITY', 'HOME', 'SCHOOL'].includes(state.serviceArea)){
                setShowServiceAreaOthers(true);
            }
        }
    }, [state]);


    const onGetDOB=(timeStamp:number)=>{
        const date=new Date(timeStamp);
        const convertedDate = NepaliDate.fromAD(date);
       setDate(convertedDate.format("YYYY-MM-DD"));
        // const convertedDate=new NepaliDate(date.getFullYear(),date.getMonth(),date.getDate());
        // console.log(convertedDate,date);
    }
    const [school, setSchool] = useState<School[]>([]);
    const [parents, setParents] = useState<User[]>([]);
    const [therapist, setTherapist] = useState<User[]>([]);
    const [teachers, setTeachers] = useState<User[]>([]);
    // @ts-ignore
    const address: Address[] = addressData;
    const districts: District[] = DistictData;
    const localLevelData: Municipality[] = i18n.language==='en'?LocalLevelData:LocalLevelDataNepali;
    const [formErrors, setFormErrors] = useState<FormattedError[]>([]);

    useEffect(() => {
        getSchools();
        getTherapist();
        getUsers();
    }, [true])



    const getSchools = async () => {
        try {
            const response = await onGetSchools();
            setSchool(response.data.items);
        } catch (e) {
            console.log(e)
        }

    }
    const getTherapist = async () => {
        try {
            const response = await onGetTherapist();
            const p = response.data.userResponseList;
            const t = response.data.userResponseList.filter((e: User) => {
                return e?.roles?.includes(Roles.THERAPIST)
            })

            setTherapist(t);

        } catch (e) {
            console.log(e);
        }
    }
    const getUsers = async () => {
        try {
            const response = await onGetUsers();
            const p = response.data.userResponseList.filter((e: User) => {
                return e?.roles?.includes(Roles.PARENT)
            });
            setParents(p);


        } catch (e) {
            console.log(e);
        }
    }
    const validateObj = () => {
        const {error} = schema.validate(student);
        console.log(error,student);
        if (error) {
            setFormErrors(errorFormat(error.details));
            return false;
        }

        setFormErrors([]);
        return true;
    }

    const handleUpdateStudent = async () => {
        if (validateObj()) {
            try {
                if (id) {
                    setLoading(true);

                    if (id === 'add') {
                        await onAddNewStudent(student);
                        navigate(-1);
                    } else {
                        await onUpdateStudent(student,id);
                        navigate(-1);
                    }
                }

            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);

            }
        }
    }
    const { isOpen, onOpen, onClose } = useDisclosure()

    const disabilityTypeArray = Object.entries(DisabilityType);



    return <Box>
        <Text fontSize={"24px"} fontWeight={900}>
            {t('manageStudents.title')}
        </Text>
        <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
            <Text fontSize={"18px"} fontWeight={700}>
                {t('manageStudents.studnetsDetails')}
            </Text>
            <Flex mt={"12px"}>
                <Box w={"80%"}>
                    <Flex>

                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'fullName')}>
                            <FormLabel>  {t('manageStudents.fullName')}</FormLabel>
                            <Input
                                value={student.fullName}
                                onChange={(e) => setStudent({...student, fullName: e.target.value || ''})}

                                variant={"filled"} placeholder={t('manageStudents.enterfullName')} required/>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'fullName')}</FormErrorMessage>
                        </FormControl>
                        <FormControl mr={"12px"} id="stdDOB">
                            <FormLabel>{t('manageStudents.dob')}</FormLabel>
                            <NepaliDatePicker inputClassName="form-control"
                                              className=""
                                              value={date}
                                              onChange={(value: string) => {
                                                  // @ts-ignore
                                                  const englishDate = NepaliDateConverter.fromAD(value).toJsDate();
                                                  setStudent({...student, dob: Math.abs(englishDate.getTime())});
                                                  setDate(value)
                                              }}
                                              options={{ calenderLocale: "en", valueLocale: "en" }}

                                />
                            {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
                        </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'gender')}>
                            <FormLabel>{t('manageStudents.gender')}</FormLabel>
                            <Select placeholder={t('manageStudents.genderPlaceholder')}
                                    onChange={(e) => setStudent({...student, gender: e.target.value || ''})}
                                    value={student.gender}
                                    background={'var(--chakra-colors-gray-100)'}>
                                <option value='M'>{t('manageStudents.male')}</option>
                                <option value='F'>{t('manageStudents.female')}</option>
                                <option value='U'>{t('manageStudents.others')}</option>
                            </Select>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'gender')}</FormErrorMessage>

                        </FormControl>
                    </Flex>
                    <Flex mt={"12px"}>
                        <FormControl mr={"12px"} id="stdDOB">
                            <FormLabel>{t('manageStudents.disability')}</FormLabel>
                            <Select placeholder={t('manageStudents.disabilityPlaceholder')}
                                    value={disability}
                                    onChange={(e) => setDisability(e.target.value)}
                                    background={'var(--chakra-colors-gray-100)'}>
                                <option value='true'>{t('manageStudents.yes')}</option>
                                <option value='false'>{t('manageStudents.no')}</option>
                            </Select>
                            {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
                        </FormControl>


                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'disabilityType')}>
                            <FormLabel>{t('manageStudents.disabilityType')}</FormLabel>
                            <Select placeholder={disability === 'false'?t('manageStudents.disabilityTypePlaceholder'):''}
                                    background={'var(--chakra-colors-gray-100)'}
                                    isDisabled={disability === 'false'}
                                    value={student.disabilityType}
                                    onChange={(e) => setStudent({...student, disabilityType: e.target.value || ''})}

                            >
                                {disabilityTypeArray.map(([key, value]) => (
                                    <option value={value}>{t(`manageStudents.${key}`)}</option>
                                ))}

                            </Select>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'disabilityType')}</FormErrorMessage>
                        </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'disabilityId')}>
                            <FormLabel>  {t('manageStudents.disabilityId')}</FormLabel>
                            <Select placeholder={disability === 'false'?t('manageStudents.disabilityId'):''}
                                    onChange={(e) => setStudent({...student, disabilityId: e.target.value || ''})}
                                    value={student.disabilityId}
                                    isDisabled={disability === 'false'}
                                    background={'var(--chakra-colors-gray-100)'}>
                                { ['Profound Disability (Red Color ID)', 'Severe Disability (Blue Color ID)', 'Moderate Disability (Yellow Color ID)', 'Mild Disability (White Color ID)','NO ID'].map((lang)=>
                                    <option value={lang}>{t(`manageStudents.${lang}`)}</option>
                                )}

                            </Select>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'language')}</FormErrorMessage>

                        </FormControl>
                    </Flex>
                    <Flex  mt='12px'>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'disabilityDetail')}>
                        <FormLabel>{t('manageStudents.disabilityDetail')}</FormLabel>
                        <Input
                            value={student.disabilityDetail}
                            onChange={(e) => setStudent({...student, disabilityDetail: e.target.value || ''})}

                            variant={"filled"} placeholder={t('manageStudents.disabilityDetail')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'disabilityDetail')}</FormErrorMessage>

                    </FormControl>
                    <FormControl mr={"12px"}  isInvalid={hasErrorMessage(formErrors, 'IEMISNumber')}>
                        <FormLabel>{t('manageStudents.IEMISNumber')}</FormLabel>
                        <Input
                            value={student.IEMISNumber}
                            onChange={(e) => setStudent({...student, IEMISNumber: e.target.value || ''})}

                            variant={"filled"} placeholder={t('manageStudents.IEMISNumber')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'IEMISNumber')}</FormErrorMessage>

                    </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'language')}>
                            <FormLabel>  {t('manageStudents.nativeLanguage')}</FormLabel>
                            {showLanguageOthers ?
                                <InputGroup>
                                <Input
                                    value={student.language}
                                    onChange={(e) => setStudent({...student, language: e.target.value || ''})}

                                    variant={"filled"} placeholder={t('manageStudents.nativeLanguage')} required/>
                                    <InputRightElement width='4.5rem'>
                                        <Button h='1.75rem' size='sm' onClick={()=>{
                                            setShowLanguageOthers(false);
                                            setStudent({...student, language: ''})
                                        }
                                        }>
                                            <FaArrowLeft/>
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                :
                                <Select placeholder={t('manageStudents.nativeLanguage')}
                                        onChange={(e) => {
                                            if (e.target.value === 'OTHERS') {
                                                setShowLanguageOthers(true);
                                                setStudent({...student, language: ''})
                                            } else {
                                                setStudent({...student, language: e.target.value || ''})
                                                setShowLanguageOthers(false)
                                            }
                                        }}

                                        value={student.language}
                                        background={'var(--chakra-colors-gray-100)'}>
                                    {['NEPALI', 'Maithili', 'Bhojpuri', 'Tharu', 'Tamang', 'Nepal Bhasa', 'Magar','Doteli','Limbu','Gurung','Rai','Chepang','OTHERS'].map((lang) =>
                                        <option value={lang}>{t(`manageStudents.${lang}`)}</option>
                                    )}

                                </Select>
                            }
                            <FormErrorMessage>{getErrorMessage(formErrors, 'language')}</FormErrorMessage>

                        </FormControl>
                    </Flex>
                </Box>

                <Box w={"20%"}

                >

                    <ImageUpload onSetImage={(url:string)=> setStudent({...student, studentImage: url})} imageUrl={student.studentImage}/>

                </Box>
            </Flex>

            <Flex mt={'12px'}>
                <Box>
                    <Text fontSize={"16px"} fontWeight={500} mb={"6px"}>
                        {t('manageStudents.serviceArea')}
                    </Text>
                    {showServiceAreaOthers ?
                        <InputGroup>
                            <Input
                                value={student.serviceArea}
                                onChange={(e) => setStudent({...student, serviceArea: e.target.value || ''})}

                                variant={"filled"} placeholder={t('manageStudents.serviceArea')} required/>
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={()=>{
                                    setShowServiceAreaOthers(false);
                                    setStudent({...student, serviceArea: ''})
                                }
                                }>
                                    <FaArrowLeft/>
                                </Button>
                            </InputRightElement>
                        </InputGroup> :
                        <Box background={'var(--chakra-colors-gray-100)'} borderRadius={'6px'} padding={'8px'}
                             border={hasErrorMessage(formErrors, 'serviceArea') ? '1px solid red' : 'none'}>

                            <Checkbox colorScheme='red' mr={'16px'}
                                      value={'SCHOOL'}
                                      isChecked={student.serviceArea === 'SCHOOL'}
                                      onChange={(e) => {
                                          if (student.serviceArea === 'SCHOOL') {
                                              setStudent({...student, serviceArea: ''})

                                          } else {
                                              setStudent({...student, serviceArea: 'SCHOOL'})
                                          }
                                      }
                                      }>
                                {t('manageStudents.school')}
                            </Checkbox>
                            <Checkbox colorScheme='red'
                                      value={'HOME'}
                                      isChecked={student.serviceArea === 'HOME'}
                                      onChange={(e) => {
                                          if (student.serviceArea === 'HOME') {
                                              setStudent({...student, serviceArea: ''})

                                          } else {
                                              setStudent({...student, serviceArea: 'HOME'})
                                          }
                                      }
                                      }
                            >
                                {t('manageStudents.home')}
                            </Checkbox>
                            <Checkbox colorScheme='red'
                                      ml={'4px'}
                                      value={'COMMUNITY'}
                                      isChecked={student.serviceArea === 'COMMUNITY'}
                                      onChange={(e) => {
                                          if (student.serviceArea === 'COMMUNITY') {
                                              setStudent({...student, serviceArea: ''})

                                          } else {
                                              setStudent({...student, serviceArea: 'COMMUNITY'})
                                          }
                                      }
                                      }
                            >
                                {t('manageStudents.community')}
                            </Checkbox>
                            <Checkbox colorScheme='red'
                                      ml={'4px'}
                                      value={'OTHERS'}
                                      isChecked={student.serviceArea === 'OTHERS'}
                                      onChange={(e) => {
                                          if (student.serviceArea === 'OTHERS') {
                                              setStudent({...student, serviceArea: ''})

                                          } else {
                                              setStudent({...student, serviceArea: ''})
                                          }
                                          setShowServiceAreaOthers(true);
                                      }
                                      }
                            >
                                {t('manageStudents.others')}
                            </Checkbox>
                        </Box>
                    }
                    <Text color={"red"}>{getErrorMessage(formErrors, 'serviceArea')}</Text>
                </Box>
                <Box ml={"12px"}>
                    <FormControl mr={"12px"}  isInvalid={hasErrorMessage(formErrors, 'category')} >
                        <FormLabel>  {t('manageStudents.category')}</FormLabel>
                        <Input
                            onChange={(e) => setStudent({...student, category: e.target.value || ''})}
                            value={student.category}
                            variant={"filled"} placeholder={t('manageStudents.category')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'category')}</FormErrorMessage>

                    </FormControl>
                </Box>
                <Box ml={"12px"}>
                    <FormControl mr={"12px"}  isInvalid={hasErrorMessage(formErrors, 'specialDisability')}>
                        <FormLabel>  {t('manageStudents.specialDisability')}</FormLabel>
                        <Input
                            onChange={(e) => setStudent({...student, specialDisability: e.target.value || ''})}
                            value={student.specialDisability}
                            variant={"filled"} placeholder={t('manageStudents.specialDisability')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'specialDisability')}</FormErrorMessage>

                    </FormControl>
                </Box>
                <Box ml={"12px"}>
                    <FormControl mr={"12px"}  isInvalid={hasErrorMessage(formErrors, 'contact')}>
                        <FormLabel>  {t('manageStudents.contact')}</FormLabel>
                        <Input
                            onChange={(e) => setStudent({...student, contact: e.target.value || ''})}
                            value={student.contact}
                            variant={"filled"} placeholder={t('manageStudents.contact')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'contact')}</FormErrorMessage>

                    </FormControl>
                </Box>
            </Flex>
            <Divider mt={"12px"}/>
            <Flex mt={'12px'}>
                <Box background={"#ffffff"} w={"33%"} mr={'12px'}>
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                        <Text fontSize={"18px"} fontWeight={700}>
                            {t('manageStudents.parentsDetails')}
                        </Text>
                        <IconButton
                            background={'#1b73e8'}
                            p={"10px"}
                            borderRadius={"10px"}
                            aria-label='manage students'
                            mr={"12px"}
                            onClick={() => {
                                setAddInModal('PARENT')
                                onOpen();
                            }}
                            icon={<MdAdd color={'#ffffff'}/>}
                        />
                    </Flex>

                    <FormControl mr={"12px"} mt={"12px"} isInvalid={hasErrorMessage(formErrors, 'parentId')}>
                        <Select placeholder={t('manageStudents.parentsDetails')}
                                value={student.parentId}
                                onChange={(e)=> setStudent({...student,parentId:e.target.value || ''})}

                                background={'var(--chakra-colors-gray-100)'}>
                            {parents.map((e) =>
                                <option value={e.id}>{e.displayName}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'parentId')}</FormErrorMessage>

                    </FormControl>
                </Box>
                <Box background={"#ffffff"} w={"33%"} mr={'12px'}>
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontSize={"18px"} fontWeight={700}>
                        {t('manageStudents.schoolDetails')}
                    </Text>
                    <IconButton
                        background={'#1b73e8'}
                        p={"10px"}
                        borderRadius={"10px"}
                        aria-label='manage students'
                        mr={"12px"}
                        onClick={() => {
                            setAddInModal('SCHOOL')
                            onOpen();
                        }}
                        icon={<MdAdd color={'#ffffff'}/>}
                    />
                    </Flex>
                    <FormControl mr={"12px"} mt={"12px"} isInvalid={hasErrorMessage(formErrors, 'schoolId')}>
                        <Select placeholder={t('manageStudents.schoolDetails')}
                                value={student.schoolId}
                                onChange={(e)=> setStudent({...student,schoolId:e.target.value || ''})}

                                background={'var(--chakra-colors-gray-100)'}>
                            {school.map((e) =>
                                <option value={e.id}>{e.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'schoolId')}</FormErrorMessage>

                    </FormControl>
                </Box>
                <Box background={"#ffffff"} w={"33%"} mr={'12px'}>
                    <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontSize={"18px"} fontWeight={700}>
                        {t('manageStudents.therapistDetails')}
                    </Text>
                    <IconButton
                        background={'#1b73e8'}
                        p={"10px"}
                        borderRadius={"10px"}
                        aria-label='manage students'
                        mr={"12px"}
                        onClick={() => {
                            setAddInModal('THERAPIST')
                            onOpen();
                        }}
                        icon={<MdAdd color={'#ffffff'}/>}
                    />
                    </Flex>
                    <FormControl mr={"12px"} mt={"12px"} isInvalid={hasErrorMessage(formErrors, 'therapistId')}>
                        <Select placeholder={t('manageStudents.therapistDetails')}
                                value={student.therapistId}
                                onChange={(e)=> setStudent({...student,therapistId:e.target.value || ''})}

                                background={'var(--chakra-colors-gray-100)'}>
                            {therapist.map((e) =>
                                <option value={e.id}>{e.displayName}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'therapistId')}</FormErrorMessage>

                    </FormControl>
                </Box>
            </Flex>
            <Divider mt={"12px"}/>
            <Box mt={'12px'}>
                <Text fontSize={"18px"} fontWeight={700}>
                    {t('manageStudents.address')}
                </Text>
                <Flex mt={'12px'}>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'province')}>
                        <FormLabel>{t('manageStudents.province')}</FormLabel>
                        <Select placeholder={t('manageStudents.province')}
                                background={'var(--chakra-colors-gray-100)'}
                                value={student.province}
                                onChange={(e) => {
                                    const id = _find(address, ['name', e.target.value]);
                                    setDistrict(id?id.id:0);
                                    setStudent({...student,province:e.target.value || ''})
                                }}
                        >
                            {address.map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'province')}</FormErrorMessage>

                    </FormControl>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'district')}>
                        <FormLabel>{t('manageStudents.district')}</FormLabel>

                        <Select placeholder={t('manageStudents.district')}
                                value={student.district}
                                onChange={(e)=> {

                                    const id = _find(districts, ['name', e.target.value]);
                                    setLocalLevel(id?id.id:0);
                                    setStudent({...student,district:e.target.value || ''})
                                }}

                                background={'var(--chakra-colors-gray-100)'} isDisabled={!student.province}>
                            {_filter(districts, function(o) { return o.province_id===district }).map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'district')}</FormErrorMessage>

                    </FormControl>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'localLevel')}>
                        <FormLabel>{t('manageStudents.locallevel')}</FormLabel>
                        <Select placeholder={t('manageStudents.locallevel')}
                                value={student.localLevel}
                                onChange={(e)=> setStudent({...student,localLevel:e.target.value || ''})}

                                isDisabled={!student.district}
                                background={'var(--chakra-colors-gray-100)'}>
                            {_filter(localLevelData, function(o) { return o.district_id===localLevel }).map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'localLevel')}</FormErrorMessage>
                    </FormControl>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'localAddress')}>
                        <FormLabel>{t('manageStudents.localAddress')}</FormLabel>
                        <Input
                            value={student.localAddress}
                            onChange={(e)=> setStudent({...student,localAddress:e.target.value || ''})}

                            variant={"filled"} placeholder={t('manageStudents.localAddress')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'localAddress')}</FormErrorMessage>
                    </FormControl>
                </Flex>
            </Box>
            <Divider mt={"12px"}/>

                <Text fontSize={"18px"} fontWeight={700} mt={'6px'}>
                    {t('manageStudents.address2')}
                </Text>
            <Checkbox checked={copyAddress} mt={'6px'} fontSize={"18px"} fontWeight={700} onChange={(e)=>{
                if(copyAddress){
                    setCopyAddress(false);
                    setStudent({...student,tempProvince:'',tempDistrict:'',tempLocalLevel:'',tempLocalAddress:''});
                }else {
                    setCopyAddress(true);
                    const id = _find(address, ['name', student.province]);
                    console.log(id);
                    setDistrictTemp(id ? id.id : 0);
                    const id1 = _find(districts, ['name', student.district]);
                    console.log(id1)
                    console.log(student)
                    setLocalLevelTemp(id1?id1.id:0);
                    setStudent({...student,tempProvince:student.province,tempDistrict:student.district,tempLocalLevel:student.localLevel,tempLocalAddress:student.localAddress});

                }
            }}>Same as Permanent</Checkbox>
            <Box mt={'12px'}>
                <Flex mt={'12px'}>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'tempProvince')}>
                        <FormLabel>{t('manageStudents.tempProvince')}</FormLabel>
                        <Select placeholder={t('manageStudents.tempProvince')}
                                background={'var(--chakra-colors-gray-100)'}
                                value={student.tempProvince}
                                onChange={(e) => {
                                    const id = _find(address, ['name', e.target.value]);
                                    setDistrictTemp(id ? id.id : 0);
                                    setStudent({...student,tempProvince:e.target.value || ''})
                                }}
                        >
                            {address.map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'tempProvince')}</FormErrorMessage>

                    </FormControl>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'tempDistrict')}>
                        <FormLabel>{t('manageStudents.tempDistrict')}</FormLabel>

                        <Select placeholder={t('manageStudents.tempDistrict')}
                                value={student.tempDistrict}
                                onChange={(e)=> {

                                    const id = _find(districts, ['name', e.target.value]);
                                    setLocalLevelTemp(id?id.id:0);
                                    setStudent({...student,tempDistrict:e.target.value || ''})
                                }}

                                background={'var(--chakra-colors-gray-100)'} isDisabled={!student.tempProvince}>
                            {_filter(districts, function(o) { return o.province_id===districtTemp }).map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'tempDistrict')}</FormErrorMessage>

                    </FormControl>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'tempLocalLevel')}>
                        <FormLabel>{t('manageStudents.tempLocalLevel')}</FormLabel>
                        <Select placeholder={t('manageStudents.tempLocalLevel')}
                                value={student.tempLocalLevel}
                                onChange={(e)=> setStudent({...student,tempLocalLevel:e.target.value || ''})}

                                isDisabled={!student.tempDistrict}
                                background={'var(--chakra-colors-gray-100)'}>
                            {_filter(localLevelData, function(o) { return o.district_id===localLevelTemp }).map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'tempLocalLevel')}</FormErrorMessage>
                    </FormControl>
                    <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'tempLocalAddress')}>
                        <FormLabel>{t('manageStudents.localAddress')}</FormLabel>
                        <Input
                            value={student.tempLocalAddress}
                            onChange={(e)=> setStudent({...student,tempLocalAddress:e.target.value || ''})}

                            variant={"filled"} placeholder={t('manageStudents.tempLocalAddress')} required/>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'tempLocalAddress')}</FormErrorMessage>
                    </FormControl>
                </Flex>
            </Box>

            <Flex mt={'16px'} justifyContent={'flex-end'}>

                <Button colorScheme='gray' size='lg' mr={'12px'} onClick={() => navigate(-1)} isDisabled={loading}>
                    {t('manageStudents.cancel')}
                </Button>
                <Button colorScheme='blue' size='lg'  isLoading={loading} isDisabled={loading}
                        onClick={handleUpdateStudent}
                >
                    {t('manageStudents.save')}
                </Button>
            </Flex>
        </Box>
        <Modal isOpen={isOpen} onClose={onClose} size={'5xl'}>
            <ModalOverlay />
            <ModalContent p={'12px'}>
                {addInModal==='SCHOOL'?<ManageSchool shouldReturn={true} returnUserID={(id:string)=>{

                        if(addInModal==='SCHOOL'){setStudent({...student,schoolId:id});}



                        getSchools();
                        setAddInModal('');
                        onClose();
                    }} role={addInModal}/>:
            <ManageUsers shouldReturn={true} returnUserID={(id:string)=>{
                if(addInModal==='THERAPIST'){setStudent({...student,therapistId:id});}
                if(addInModal==='PARENT'){setStudent({...student,parentId:id});}




                getTherapist();
                getUsers();
                setAddInModal('');
                onClose();
            }} role={addInModal}/>
                }
            </ModalContent>
        </Modal>


    </Box>
}