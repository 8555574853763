import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Select,
    Text,
    useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {School} from "../../interfaes/school";
import {errorFormat, FormattedError, getErrorMessage, hasErrorMessage} from "../../utils/formUtils";
import Joi from "joi";
import {onAddNewSchool, onUpdateSchool} from "../../http/school";
import _find from "lodash/find";
import _filter from "lodash/filter";
import {Address, District, Municipality} from "../../interfaes/address";
import addressData from "../../data/address.json";
import DistictData from "../../data/District.json";
import LocalLevelData from "../../data/localLevelData.json";
import LocalLevelDataNepali from "../../data/localLevelDataNe.json";

type ManageSchoolPropsType = {
    shouldReturn: boolean; role: string; returnUserID: (id: string) => void;
};
export default function ManageSchool({shouldReturn, returnUserID, role}: ManageSchoolPropsType) {
    const {state} = useLocation();
    const {id} = useParams();
    const {t,i18n} = useTranslation('school');
    const schema = Joi.object({
        name: Joi.string()
            .required(),
        province: Joi.string().required(),
        district: Joi.string().required(),
        localLevel: Joi.string().required(),
        localAddress: Joi.string().required(),
        IEMISNumber: Joi.string().optional().allow('').allow(null), image: Joi.string().optional().allow('')
    });
    // @ts-ignore
    const address: Address[] = addressData;
    const districts: District[] = DistictData;
    const localLevelData: Municipality[] = i18n.language==='en'?LocalLevelData:LocalLevelDataNepali;
    const [district, setDistrict] = useState<number>(0);


    const [localLevel, setLocalLevel] = useState<number>(0);
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
    const [schoolObj, setSchoolObj] = useState<School>({
        "name": state ? state.name : '',
        "image": "",
        "province": state ? state.province : '',
        "district": state ? state.district : '',
        "localLevel": state ? state.localLevel : '',
        "localAddress": state ? state.localAddress : '',
        IEMISNumber: state?.IEMISNumber,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const validateObj = () => {
        const {error} = schema.validate(schoolObj);
        if (error) {
            setFormErrors(errorFormat(error.details));
            return false;
        }

        setFormErrors([]);
        return true;
    }
    const toast = useToast();


    const handleUpdateSchool = async () => {
        if (validateObj()) {
            try {
                if(shouldReturn){
                    setLoading(true);
                    const response=await onAddNewSchool(schoolObj);
                    returnUserID(response.data.id);
                    setLoading(false);
                    return;
                }
                if (id) {
                    setLoading(true);
                    if (id === 'add') {
                        await onAddNewSchool(schoolObj);
                        navigate(-1)
                    } else {

                            await onUpdateSchool(schoolObj, id);
                            navigate(-1)

                    }
                }
                toast({
                    title: 'Added',
                    description: "School Added Successfully!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            } catch (e) {
                // @ts-ignore
                toast({
                    title: 'Error',
                    // @ts-ignore
                    description: e?.response?.data?.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            } finally {
                setLoading(false);
            }
        }
    }

    return (<Box>
            <Text fontSize={"24px"} fontWeight={900}>
                {t('title')}
            </Text>
            <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
                <FormControl mt={"12px"} isInvalid={hasErrorMessage(formErrors, 'name')}>
                    <FormLabel>  {t('schoolName')}</FormLabel>
                    <Input variant={"filled"} placeholder={t('schoolName')}
                           value={schoolObj.name}
                           onChange={(e) => setSchoolObj({...schoolObj, name: e.target.value || ''})}
                           required/>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'name')}</FormErrorMessage>
                </FormControl>
                <FormControl mt={"12px"} isInvalid={hasErrorMessage(formErrors, 'IEMISNumber')}>
                    <FormLabel>{t('IEMISNumber')}</FormLabel>
                    <Input
                        value={schoolObj?.IEMISNumber}
                        onChange={(e) => setSchoolObj({...schoolObj, IEMISNumber: e.target.value || ''})}

                        variant={"filled"} placeholder={t('IEMISNumber')} required/>
                    <FormErrorMessage>{getErrorMessage(formErrors, 'IEMISNumber')}</FormErrorMessage>

                </FormControl>
                <Box mt={'12px'}>
                    <Text fontSize={"18px"} fontWeight={700}>
                        {t('studentLocal:manageStudents.address')}
                    </Text>
                    <Flex mt={'12px'}>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'province')}>
                            <FormLabel>{t('studentLocal:manageStudents.province')}</FormLabel>
                            <Select placeholder={t('studentLocal:manageStudents.province')}
                                    background={'var(--chakra-colors-gray-100)'}
                                    value={schoolObj.province}
                                    onChange={(e) => {
                                        const id = _find(address, ['name', e.target.value]);
                                        setDistrict(id?id.id:0);
                                        setSchoolObj({...schoolObj,province:e.target.value || ''})
                                    }}
                            >
                                {address.map((a,index) =>
                                    <option value={a.name}>{a.name}</option>
                                )}

                            </Select>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'province')}</FormErrorMessage>

                        </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'district')}>
                            <FormLabel>{t('studentLocal:manageStudents.district')}</FormLabel>

                            <Select placeholder={t('studentLocal:manageStudents.district')}
                                    value={schoolObj.district}
                                    onChange={(e)=> {

                                        const id = _find(districts, ['name', e.target.value]);
                                        setLocalLevel(id?id.id:0);
                                        setSchoolObj({...schoolObj,district:e.target.value || ''})
                                    }}

                                    background={'var(--chakra-colors-gray-100)'} isDisabled={!schoolObj.province}>
                                {_filter(districts, function(o) { return o.province_id===district }).map((a,index) =>
                                    <option value={a.name}>{a.name}</option>
                                )}

                            </Select>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'district')}</FormErrorMessage>

                        </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'localLevel')}>
                            <FormLabel>{t('studentLocal:manageStudents.locallevel')}</FormLabel>
                            <Select placeholder={t('studentLocal:manageStudents.province')}
                                    value={schoolObj.localLevel}
                                    onChange={(e)=> setSchoolObj({...schoolObj,localLevel:e.target.value || ''})}

                                    isDisabled={!schoolObj.district}
                                    background={'var(--chakra-colors-gray-100)'}>
                                {_filter(localLevelData, function(o) { return o.district_id===localLevel }).map((a,index) =>
                                    <option value={a.name}>{a.name}</option>
                                )}

                            </Select>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'localLevel')}</FormErrorMessage>
                        </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'localAddress')}>
                            <FormLabel>{t('studentLocal:manageStudents.localAddress')}</FormLabel>
                            <Input
                                value={schoolObj.localAddress}
                                onChange={(e)=> setSchoolObj({...schoolObj,localAddress:e.target.value || ''})}

                                variant={"filled"} placeholder={t('studentLocal:manageStudents.localAddress')} required/>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'localAddress')}</FormErrorMessage>
                        </FormControl>
                    </Flex>
                </Box>
                <Flex mt={'16px'} justifyContent={'flex-end'}>

                    <Button colorScheme='gray' size='lg' mr={'12px'} onClick={() => {
                        if (shouldReturn) {
                            returnUserID('');
                        } else {

                            navigate(-1)
                        }
                    }} isDisabled={loading}>
                        {t('cancel')}
                    </Button>
                    <Button colorScheme='blue' size='lg' onClick={handleUpdateSchool} isLoading={loading}
                            isDisabled={loading}>
                        {t('save')}
                    </Button>
                </Flex>
            </Box>
        </Box>)
}
