import {Student} from "./student";
import {Roles} from "./user";

export const DEFAULTSTUDENT:Student={
    "fullName": "",
    "dob": new Date().getTime(),
    "gender": "",
    "language": "",
    "disabilityType": "",
    "category": "",
    "specialDisability": "",
    "contact": "",
    "schoolId": "",
    "province": "",
    "district": "",
    "localLevel": "",
    "localAddress": "",
    "serviceArea": "",
    "parentId": "",
    "therapistId": "",
    disabilityId:"",
    IEMISNumber:'',
    tempProvince: "",
    tempDistrict: "",
    tempLocalLevel: "",
    tempLocalAddress: ""
}
export const DEFAULTUSER={
    "displayName": "",
    "image": "",
    "description": "-",
    "gender": "",
    "address": "",
    "phone": "",
    "roles": [],
    "age": 22,
    "credencials": {
        "username": "",
        "password": ""
    },
}

export const DEFAULT_IEP={
    "studentId": "",
    "year": "",
    "level": "",
    "section": "",
    "roll": ""
}
