import {MdDashboard, MdSchool} from "react-icons/md";
import {FaSchool} from "react-icons/fa";
import {BiUserPlus,} from "react-icons/bi";
import {IoIosSchool,IoIosPaper} from "react-icons/io";
import React from "react";

interface MenuItem {
    label: string;
    path: string;
    icon: React.ElementType;
    accessTo:string[]
}

export const MenuList: MenuItem[] = [
    {
        label: 'Dashboard',
        path: '/dashboard',
        icon: MdDashboard,
        accessTo:["MODERATOR", "ADMIN", "SUPER_ADMIN","REPORT_ACCESS","TEACHER"]
    },{
        label: 'Student Progress',
        path: '/dashboard-student',
        icon: IoIosPaper,
        accessTo:["MODERATOR", "ADMIN", "SUPER_ADMIN","REPORT_ACCESS","TEACHER"]
    },
    {
        label: 'Objectives Progress',
        path: '/dashboard-objectives',
        icon: IoIosPaper,
        accessTo:["MODERATOR", "ADMIN", "SUPER_ADMIN","REPORT_ACCESS","TEACHER"]
    },
    {
        label: 'User',
        path: '/user',
        icon: BiUserPlus,
        accessTo:["MODERATOR", "ADMIN", "SUPER_ADMIN",'TEACHER']
    },

    {
        label: 'School',
        path: '/school',
        icon: FaSchool,
        accessTo:["MODERATOR", "ADMIN", "SUPER_ADMIN","TEACHER"]
    },
    {
        label: 'Student',
        path: '/students',
        icon: MdSchool,
        accessTo:["MODERATOR", "ADMIN", "SUPER_ADMIN"]
    },
    {
        label: 'My_Students',
        path: '/my_students',
        icon: IoIosSchool,
        accessTo:["TEACHER","PARENT","THERAPIST"]
    },
]
