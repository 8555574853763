import * as React from "react"
import {Box, Center, ChakraProvider, CircularProgress, Flex,} from "@chakra-ui/react"

import PageRoutes from "./routes/Routes";
import customTheme from "./coustomTheme";
import MenuComponent from "./components/MenuComponent";
import {useLocation} from "react-router";
import './main.css';
import {initHttp} from "./http/common";
import {useEffect, useState} from "react";
export default function App () {
    const {pathname} = useLocation();
    const[settingUp,setSettingApp]=useState(true)
    useEffect(()=>{
       onInitApp();
    },[true])

    const onInitApp=async () => {
        await initHttp();
        setSettingApp(false);
    }

    return (

        <ChakraProvider theme={customTheme}>
            {settingUp ?
                <Center h={'100vh'} m={"auto"}><CircularProgress isIndeterminate/></Center> :

                <Flex>
                    {pathname !== '/login' && (
                        <Box w={"15%"} h={"100vh"} background={"#1b73e8"}>
                            <MenuComponent/>
                        </Box>
                    )}
                    <Box w={pathname === '/login' ? "100%" : "85%"} h={"100vh"} overflow={"auto"}
                         p={pathname === '/login' ? "0px" : "24px"} background={"#f4f1ec"}>
                        <PageRoutes/>
                    </Box>

                </Flex>
            }

        </ChakraProvider>
    );
}
