import {
    Box,
    Button,
    Card,
    CircularProgress,
    Flex, Input,
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    Select, Skeleton, Stack,
    Text, Textarea,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {UserAnswer} from "../../interfaes/iep";
import {completeIEPStep, onGetHistory, onSaveAnswers, updateData} from "../../http/iepAPis";
import _findIndex from "lodash/findIndex";
import _groupBy from "lodash/groupBy";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {canAddStudent, isAnswerContentGreaterThanTwo} from "../../utils/common";
import GoalsAndObjectivesView from "./GoalsAndObjectives";
import _find from "lodash/find";

export default function ReviewView({title,subTitle,iepId,firstReview,secondReview,finalReview,onGetData,step,isCompleted,goalsAndObjectives,mainReview}){
    const {t} = useTranslation('iep');
    const toast = useToast();
    const [historyRes,setHistoryRest]=useState([]);


    const [loading,setLoading]=useState(false);
    const [goalId,setGoalId]=useState(undefined);
    const [loadingHistory,setLoadingHistory]=useState(false);
    const { isOpen:isOpenHistory, onOpen:onOpenHistory, onClose:onCloseHistory } = useDisclosure()
    const { isOpen:isOpenReview, onOpen:onOpenReview, onClose:onCloseReview } = useDisclosure()
    const [review,setReview]=useState(new Map());

    useEffect(()=>{
        if(step===4){
            let fMap=new Map();
            firstReview.reviews.map((r)=>{
                let answer= {
                    questionId:r.questionId,
                    iepId:iepId,
                    type:'MULTI_CHOICE',
                    content:r.answer?[r.answer.content]:[],
                    remarks:r.answer?r.answer.remarks:''
                }
                fMap.set(r.questionId,answer)
            })

           setReview(fMap);
        }
        if(step===5){
            let fMap=new Map();
            secondReview.reviews.map((r)=>{
                let answer= {
                    questionId:r.questionId,
                    iepId:iepId,
                    type:'MULTI_CHOICE',
                    content:r.answer?[r.answer.content]:[],
                    remarks:r.answer?r.answer.remarks:''
                }
                fMap.set(r.questionId,answer)
            })

            setReview(fMap);
        }
        if(step===6){
            let fMap=new Map();
            finalReview.reviews.map((r)=>{
                let answer= {
                    questionId:r.questionId,
                    iepId:iepId,
                    type:'MULTI_CHOICE',
                    content:r.answer?[r.answer.content]:[],
                    remarks:r.answer?r.answer.remarks:''
                }
                fMap.set(r.questionId,answer)
            })

            setReview(fMap);
        }

    },[step])


    const btnRef = React.useRef(null)


    const handleSave=async (userAnswers) => {

setLoading(true);

        try {
            await onSaveAnswers(userAnswers);
            onGetData();
            toast({
                title: 'Review Successful',
                description: "Review Submitted Successfully!!!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (e) {
            console.log(e)
            toast({
                title: 'Error!!',
                description: "Could not submit review!!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }finally {
           setLoading(false)
        }





    }
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const handleClose = () => {

        onClose();


    }
    const onCompleteIEP = async () => {
        try {
            setLoading(true)
            await completeIEPStep(iepId,step,!isCompleted);
            toast({
                title: 'Step Completed',
                description: "Review  Completed",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            onGetData();
        } catch (e) {
            console.log(e)
            toast({
                title: 'Error',
                description: "Review Could not be completed !",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            setLoading(false);
            navigate(-1);
        }


    }

    const onGetVersionHistory=async (id) => {
        try {
            setLoadingHistory(true);
            onOpenHistory();
            const response = await onGetHistory(iepId,id);
            setHistoryRest(response.data);
            setLoadingHistory(false);
        }catch (e) {
            toast({
                title: 'Error',
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            setLoadingHistory(false);
        }
    }
    const update=async (newObj) => {
        try {
            setLoadingHistory(true)
            if (step === 4) {

                for (let i = 0; i <= secondReview.reviews.length; i++) {
                    let data = secondReview.reviews[i];
                    if (data) {
                        const obj = {
                            "goalId": data.goalId,
                            "reviewQuestionId": data.questionId,
                            "goalQuestionContent": newObj.get(data.goalId) ? newObj.get(data.goalId).content : '',
                            "objectiveQuestionId": data.objectiveId,
                            "objectiveQuestionContent": newObj.get(data.objectiveId) ? newObj.get(data.objectiveId).content : '',
                            "activityQuestionId": data.activitiesId,
                            "activityQuestionContent": newObj.get(data.activitiesId) ? newObj.get(data.activitiesId).content : '',
                            "materialQuestionId": data.materialId,
                            "materialQuestionContent": newObj.get(data.materialId) ? newObj.get(data.materialId).content : '',
                            "responsibleQuestionId": data.responsibleQuestionId,
                            "responsibleQuestionContent": newObj.get(data.responsibleQuestionId) ? newObj.get(data.responsibleQuestionId).content : '',
                        }
                        await updateData(obj)
                    }
                }
            }
            if (step === 4 || step === 5) {
                for (let i = 0; i <= finalReview.reviews.length; i++) {
                    let data = finalReview.reviews[i];
                    if (data) {
                        const obj = {
                            "goalId": data.goalId,
                            "reviewQuestionId": data.questionId,
                            "goalQuestionContent": newObj.get(data.goalId) ? newObj.get(data.goalId).content : '',
                            "objectiveQuestionId": data.objectiveId,
                            "objectiveQuestionContent": newObj.get(data.objectiveId) ? newObj.get(data.objectiveId).content : '',
                            "activityQuestionId": data.activitiesId,
                            "activityQuestionContent": newObj.get(data.activitiesId) ? newObj.get(data.activitiesId).content : '',
                            "materialQuestionId": data.materialId,
                            "materialQuestionContent": newObj.get(data.materialId) ? newObj.get(data.materialId).content : '',
                            "responsibleQuestionId": data.responsibleQuestionId,
                            "responsibleQuestionContent": newObj.get(data.responsibleQuestionId) ? newObj.get(data.responsibleQuestionId).content : '',
                        }
                        await updateData(obj)
                    }
                }
            }
            onGetData();
            setLoadingHistory(false);
            onCloseReview();
        }catch (e) {
            toast({
                title: 'Error',
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            setLoadingHistory(false);
            onCloseReview();
        }
    }
    const isAnyAnswerContentGreaterThanTwo = mainReview.reviews.some(item => isAnswerContentGreaterThanTwo(item));



    return (
    <Box mt={'12px'}>
        <Text fontWeight={'900'} fontSize={'16px'}>{title} : {subTitle}</Text>
        { firstReview && (
            Object.entries(_groupBy(mainReview.reviews,'goal')).map(([key, value]) => (

        <Card mt={'12px'} border={'1px solid #000'}>
            <Text fontSize={'22px'} fontWeight={900} p={'8px'}>{key}</Text>
            <Flex background={'#1b73e8'} p={'8px'}>
                <Text w={'24%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                    Objectives
                </Text>
                <Text w={'20%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                    Activities
                </Text>
                <Text w={'20%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                    Materials
                </Text>
                <Text w={'8%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                    Review
                </Text>
                <Text w={'18%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                    Comments
                </Text>

                <Box w={'10%'}/>
            </Flex>
            {value.map((r,index)=>
                r.objective && r.objective!=='' &&
                <Box background={r.answer?parseInt(r.answer.content)>2?'#ffffff':'red.200':'#ffffff'} borderBottom={index===value.length-1?'unset':'1px solid #1b73e8'}>
            <Flex p={'8px'} >
                <Text w={'24%'} fontWeight={'500'} fontSize={'16px'} color={'#000000'}>
                    {r.objective}
                </Text>
                <Text w={'20%'} fontWeight={'500'} fontSize={'16px'} color={'#000000'}>
                    {r.activities}
                </Text>
                <Text w={'20%'} fontWeight={'500'} fontSize={'16px'} color={'#000000'}>
                    {r.material}
                </Text>
                <Flex w={'8%'} fontWeight={'500'} fontSize={'16px'} color={'#000000'} >
                    <Select  variant={"filled"} placeholder={"Select"} mr={'12px'}
                             isDisabled={isCompleted || !canAddStudent()}
                     value={review.get(r.questionId)?review.get(r.questionId).content[0]:''}
                             onChange={(e) => {
                                 let answer={};
                                 if(review.get(r.questionId)){
                                      answer=review.get(r.questionId);
                                     answer.content=[e.target.value];
                                 }else {
                                     answer= {
                                         questionId:r.questionId,
                                         iepId:iepId,
                                         type:'MULTI_CHOICE',
                                         content:[e.target.value],
                                         remarks:''
                                     }
                                 }
                                 setReview((prevMap) => {
                                     const newMap = new Map(prevMap);

                                         newMap.set(r.questionId,answer)

                                     return newMap;

                                 });

                             }}
                    >
                        {r.options.map((q)=>
                            <option value={q}>{q}</option>
                        )}

                    </Select>
                    {loading && (
                        <CircularProgress isIndeterminate color='green.300' size={6} ml={'6px'}/>
                    )}

                </Flex>
                <Box w={'18%'} mr={'4px'}>
                    <Textarea variant='filled' placeholder='Comments' value={review.get(r.questionId)?review.get(r.questionId).remarks:''} onChange={(e)=>{
                        let answer={};
                        if(review.get(r.questionId)){
                             answer=review.get(r.questionId);
                            answer.remarks=e.target.value;
                        }else {
                           answer= {
                                questionId:r.questionId,
                                    iepId:iepId,
                                type:'MULTI_CHOICE',
                                content:[],
                                remarks:e.target.value
                            }
                        }

                        setReview((prevMap) => {
                            const newMap = new Map(prevMap);

                            newMap.set(r.questionId,answer)

                            return newMap;

                        });
                    }}/>
                </Box>
                <Flex w={'10%'} cursor={'hover'}>
                    <Button onClick={()=>{
                       handleSave(review.get(r.questionId))
                    }}
                            colorScheme={'blue'}
                            cursor={'pointer'}
                            _hover={{color:'blue',textDecoration:'underline'}} mr={'4px'}>Save</Button>
                    {!isCompleted && r.answer && parseInt(r.answer.content)<=2 && step!==6 && (<Button onClick={()=>{
                        setGoalId(r.goalId);
                        onOpenReview();
                    }}
                                              color={'blue'}
                                              cursor={'pointer'}
                                              _hover={{color:'blue',textDecoration:'underline'}}>Review</Button>)}

                </Flex>
            </Flex>
                </Box>
            )}

        </Card>
            ))
            )}
        {isAnyAnswerContentGreaterThanTwo && (
            <Text fontWeight={500} color={'red.500'} fontStyle={'italic'} mt={'16px'}>
                एक वा एकभन्दा बढि उद्देश्य⁄सिकाइ उपलब्धिको अङ्क १ वा २ मात्र आएको हुनाले पुनः योजना बनाइ थप क्रियाकलाप तथा सहयोग गर्नुपर्ने हुन्छ । अब
                तपाईँले सबै सिकाई उपलब्धीहरू समिक्षा गर्नुपर्छ ।
            </Text>
        )}


        <Flex mt={'16px'} justifyContent={'flex-end'}>


            <Button colorScheme='gray' size='lg' mr={'12px'}  isDisabled={loading} onClick={()=>navigate(-1)}>
               Back
            </Button>
            <Button colorScheme='green' size='lg' onClick={()=>onOpen()}  isDisabled={!canAddStudent()}>
                {isCompleted?t('redoStep'):t('completeStep')}
            </Button>

        </Flex>
        <CoustomAlertDialog
            onClose={handleClose}
            onOpen={onOpen}
            isOpen={isOpen}
            onMainAction={onCompleteIEP}
            loading={loading}
            warning={true}
        />
        {isOpenHistory && (
        <Modal
            onClose={onCloseHistory}
            finalFocusRef={btnRef}
            isOpen={isOpenHistory}
            scrollBehavior={'inside'}
            size={'6xl'}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>History</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {loadingHistory?
                        <Stack>
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                        </Stack>:
                        <Box>
                            {historyRes.map((i)=>
                                <Box>
                                    <Text fontWeight={'900'} fontSize={'20px'} color={'#000000'}>{i.objective}</Text>
                                    <Text fontWeight={'500'} fontSize={'16px'} color={'#484545'}>{i.activities}</Text>
                                </Box>

                            )}
                        </Box>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onCloseHistory}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
            )}
        {isOpenReview && (
            <Modal
                onClose={onCloseReview}
                finalFocusRef={btnRef}
                isOpen={isOpenReview}
                scrollBehavior={'inside'}
                size={'6xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Review Goals And Objectives</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {loadingHistory?
                            <Stack>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>:
                            <Box>
                                <GoalsAndObjectivesView goalsAndObjectives={goalsAndObjectives}
                                                        iepId={iepId} onGetData={onGetData}
                                                        isCompleted={false}
                                                        reviewStep={step}
                                                        goalId={goalId}
                                                        onUpdateObjetives={(data)=>update(data)}
                                />
                            </Box>
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        )}

    </Box>
    )
}
