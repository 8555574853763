import {Box, Image, Progress, Text} from "@chakra-ui/react";
import {AiOutlineUpload} from "react-icons/ai";
import React, {ChangeEvent, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import onGetImageUploadUrl from "../http/uploadImagehandler";
import axios from "axios";

export default function ImageUpload({onSetImage,imageUrl}){
    const {t} = useTranslation('common');

    const [selectedImage, setSelectedImage] = useState(imageUrl);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    const handleImageChange = async (event) => {
        const file = event.target.files?.[0];

        if (file) {
            setSelectedImage(URL.createObjectURL(file));
            onUploadImage(file);
        }
    };

    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onUploadImage=async (image) => {
        try {
            setLoading(true);
            const uploadUrl = await onGetImageUploadUrl();

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "image/jpeg");
            myHeaders.append('x-amz-acl','public-read');

            var file = image;

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: file,
                redirect: 'follow'
            };

            fetch(uploadUrl.data.preSignedUrl, requestOptions)
                .then(response => response.text())
                .then(result =>{
                    onSetImage(uploadUrl.data.preSignedUrl.split('?')[0]);
                    setLoading(false);
                })
                .catch(error => {
                    console.log('error', error);
                    setLoading(false);
                });
        } catch (e) {
            console.log(e)
        }
    }

    if(selectedImage){
        return (
            <Box boxSize='200px'
                 onClick={openFileInput}
                 background={'var(--chakra-colors-gray-100)'} border={'1px solid #000000'}
                 borderRadius={"12px"} cursor={"pointer"}
            >

                <Image  objectFit='contain' height="inherit" src={selectedImage} fallbackSrc='https://via.placeholder.com/150' width={'100%'}/>
                <input type="file" ref={fileInputRef} accept="image/*" onChange={handleImageChange} id="imageSelect" style={{display:'none'}}/>
                {loading && (
                <Progress size='xs' isIndeterminate />
                    )}
            </Box>
        )
    }

    return (
        <Box w={"100%"}
             justifyContent={'center'}
             alignItems={'center'}
             display={'flex'}
             boxSize='200px' background={'var(--chakra-colors-gray-100)'} border={'1px solid #000000'}
             borderRadius={"12px"} cursor={"pointer"}
             onClick={openFileInput}
        >

            <AiOutlineUpload/>

            <Text>{t('uploadPhoto')}</Text>
            <input type="file" ref={fileInputRef} accept="image/*" onChange={handleImageChange} id="imageSelect" style={{display:'none'}}/>

        </Box>
    )
}
