import {School} from "../interfaes/school";
import axios from "axios";

export  async function onAddNewSchool(school: School) {

    return await axios.post(
        '/schools',
        school
    );

}
export  async function onUpdateSchool(school: School,id:string) {

    return await axios.put(
        `/schools/${id}`,
        {
            'school':school
        }
    );

}

export  async function onGetSchools() {
    return await axios.get(
        '/schools/allSchools'
    );
}

export  async function onDeleteSchools(id:String) {
    return await axios.delete(
        `/schools/${id}`
    );
}