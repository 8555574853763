import axios from "axios";
import {School} from "../interfaes/school";
import {Roles, User } from "../interfaes/user";

export  async function onGetUsers() {
    return await axios.get(
        `/users?from=0&to=0`
    );
}

export  async function onGetTherapist() {
    return await axios.get(
        `/users/filter/role?role=THERAPIST`
    );
}
export  async function onGetParent() {
    return await axios.get(
        `/users/filter/role?role=PARENT`
    );
}

export  async function onAddNewUser(user: User) {

    return await axios.post(
        '/users',
        user
    );

}

export  async function onUpdateUser(user: User,id:string) {

    return await axios.put(
        `/users/${id}`,

            user

    );

}

export  async function onChangeUserPassword(password: string,id:string) {

    return await axios.put(
        `/users/${id}/creds`,
        {
            "password":password
        }
    );

}
export  async function onChangeUserRole(role: Roles[],id:string) {

    return await axios.put(
        `/users/${id}/roles`,
        {
            "roles":role
        }
    );

}

export  async function onDeleteUser(id:String) {
    return await axios.delete(
        `/users/${id}`
    );
}

export async function getMyProfile() {
    return await axios.get(
        `/users/profile`
    );
}
