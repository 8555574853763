import {

    Box, Button,
    Center,
    CircularProgress, Flex,
    FormControl,

    FormLabel,

    Select
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import MaterialTable, {Column} from "material-table";
import {createTheme, ThemeProvider} from "@mui/material";
import {useTranslation} from "react-i18next";
import _find from "lodash/find";
import {Address, District, Municipality} from "../../interfaes/address";
import addressData from "../../data/address.json";
import DistictData from "../../data/District.json";
import LocalLevelData from "../../data/localLevelData.json";
import LocalLevelDataNepali from "../../data/localLevelDataNe.json";
import _filter from "lodash/filter";

import {Roles} from "../../interfaes/user";
import {onGetUsers} from "../../http/users";
import {DisabilityType} from "../../interfaes/student";



export default function StudentDashboard(){
    const [loading, setLoading] = useState(true);
    const [localLevel, setLocalLevel] = useState(0);
    const {t,i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [district, setDistrict] = useState(0);
    const [provience, setProvience] = useState('');
    const [iep, setIep] = useState('');
    const [minisupality, setMinisupality] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [serviceArea, setServiceArea] = useState('');
    const [teacherID, setTeacherID] = useState('');
    const [disabilityType, setDisabilityType] = useState('');
    const [disabilityID, setDisabilityID] = useState('');
    const [teachers, setTeachers] = useState([]);
    const districts = DistictData;
    const localLevelData = i18n.language==='en'?LocalLevelData:LocalLevelDataNepali;


    useEffect(() => {
        getUsers();

        onGetDashBoardData('','','','');
    }, [true])

    const getUsers = async () => {
        try {
            const response = await onGetUsers();
            const teacher = response.data.userResponseList.filter((e) => {
                return e?.roles?.includes(Roles.TEACHER)
            })

            setTeachers(teacher);

        } catch (e) {
            console.log(e);
        }
    }
    const disabilityTypeArray = Object.entries(DisabilityType);

    const columns=  [
        {title: t('studentLocal:name'), field: 'nameOfChild', type: 'string'},
        {title: t('studentLocal:gender'), field: 'genderOfChild', type: 'string'},
        {title: t('studentLocal:manageStudents.contact'), field: 'contact', type: 'string'},
        {title: t('studentLocal:manageStudents.serviceArea'), field: 'serviceArea', type: 'string'},
        {title: t('studentLocal:manageStudents.school'), field: 'schoolName', type: 'string'},
        {title: t('users:TEACHER'), field: 'teacherName', type: 'string'},
        {title: t('studentLocal:manageStudents.province'), field: 'province', type: 'province'},
        {title: t('studentLocal:manageStudents.district'), field: 'district', type: 'province'},
        {title: t('studentLocal:manageStudents.address'), field: 'municipality', type: 'province'},
        {title: t('studentLocal:manageStudents.Disability'), field: 'disability', type: 'string'},
        {title: t('studentLocal:manageStudents.disabilityType'), field: 'disabilityType', type: 'string'},
        {title: t('studentLocal:manageStudents.disabilityId'), field: 'disabilitySeverity', type: 'string'},
        {title: t('newLocal:dateOfIEP'), field: 'dateOfIEP',  render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}>


                    {new Date(rowData.dateOfIEP).toDateString()}
                </div>
            )},
        {title: t('newLocal:year'), field: 'year', type: 'string'},
        {title: t('newLocal:numberOfGoals'), field: 'numberOfGoals', type: 'string'},
        {title: t('newLocal:numberOfObjectives'), field: 'numberOfObjectives', type: 'string'},
        {title: t('newLocal:numberOfObjectivesAchieved'), field: 'numberOfObjectivesAchieved', type: 'string'},
    ];
    const defaultMaterialTheme = createTheme();
    const onGetDashBoardData = (p,d,m,sa,t,dt,di,iep) => {
        axios.post('/dashboard/student-progress', {
            "province": p,
            "district": d,
            "municipality": m,
            "teacherId": t,
            "disabilityType": dt,
            "disabilitySeverity": di,
            "serviceArea": sa,
            "iep": iep
        }).then((res) => {
            setData(res.data.data);
            setLoading(false);
        });
    }
    if (loading) {
        return <Center><CircularProgress isIndeterminate/></Center>
    }
    const address = addressData;


    return(
        <Box>
            <Box>
                <Flex mt={'12px'} mb={'12px'} boxShadow='0 3px 10px rgb(0 0 0 / 0.2)' background={'blue.100'} p={'12px'}
                      borderRadius={'12px'} alignItems={"center"}>
                    <FormControl mr={"12px"} >
                        <Select placeholder={t('studentLocal:manageStudents.province')}
                                background={'var(--chakra-colors-gray-100)'}
                                value={provience}
                                onChange={(e) => {
                                    const id = _find(address, ['name', e.target.value]);
                                    setDistrict(id?id.id:0);
                                    setProvience(e.target.value);

                                    onGetDashBoardData(e.target.value,selectedDistrict,minisupality,serviceArea,teacherID,disabilityType,disabilityID);




                                }}
                        >
                            {address.map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>


                    </FormControl>
                    <FormControl mr={"12px"} >

                        <Select placeholder={t('studentLocal:manageStudents.district')}
                                value={selectedDistrict}
                                onChange={(e)=> {

                                    const id = _find(districts, ['name', e.target.value]);
                                    setLocalLevel(id?id.id:0);
                                    setSelectedDistrict(e.target.value)
                                    onGetDashBoardData(provience,e.target.value,minisupality,serviceArea,teacherID,disabilityType,disabilityID);
                                }}

                                background={'var(--chakra-colors-gray-100)'} isDisabled={!provience}>
                            {_filter(districts, function(o) { return o.province_id===district }).map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>


                    </FormControl>
                    <FormControl mr={"12px"} >

                        <Select placeholder={t('studentLocal:manageStudents.locallevel')}
                                value={minisupality}
                                onChange={(e)=> {
                                    setMinisupality(e.target.value);
                                    onGetDashBoardData(provience,selectedDistrict,e.target.value,serviceArea,teacherID,disabilityType,disabilityID);
                                }}

                                isDisabled={!district}
                                background={'var(--chakra-colors-gray-100)'}>
                            {_filter(localLevelData, function(o) { return o.district_id===localLevel }).map((a,index) =>
                                <option value={a.name}>{a.name}</option>
                            )}

                        </Select>
                    </FormControl>
                    <FormControl mr={"12px"} >

                        <Select placeholder={t('studentLocal:manageStudents.serviceArea')}
                                value={serviceArea}
                                onChange={(e)=> {
                                    setServiceArea(e.target.value);
                                    onGetDashBoardData(provience,selectedDistrict,minisupality,e.target.value,teacherID,disabilityType,disabilityID);
                                }}

                                background={'var(--chakra-colors-gray-100)'}>
                            {['COMMUNITY', 'HOME', 'SCHOOL'].map((a,index) =>
                                <option value={a}>{a}</option>
                            )}

                        </Select>
                    </FormControl>
                    <FormControl mr={"12px"} >

                        <Select placeholder={t('users:TEACHER')}
                                value={teacherID}
                                onChange={(e)=> {
                                    setTeacherID(e.target.value);
                                    onGetDashBoardData(provience,selectedDistrict,minisupality,serviceArea,e.target.value,disabilityType,disabilityID);
                                }}

                                background={'var(--chakra-colors-gray-100)'}>
                            {teachers.map((a,index) =>
                                <option value={a.id}>{a.displayName}</option>
                            )}

                        </Select>
                    </FormControl>
                    <FormControl mr={"12px"} >

                        <Select placeholder={t('studentLocal:manageStudents.disabilityType')}
                                value={disabilityType}
                                onChange={(e)=> {
                                    setDisabilityType(e.target.value);
                                    onGetDashBoardData(provience,selectedDistrict,minisupality,serviceArea,teacherID,e.target.value,disabilityID);
                                }}

                                background={'var(--chakra-colors-gray-100)'}>
                            {disabilityTypeArray.map(([key, value]) => (
                                <option value={value}>{t(`studentLocal:manageStudents.${key}`)}</option>
                            ))}

                        </Select>
                    </FormControl>
                    <FormControl mr={"12px"} >

                        <Select placeholder={t('studentLocal:manageStudents.disabilityId')}
                                value={disabilityID}
                                onChange={(e)=> {
                                    setDisabilityID(e.target.value);
                                    onGetDashBoardData(provience,selectedDistrict,minisupality,serviceArea,teacherID,disabilityType,e.target.value);
                                }}

                                background={'var(--chakra-colors-gray-100)'}>
                            { ['Profound Disability (Red Color ID)', 'Severe Disability (Blue Color ID)', 'Moderate Disability (Yellow Color ID)', 'Mild Disability (White Color ID)','NO ID'].map((lang)=>
                                <option value={lang}>{t(`studentLocal:manageStudents.${lang}`)}</option>
                            )}

                        </Select>
                    </FormControl>
                    <Button colorScheme='blue' variant='outline' onClick={()=>{
                        setProvience('');
                        setDistrict('');
                        setMinisupality('');
                        setServiceArea('');
                        setTeacherID('');
                        onGetDashBoardData('','','','','','','')
                    }}>
                        Clear
                    </Button>
                </Flex>
            </Box>
            <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                    title={t('studentLocal:studentList')}
                    columns={columns}
                    data={data}

                    options={{
                        exportButton: {
                            csv: true,
                        },
                        search: true,
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: 100
                    }}
                />
            </ThemeProvider>
        </Box>
    )
}
