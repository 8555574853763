import login from "./login";
import common from "./common";
import studentLocal from "./studentLocal";
import school from "./school";
import users from "./users";
import iep from "./iep";
import newLocal from "./newLocal";

export default {
    login,
    users,
    common,
    studentLocal,
    school,
    iep,
    newLocal
}
