export default {
    displayName: 'Full Name',
    gender: 'Gender',
    address: 'Address',
    phone: 'Phone',
    title: 'Users',
    addUsers: 'Add Users',
    userList: 'Users List',
    description: 'Description',
    male: 'Male',
    female: 'Female',
    others: 'Others',
    uploadPhoto: 'Upload Photo',
    TEACHER: 'Teacher',
    PARENT: 'Parent',
    ADMIN: 'Admin',
    MODERATOR: 'Moderator',
    THERAPIST: 'Therapist',
    selectRole:'Select Roles',
    password:'Password',
    save:'Save',
    cancel:'Cancel',
    roles:'Roles',
    edit:'Edit User',
    delete:'Delete User',
    changePassword:'Change Password',
    resSetPassword:'Reset Password:',
    changeRole:'Change Role',
    associatedStudentsCount:'Student Count',
}