import {Roles, User} from "../interfaes/user";
import axios from "axios";
import {Student} from "../interfaes/student";

export  async function onAddNewStudent(student: Student) {

    return await axios.post(
        '/students',
        student
    );

}


export  async function onUpdateStudent(student: Student,id:string) {

    return await axios.put(
        `/students/${id}`,

            student

    );

}
export  async function onGetStudents() {
    return await axios.get(
        `/students`
    );
}
export  async function onGetMyStudents() {
    return await axios.get(
        `/students/my`
    );
}

export  async function onGetStudent(id:String) {
    return await axios.get(
        `/students/${id}`
    );
}

export  async function onDeleteStudent(id:String) {
    return await axios.delete(
        `/students/${id}`
    );
}
export  async function onChangeRole(id:String,relationshipId:string,transferUserId:string,role:Roles) {
    return await axios.put(
        `/students/${id}/transfer`,
        {
            "relationshipId": relationshipId,
            "transferUserId": transferUserId,
            "relation": role
        }
    );
}
