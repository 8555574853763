import {Avatar, Box, Button, Flex, IconButton, Text, useDisclosure} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material";
import MaterialTable, {Column} from "material-table";
import {FaSchool} from "react-icons/fa";
import {MdDelete} from "react-icons/md";
import {GrEdit} from "react-icons/gr";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {School} from "../../interfaes/school";
import {onDeleteSchools, onGetSchools} from "../../http/school";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";


export default function SchoolPage(){
    const { isOpen, onOpen, onClose } = useDisclosure()

    const {t} = useTranslation('school');
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [deleteIndex,setDeleteIndex] = useState("");
    const [loading,setLoading]=useState(false);
    const columns: Column<School>[] = [
        {title: t('id'), field: 'id', type: 'numeric', hidden: true},
        {
            title: t('schoolName'), field: 'name',            customFilterAndSearch: (term, rowData) => (rowData.name).indexOf(term) !== -1

        },
        {title: t('address'), field:"address",
            render: rowData => (

                (rowData.province && rowData.district && rowData.localAddress && rowData.localLevel) ?
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {`${rowData.province}, ${rowData.district}, ${rowData.localAddress}, ${rowData.localLevel}`}
                        </div> : '-'

            ),
            customFilterAndSearch: (term, rowData) => (`${rowData.province}, ${rowData.district}, ${rowData.localAddress}, ${rowData.localLevel}`).indexOf(term) !== -1

        },
    ];
    const defaultMaterialTheme = createTheme();


    useEffect(()=>{
        getSchools();
    },[])

    const getSchools=async () => {
        try {
            const response = await onGetSchools();
            setData(response.data.items);
        } catch (e) {
            console.log(e)
        }

    }
    const onDeleteSchool=async () => {
        try {
            setLoading(true)
            const response = await onDeleteSchools(deleteIndex);
            const arrayOfObjects = data.filter((obj:School) => obj.id !== deleteIndex);
            setData(arrayOfObjects);

        } catch (e) {
            console.log(e)
        }finally {
            handleClose();
        }
    }

    const handleClose = () => {
      setDeleteIndex('');
        setLoading(false)
      onClose();

    }


    return <Box>
        <Flex justifyContent="space-between">
            <Text fontSize="30px" fontWeight={900}>
                {t('title')}
            </Text>
            <Button leftIcon={<FaSchool/>} background={"#1b73e8"}
                    color={"#ffffff"}
                    _hover={{background: "#1d5094", color: "#ffffff"}}
                    variant='solid' borderRadius={"20px"} mb={"18px"}
                    onClick={()=>navigate("/school/add")}
            >
                {t('addSchool')}
            </Button>
        </Flex>

        <Box mt={"20px"}>
            <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                    title={t('studentList')}
                    columns={columns}
                    data={data}
                    options={{
                        search: true,
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: 20
                    }}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'Edit Student',
                            onClick: (event, rowData) => {
                               navigate("/school/"+event.id,{state:event})
                            }
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Student',
                            onClick: (event, rowData) => {
                                setDeleteIndex(event.id);
                                onOpen();
                            }
                        }
                    ]}
                    components={{
                        Action: props => (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <IconButton
                                    background={'#eeeeee'}
                                    p={"10px"}
                                    borderRadius={"10px"}
                                    aria-label='manage students'
                                    mr={"12px"}
                                    onClick={() => props.action.onClick(props.data)}
                                    icon={props.action.icon === 'edit' ? <GrEdit size="20px" color={"green"}/> :
                                        <MdDelete size="20px" color={"red"}/>}
                                />
                            </div>
                        ),
                    }}
                    //  onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                />
            </ThemeProvider>
        </Box>
        <CoustomAlertDialog
        onClose={handleClose}
        onOpen={onOpen}
        isOpen={isOpen}
        onMainAction={onDeleteSchool}
        loading={loading}
        />
    </Box>
}
