export default {
    title:'Student Details',
    address:'Address',
    parentInformation:'Parent Information',
    relation:'Relation',
    contact:'Contact',
    iep:'Individualized Education Plan',
    addIep:'Add IEP',
    year:'Year',
    level:'Class',
    roll:'Roll No.',
    section:'Section',
    inProgress:'In Progress',
    completed:'Completed',
    iepProcess:'IEP Development Process',
    step1:'Step 1',
    stepTitle:'Strengths And Limitation',
    step2:'Step 2',
    step2Title:'Condition and Need Assessment Checklist (initial)' ,
    step3:'Step 3',
    step3Title:'Goals and Objective Settings',
    step4:'Step 4',
    step4Title:'1st Quarterly Review',
    step5:'Step 5',
    step5Title:'2nd Quarterly Review',
    step6:'Step 6',
    step6Title:'3rd Quarterly Review',
    step7:'Step 7',
    step7Title:'Condition and Need Assessment Checklist (Final)',
    save:'Save',
    back:'Back',
    completeStep:'Complete Step',
    redoStep:'Revise',
    completeIEP:'Complete IEP',
    //===========
    logs:'Logs',
    addLogs:'Add your Logs',
    addTime:'Time',
    addGoal:'Add Goal',
    area:'Area',
    subArea:'Sub Area',
    goal:'Goal',
    enterGoal:'Enter Goal',
    objectFristQtr:'Set Objectives For First  Quater',
    enterObjectives:'Enter Objectives',
    enterActivites:'Enter Activities',
    enterMaterials:'Enter Materials',
    enterResponsible:'Enter Responsible',
    objectiveSecondQtr:'Set Objectives For Second  Quater',
    objectives:'Objectives',
    activities:'Activities and Teaching Strategies',
    materials:'Materials',
    responsible:'Responsible',
    communication:'Communication Strategies (Expressive and Receptive)',
    iepProfile:'Individualized Education Plan',
}
