export default {
    appName: ' Individualized Education Plan-IEP',
    loginText: "Welcome to IEP Management System",
    password: 'Password',
    userName: 'Phone Number',
    loginBtn: 'Login',
    hide:'Hide',
    show:'Show',
    enterUserNmae:'Enter User Name',
    enterPassword:'Enter Password'
}
