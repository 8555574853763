import {
    Button, Flex,
    FormControl, FormErrorMessage, FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select, useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {errorFormat, FormattedError, getErrorMessage, hasErrorMessage} from "../../utils/formUtils";
import NepaliDate from "nepali-date-converter";
import Joi from "joi";
import {addIEP, updateIEP} from "../../http/iepAPis";
import {IEP} from "../../interfaes/iep";

interface ManageIEProps{
    isOpen:boolean,
    onOpen:()=>void,
    onClose:()=>void,
    studentId:string,
    selectedIEP:IEP | undefined

}

export default function ManageIEP({isOpen,onClose,onOpen,studentId,selectedIEP}:ManageIEProps){
    const {t} = useTranslation('iep');
    const [iep,setIep]=useState({
        "studentId": studentId,
        "year": "",
        "level": "",
        "section": "",
        "roll": ""
    });
    const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
    const startYear = 2080;
    const endYear = NepaliDate.now().getYear();
    //const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
    const years = [2080,2081,2082,2083];
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToast()



    useEffect(() => {
        if(selectedIEP){
            setIep({...iep,
                studentId: studentId,
                    year: selectedIEP.year,
                    level: selectedIEP.level,
                    section: selectedIEP.section,
                    roll: selectedIEP.roll
            });
        }
    }, [selectedIEP]);


    const schema = Joi.object({
        year: Joi.string()
            .required(),
        level: Joi.string().optional().allow(''),
        section: Joi.string().optional().allow(''),
        roll: Joi.string().optional().allow(''),
        studentId: Joi.string()
            .required().allow(''),

    });

    const validateObj = () => {
        const {error} = schema.validate(iep);
        if (error) {
            setFormErrors(errorFormat(error.details));
            return false;
        }

        setFormErrors([]);
        return true;
    }

    const onAddIEP=async () => {
        if (validateObj()) {
            try {
                setLoading(true);
                if(selectedIEP){
                    await updateIEP(iep,selectedIEP?.iepId || '');
                    toast({
                        title: 'IEP added!',
                        description: "IEP Added Successfully",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }else{
                    await addIEP(iep);
                    toast({
                        title: 'IEP updated!',
                        description: "IEP Udpated Successfully",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    })
                }

                setLoading(true);
            } catch (e) {
                console.log(e);
                // @ts-ignore
                toast({
                    title: 'Error',
                    // @ts-ignore
                    description: e?.response?.data?.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }finally {
                setLoading(false);
                onClose();

            }
        }
    }


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('iep')}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                 <Flex>
                     <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'year')}>
                         <FormLabel>  {t('year')}</FormLabel>
                         <Select placeholder={t('year')}
                                 onChange={(e) => setIep({...iep, year: e.target.value || ''})}
                                 value={iep.year}
                                 isDisabled={loading || !selectedIEP===undefined}
                                 background={'var(--chakra-colors-gray-100)'}>
                             {years.map((y)=> <option value={y}>{y}</option>)}

                         </Select>
                         <FormErrorMessage>{getErrorMessage(formErrors, 'year')}</FormErrorMessage>
                     </FormControl>
                     <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'level')}>
                         <FormLabel>  {t('level')}</FormLabel>
                         <Input
                             onChange={(e) => setIep({...iep, level: e.target.value || ''})}
                             value={iep.level}
                             isDisabled={loading}

                             variant={"filled"} placeholder={t('level')} required/>
                         <FormErrorMessage>{getErrorMessage(formErrors, 'level')}</FormErrorMessage>
                     </FormControl>
                 </Flex>
                    <Flex mt={"6px"}>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'roll')}>
                            <FormLabel>  {t('roll')}</FormLabel>
                            <Input
                                isDisabled={loading}
                                onChange={(e) => setIep({...iep, roll: e.target.value || ''})}
                                value={iep.roll}

                                variant={"filled"} placeholder={t('roll')} required/>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'roll')}</FormErrorMessage>
                        </FormControl>
                        <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'section')}>
                            <FormLabel>  {t('section')}</FormLabel>
                            <Input
                                isDisabled={loading}
                                onChange={(e) => setIep({...iep, section: e.target.value || ''})}
                                value={iep.section}

                                variant={"filled"} placeholder={t('section')} required/>
                            <FormErrorMessage>{getErrorMessage(formErrors, 'section')}</FormErrorMessage>
                        </FormControl>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button  mr={3}  variant='ghost' onClick={onClose} isDisabled={loading}>
                       Close
                    </Button>
                    <Button colorScheme='blue' onClick={onAddIEP}  isLoading={loading} isDisabled={loading}> Save IEP</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
