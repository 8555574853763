export default {
    title: 'विद्यार्थीको विवरण',
    address: 'ठेगाना',
    parentInformation: 'अभिभावकको जानकारी',
    relation: 'सम्बन्ध',
    contact: 'सम्पर्क',
    iep: 'वैयक्तिक शैक्षिक योजना',
    addIep: 'वैयक्तिक शैक्षिक योजना थप्नुहोस्',
    year: 'वर्ष',
    level: 'कक्षा',
    roll: 'रोल नम्बर',
    section: 'सेक्सन',
    inProgress: 'प्रगतिमा छ',
    completed: 'पूरा भयो',
    iepProcess: 'योजना निर्माण प्रक्रिया',
    step1: 'चरण १',
    stepTitle: 'सबल पक्ष र कठिनाइ',
    step2: 'चरण २',
    step2Title: 'अवस्था र आवश्यकताको रूजु सूची (प्रारम्भिक)',
    step3: 'चरण ३',
    step3Title: 'लक्ष्य र उद्देश्यहरू',
    step4: 'चरण ४',
    step4Title: 'पहिलो चौमासिक समीक्षा',
    step5: 'चरण ५',
    step5Title: 'दोस्रो चौमासिक समीक्षा',
    step6: 'चरण ६',
    step6Title: 'तेस्रो चौमासिक समीक्षा',
    step7: 'चरण ७',
    step7Title: 'अवस्था र आवश्यकताको रूजु सूची (अन्तिम)',
    save: 'सेभ गर्नुहोस्',
    back: 'पछाडी फर्कनुहोस्',
    completeStep: ' यो चरण पूरा गर्नुहोस्',
    redoStep: ' यस चरणलाई पुनः भर्नुहोस्',
    completeIEP: 'योजना निर्माणलाई समापन गर्नुहोस्',
    iepProfile:'वैयक्तिक शैक्षिक योजना'
}
