import axios from "axios";
import {json} from "react-router";

export function initHttp(){
    axios.defaults.baseURL = 'https://iepnepal.com/api';
   return  axios.interceptors.request.use(function (config) {
        // Do something before request is sent
      if(config.url!=='/auth/login') {
          const token = JSON.parse(localStorage?.getItem('user') || '');
          if (token) config.headers.Authorization = `Bearer ${token.access_token}`;
      }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });


}
