import {Box, Button, Flex, FormControl, FormLabel, Text, Textarea, useDisclosure, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StrengthAndLimitation, UserAnswer} from '../../interfaes/iep'
import {addBulkAnswers, completeIEPStep, onSaveAnswers} from "../../http/iepAPis";
import {useNavigate} from "react-router-dom";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {canAddStudent} from "../../utils/common";



 type StrengthAndLimitationPropsType = {
    strengthAndLimitation:StrengthAndLimitation[]
    iepId:string
     onGetData:()=>void;
     isCompleted:boolean | undefined;
};

export default function StrengthAndLimitationView({strengthAndLimitation,iepId,onGetData,isCompleted}:StrengthAndLimitationPropsType){
    const {t,i18n} = useTranslation('iep');
    const [userAnswers,setUserAnswers]=useState<UserAnswer[]>([]);
    const [loading,setLoading]=useState<boolean>(false);
    const navigate = useNavigate();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast()


    useEffect(() => {
        onManageAnswers();
    }, [strengthAndLimitation]);

    const onManageAnswers=()=>{
        let updatedAns:UserAnswer[]=[];
        strengthAndLimitation.forEach((s)=>{
            const answer:UserAnswer={
                questionId:s.questionId,
                iepId:iepId,
                type:s.type,
                content:[s.answer.content]
            }
            updatedAns.push(answer);
        })

        setUserAnswers([...updatedAns])
    }

    const handleSave=async () => {
        setLoading(true);


            for (let i = 0; i < userAnswers.length; i++) {
                try {
                    if(!userAnswers[i].content || userAnswers[i].content[0]!=='') {
                        await onSaveAnswers(userAnswers[i])
                        if(i===userAnswers.length-1) {
                            toast({
                                title: 'Strength and Limitation !',
                                description: "Strength and Limitation saved Successfully!!!",
                                status: 'success',
                                duration: 3000,
                                isClosable: true,
                            })
                        }
                    }
                    onGetData();
                } catch (e) {
                    if(i===userAnswers.length-1) {
                        toast({
                            title: 'Error',
                            description: "Strength and Limitation could not be saved!!!",
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    }
                    console.log(e)
                }
            }
            setLoading(false);



    }

    const handleClose = () => {

        onClose();


    }

    const onCompleteIEP = async () => {
        try {
            setLoading(true)
            await completeIEPStep(iepId,1,!isCompleted);
            onGetData();
            toast({
                title: 'Strength and Limitation !',
                description: "Strength and Limitation completed Successfully!!!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (e) {
            console.log(e)
            toast({
                title: 'Error!!',
                description: "Strength and Limitation Could not be completed!",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            setLoading(false);
            navigate(-1);
        }


    }

    return (
        <Box mt={'12px'}>
            <Text fontWeight={'900'} fontSize={'16px'}>{t('step1')} : {t('stepTitle')}</Text>
            {strengthAndLimitation.map((item,index)=>
            <FormControl mt={"12px"} >
                <FormLabel>{i18n.language==='en'?item.question:item.questionNP}</FormLabel>
                <Textarea
                    isDisabled={isCompleted || !canAddStudent()}
                    _disabled={{background:  '#f5f1f1',color:'#000000',border:'1px solid'}}
                    value={userAnswers[index]?userAnswers[index].content?userAnswers[index].content[0]:'':''}
                    onChange={(e) => {
                        let ans=[];
                        ans.push(e.target.value);
                        const answer:UserAnswer={
                            questionId:item.questionId,
                            iepId:iepId,
                            type:item.type,
                            content:ans
                        }
                        let answers=userAnswers;
                        answers[index]=answer;
                        setUserAnswers([...answers])
                    }}

                    variant={"filled"} placeholder={item.question} required/>
                {/*<FormErrorMessage>{getErrorMessage(formErrors, 'fullName')}</FormErrorMessage>*/}
            </FormControl>
                )}
            <Flex mt={'16px'} justifyContent={'flex-end'}>


            <Button colorScheme='gray' size='lg' mr={'12px'}  isDisabled={loading} onClick={()=>navigate(-1)}>
                {t('back')}
            </Button>
                {!isCompleted && (
            <Button colorScheme='blue' size='lg' isLoading={loading} mr={'12px'} isDisabled={loading} onClick={handleSave}>{t('save')}</Button>
                    )}
                <Button colorScheme='green' size='lg'   onClick={()=>onOpen()}>
                    {isCompleted?t('redoStep'):t('completeStep')}
                </Button>
            </Flex>
            <CoustomAlertDialog
                onClose={handleClose}
                onOpen={onOpen}
                isOpen={isOpen}
                onMainAction={onCompleteIEP}
                loading={loading}
                warning={true}
            />
        </Box>
    )
}
