import {Box, Text} from "@chakra-ui/react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function StudentProgress({studentID}){

    const [progress,setProgress]=useState([])

    useEffect(() => {
        onStudentProgress()
    }, [studentID]);
    const onStudentProgress = () => {
        axios.post('/dashboard/student-performance', {
            "studentId": studentID,
            "area": "",
            "subArea": ""
        }).then((res) => {
            const data = [];
            if(res.data.data) {
                res.data.data.forEach((e) => {
                    data.push({
                        name: e.year,
                        score: e.reviewScore,
                        percentage: e.percent,
                    },)
                })
                setProgress(data);
            }
        });
    }
    return (
        <Box>
            <Text fontSize={'30px'} fontWeight={'900'} textAlign={'center'}>Student Progress</Text>
            {progress.length===0 ?
                <Text fontSize={'20px'} fontWeight={'500'} textAlign={'center'}>Progress not recorded yet.</Text>:


            <LineChart
                    width={500}
                    height={300}
                    data={progress}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="percentage" stroke="#82ca9d" />
                </LineChart>
            }

        </Box>
    )
}
