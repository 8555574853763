import {Box, Divider, Flex, Image, Text} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {RelationShip} from "../../interfaes/student";
import {Roles} from "../../interfaes/user";
import {IEP, UserAnswer} from "../../interfaes/iep";
import {useLocation, useParams} from "react-router-dom";
import StrengthAndLimitationView from "./strengthAndLimitation";
import CheckListAreas from "./CheckListAreas";
import GoalsAndObjectives from "./GoalsAndObjectives";
import GoalsAndObjectivesView from "./GoalsAndObjectives";
import ReviewView from "./ReviewView";
import {onGetSingleIEP} from "../../http/iepAPis";

export default function IepSteps(){
    const {t} = useTranslation('iep');
    const {state} = useLocation();
    const {step}=useParams();
    const [iepFormAPI,setIEPFormAPI]=useState<IEP | undefined>(undefined);
    const {iepId} = useParams();


    const getIEP=async () => {
        if(iepId && state) {
            try {
                const response=await onGetSingleIEP(state?.student.studentId, iepId);
                setIEPFormAPI({...response.data});
            }catch (e) {
                console.log(e);
            }
        }
    }
    useEffect(() => {
        getIEP()
    }, [iepId]);



    const getComponentByStep=()=>{
        if(iepFormAPI) {
            const iep: IEP = iepFormAPI;
            switch (step) {
                case 'step1':
                    return <StrengthAndLimitationView strengthAndLimitation={iep.iepSteps?.strengthAndLimitations || []}
                                                      iepId={iep?.iepId ? iep.iepId : ''} onGetData={getIEP}
                                                      isCompleted={iep.completedSteps?.includes(1)}/>;
                case 'step2':
                    return <CheckListAreas checkListArea={iep.iepSteps?.checkListAreas || []}
                                           checkListAreaOld={iep.iepSteps?.checkListAreas || []}
                                           iepId={iep?.iepId ? iep.iepId : ''} title={t('step2')}
                                           subTitle={t('step2Title')} onGetData={getIEP} step={2}
                                           isCompleted={iep.completedSteps?.includes(2)}/>;

                case 'step3':
                    return <GoalsAndObjectivesView goalsAndObjectives={iep.iepSteps?.goalsAndObjectives}
                                                   iepId={iep?.iepId ? iep.iepId : ''} onGetData={getIEP}
                                                   isCompleted={iep.completedSteps?.includes(3)}
                                                   goalId={undefined}
                                                   reviewStep={0}
                                                   onUpdateObjetives={()=>{}}
                    />;
                case 'step4':
                    return <ReviewView title={t('step4')} subTitle={t('step4Title')} iepId={iep?.iepId ? iep.iepId : ''}
                                       firstReview={iep.iepSteps?.firstReview}
                                       secondReview={iep.iepSteps?.secondReview}
                                       finalReview={iep.iepSteps?.finalReview}
                                       mainReview={iep.iepSteps?.firstReview}
                                       onGetData={getIEP} step={4}
                                       isCompleted={iep.completedSteps?.includes(4)}
                                       goalsAndObjectives={iep.iepSteps?.goalsAndObjectives}
                    />;
                case 'step5':
                    return <ReviewView title={t('step5')} subTitle={t('step5Title')} iepId={iep?.iepId ? iep.iepId : ''}
                                       firstReview={iep.iepSteps?.firstReview}
                                       secondReview={iep.iepSteps?.secondReview}
                                       finalReview={iep.iepSteps?.finalReview}
                                       mainReview={iep.iepSteps?.secondReview}
                                       onGetData={getIEP} step={5}
                                       isCompleted={iep.completedSteps?.includes(5)}
                                       goalsAndObjectives={iep.iepSteps?.goalsAndObjectives}
                    />;
                case 'step6':
                    return <ReviewView title={t('step6')} subTitle={t('step6Title')} iepId={iep?.iepId ? iep.iepId : ''}
                                       firstReview={iep.iepSteps?.firstReview}
                                       secondReview={iep.iepSteps?.secondReview}
                                       finalReview={iep.iepSteps?.finalReview}
                                       mainReview={iep.iepSteps?.finalReview}
                                       onGetData={getIEP} step={6}
                                       isCompleted={iep.completedSteps?.includes(6)}
                                       goalsAndObjectives={iep.iepSteps?.goalsAndObjectives}
                    />;
                case 'step7':
                    return <CheckListAreas checkListArea={iep.iepSteps?.finalChecklist || []}
                                           checkListAreaOld={iep.iepSteps?.checkListAreas || []}
                                           iepId={iep?.iepId ? iep.iepId : ''} title={t('step7')}
                                           subTitle={t('step7Title')} onGetData={getIEP} step={7}
                                           isCompleted={iep.completedSteps?.includes(7)}

                    />;


                default:
                    return <Box>No Step Found</Box>


            }
        }
    }


    if (state) {
        const iep: IEP = iepFormAPI?iepFormAPI:state.iep;
        const parent = state?.student.relationships?.filter((r: RelationShip) => r.relation === Roles.PARENT);
        return (
            <Box>
                <Flex justifyContent="space-between">
                    <Text fontSize="30px" fontWeight={900}>
                        {t('iep')} : {iep.year}
                    </Text>
                </Flex>
                <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
                    <Flex>
                        <Box w={"10%"}>
                            <Image
                                borderRadius='full'
                                w={"100%"}
                                src={state.studentImage}
                                alt='Dan Abramov'
                                fallbackSrc='https://via.placeholder.com/150'
                            />
                        </Box>
                        <Box w={"2%"}/>
                        <Box w={"78%"}>
                            <Text fontWeight={'900'} fontSize={'24px'}>
                                {state.student.fullName}
                            </Text>
                            <Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                                {`${t('address')} : ${state.student.province} | ${state.student.district} | ${state.student.localLevel} | ${state.student.localAddress}`}
                            </Text>
                            {parent && (<Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                                {`${t('parentInformation')} : ${parent[0].relatedUser.displayName} | ${t('relation')} : ${parent[0].relation} | ${t('contact')} : ${parent[0].relatedUser.phone}`}
                            </Text>)}
                            <Text fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                                {`${t('level')} : ${iep.level} | ${t('section')} : ${iep.section} | ${t('roll')} : ${iep.roll}`}
                            </Text>
                        </Box>
                    </Flex>
                    {getComponentByStep()}
                </Box>


            </Box>
        );
    }else {
        return <Box>Student Not Found</Box>
    }
}
