import React, {useState} from 'react';
import {
    Box,
    Button,
    Center,
    Flex,
    FormControl,
    FormErrorMessage,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useToast
} from "@chakra-ui/react";
import LoginGraphics from "../../images/login_graphics.png";
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import { onHandelLogin } from '../../http/login';
import {Credencials} from "../../interfaes/user";
import Joi from 'joi';
import {errorFormat, FormattedError, getErrorMessage, hasErrorMessage} from '../../utils/formUtils';

export default function LoginPage() {
    const toast = useToast()
    const schema = Joi.object({
        username: Joi.string()
            .min(3)
            .max(50)
            .required(),

        password: Joi.string().required()
    });

    const [loading, setLoading] = useState<boolean>(false);


    const navigate = useNavigate();
    const { t } = useTranslation('login');
    const [show, setShow] = React.useState(false)
    const [loginObj,setLoginObj]=useState<Credencials>({
        username:"",
        password:""
    });
    const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
    const handleClick = () => setShow(!show)

    const validateObj=()=>{
        const { error } = schema.validate(loginObj);

        if (error) {
            setFormErrors(errorFormat(error.details));
            return false;
        }

        setFormErrors([]);
        return true;
    }

    const onLogin=async () => {
        if(validateObj()) {
            try {
                setLoading(true);


                const response=await onHandelLogin(loginObj);
                localStorage.setItem('user',JSON.stringify(response.data));
                setLoading(false);
                toast({
                    title: 'Welcome!!!',
                    description: "Welcome to IEP!!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                navigate('/');
            } catch (e) {
                console.log(e)
                setLoading(false);
                toast({
                    title: 'Someting Went Wrong',
                    description: "We could not login at the moment. Please contact authorities for support.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
        }

    }

    return (<Flex height={"100vh"}>
        <Box w={["50%"]} display={["none", "flex"]} background={"#f9fbfc"}>
            <Box m="auto">
                <Box>
                    <Text fontSize="30px" fontWeight={900} textAlign={"center"}>
                        {t('login:appName')}
                    </Text>
                </Box>
                <Center>
                    <Box boxSize='sm'>
                        <Image src={LoginGraphics}/>
                    </Box>
                </Center>
            </Box>
        </Box>
        <Box w={["100%", "50%"]} background={"#1b73e8"} display={"flex"}>
            <Box ml={12} mr={12} mt={"auto"} mb={"auto"} w={"100%"}>
                <Box display={["block", "none"]} mt={"12px"} mb={"16px"}>

                    <Text fontSize="30px" fontWeight={900} textAlign={"center"}>
                        {t('login:appName')}
                    </Text>
                </Box>
                <Text fontSize="30px" fontWeight={900} textAlign={"center"} color={"#ffffff"}>
                    {t('login:loginText')}
                </Text>
                <Box>
                    <Text color={"#ffffff"} mb={"6px"}>
                        {t('login:userName')}
                    </Text>
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'username')} isRequired>
                    <Input
                        background={"#ffffff"}
                        _focus={{
                            bg: '#ffffff', // Change 'customColor' to the color you want
                        }}
                        pr='4.5rem'
                        type={'text'}
                        value={loginObj.username}
                        placeholder={t('login:enterUserNmae')}
                        variant='filled'
                        onChange={(e)=> setLoginObj({...loginObj,username:e.target.value || ''})}
                    />
                        <FormErrorMessage>{getErrorMessage(formErrors, 'username')}</FormErrorMessage>
                    </FormControl>

                </Box>
                <Box mt={"12px"}>
                    <Text color={"#ffffff"} mb={"6px"}>
                        {t('login:password')}
                    </Text>
                    <FormControl isInvalid={hasErrorMessage(formErrors, 'password')} isRequired>
                    <InputGroup size='md'>

                        <Input
                            background={"#ffffff"}
                            _focus={{
                                bg: '#ffffff', // Change 'customColor' to the color you want
                            }}
                            pr='4.5rem'
                            type={show ? 'text' : 'password'}
                            placeholder={t('login:enterPassword')}
                            variant='filled'
                            value={loginObj.password}
                            onChange={(e)=> setLoginObj({...loginObj,password:e.target.value || ''})}

                        />
                        <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClick}>
                                {show ? t('login:hide') : t('login:show')}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                        <FormErrorMessage>{getErrorMessage(formErrors, 'password')}</FormErrorMessage>
                    </FormControl>
                </Box>
                <Button w="100%" background={"#004aad"} color={"#ffffff"} border={"1px solid #ffffff"} mt={'20px'}
                        _hover={{
                            background: '#ffffff', color: '#004aad', border: '1px solid #004aad'
                        }}
                        onClick={onLogin}
                        isLoading={loading} isDisabled={loading}
                >
                    {t('login:loginBtn')}
                </Button>
            </Box>
        </Box>
    </Flex>)
}
