import {
    Avatar,
    Box,
    Button, Checkbox,
    Flex,
    FormControl, FormErrorMessage,
    FormLabel,
    IconButton,
    Image,
    Input, InputGroup, InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, Select,
    Text,
    Tooltip,
    useDisclosure, useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import MaterialTable, {Column} from "material-table";
import {createTheme, ThemeProvider} from "@mui/material";
import {FaSchool} from "react-icons/fa";
import {GrEdit} from "react-icons/gr";
import {MdDelete} from "react-icons/md";
import {Roles, User} from "../../interfaes/user";
import {onChangeUserPassword, onChangeUserRole, onDeleteUser, onGetUsers} from "../../http/users";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {onDeleteSchools} from "../../http/school";
import {School} from "../../interfaes/school";
import {CgArrowsExchange,CgPassword} from "react-icons/cg";
import {getErrorMessage, hasErrorMessage} from "../../utils/formUtils";

export default function UserPage(){
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen:isOpenChnagePassword, onOpen:onOpenChangePassword, onClose:onCloseChangePassword } = useDisclosure()
    const { isOpen:isOpenChnageRole, onOpen:onOpenChnageRole, onClose:onCloseChnageRole } = useDisclosure()
    const [show, setShow] = React.useState(false)
    const [newPassword, setNewPassword] = React.useState('')
    const [searchParams] = useSearchParams();
    const role = searchParams.get('role');
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const {t} = useTranslation('users');
    const navigate = useNavigate();
    const [data,setData] = useState([]);
    const [filterData,setFilterData] = useState([]);
    const [roles,setRoles] = useState<Roles[]>([]);
    const [deleteIndex,setDeleteIndex] = useState("");
    const [loading,setLoading]=useState(false);
    const columns: Column<User>[] = [
        {title: t('id'), field: 'id', type: 'numeric', hidden: true},

        {
            title: t('displayName'), field: 'displayName',
            render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}>

                        <Avatar name={rowData.displayName} src={rowData.image} mr={'12px'} w={'40px'} h={'40px'} background={'#e2e8f0'}/>


                    {rowData.displayName}
                </div>
            )
        },
        {title: t('roles'), field:"roles",
        render:rowData=>(
            <div>
                {(rowData.roles)?.join(' ,')}
            </div>
    )
        },
        {title: t('address'), field:"address"},
        {title: t('associatedStudentsCount'), field:"associatedStudentsCount"},

        {title: t('phone'), field:"phone"},
    ];
    const defaultMaterialTheme = createTheme();

    useEffect(()=>{
        getUsers();
    },[])

    const getUsers=async () => {
        try {
            console.log(role);
            const response = await onGetUsers();
            setData(response.data.userResponseList);
            if(role==='all' || !role){
                setFilterData(response.data.userResponseList)
            }else{

                setFilterData(response.data.userResponseList.filter((u:User)=>{
                    if(u.roles) {
                        // @ts-ignore
                        return u?.roles.includes(role)
                    }
                }))

            }
        }catch(e)
        {
            console.log(e);
        }
    }

    const handleClose = () => {
        setDeleteIndex('');
        setLoading(false)
        setRoles([]);
        setNewPassword('')
        onClose();
        onCloseChangePassword();
        onCloseChnageRole();
    }

    const onDeleteSchool=async () => {
        try {
            setLoading(true)
            const response = await onDeleteUser(deleteIndex);
            const arrayOfObjects = filterData.filter((obj:School) => obj.id !== deleteIndex);
            setFilterData(arrayOfObjects);
            getUsers();
            toast({
                title: 'Deleted',
                description: "User Deleted Successfully!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })


        } catch (e) {
            toast({
                title: 'Error',
                // @ts-ignore
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }finally {
            handleClose();
        }
    }
    const onChangePassword=async () => {
        try {
            setLoading(true)
            const response = await onChangeUserPassword(newPassword,deleteIndex);
            toast({
                title: 'Password changed',
                description: "Password Changed Successfully",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

        } catch (e) {
            toast({
                title: 'Error',
                // @ts-ignore
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }finally {
            handleClose();
        }
    }
    const onChangeRole=async () => {
        try {
            setLoading(true)
            const response = await onChangeUserRole(roles,deleteIndex);
            getUsers();
            toast({
                title: 'Role Changed',
                description: "User Role Changed Successfully!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

        } catch (e) {
            toast({
                title: 'Error',
                // @ts-ignore
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        }finally {
            handleClose();
        }
    }

    const onGetIcon = (id: string) => {
        if (id === 'edit') {
            return <Tooltip label={t('edit')} shouldWrapChildren><GrEdit size="20px" color={"red"}/></Tooltip>;

        } else if (id === 'resetPassword') {
            return <Tooltip label={t('changePassword')} shouldWrapChildren><CgPassword size="20px" color={"orange"}/></Tooltip>;
        } else if (id === 'changeRole') {
            return <Tooltip label={t('changeRole')} shouldWrapChildren><CgArrowsExchange size="20px" color={"blue"}/></Tooltip>;
        } else {

            return <Tooltip label={t('delete')} shouldWrapChildren><MdDelete size="20px" color={"red"}/></Tooltip>
        }


    }
    const filterUser=(role:string)=>{
        //filter user on the basis of role
        if(role==='all' || !role){
                setFilterData(data)
        }else{

            setFilterData(data.filter((u:User)=>{
                if(u.roles) {
                    // @ts-ignore
                   return u?.roles.includes(role)
                }
            }))

        }


    }

    return(
        <Box>
            <Flex justifyContent="space-between">
                <Text fontSize="30px" fontWeight={900}>
                    {t('title')}
                </Text>

                <Button leftIcon={<FaSchool/>} background={"#1b73e8"}
                        color={"#ffffff"}
                        _hover={{background: "#1d5094", color: "#ffffff"}}
                        variant='solid' borderRadius={"20px"} mb={"18px"}
                        onClick={()=>navigate("/user/add")}
                >
                    {t('addUsers')}
                </Button>
            </Flex>
            <Box style={{background:'#fff',width:'40%'}}>
                <Select placeholder='Select User' defaultValue={role?? 'all'} onChange={(e)=>filterUser(e.target.value)}>
                    <option value={"all"}>All</option>
                    <option value={Roles.TEACHER}>{Roles.TEACHER}</option>
                    <option value={Roles.PARENT}>{Roles.PARENT}</option>
                    <option value={Roles.THERAPIST}>{Roles.THERAPIST}</option>
                </Select>
            </Box>

            <Box mt={"20px"}>
                <ThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                        title={t('userList')}
                        columns={columns}
                        data={filterData}
                        options={{
                            search: true,
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: 50
                        }}
                        actions={[
                            {
                                icon: 'edit',
                                tooltip: 'Edit Student',
                                onClick: (event, rowData) => {
                                    navigate("/user/"+event.id,{state:event})
                                }
                            },
                            {
                                icon: 'delete',
                                tooltip: 'Delete Student',
                                onClick: (event, rowData) => {
                                    setDeleteIndex(event.id);
                                    onOpen();
                                }
                            },
                            {
                                icon: 'resetPassword',
                                tooltip: 'Delete Student',
                                onClick: (event, rowData) => {
                                    setDeleteIndex(event.id);
                                    onOpenChangePassword();
                                }
                            },
                            {
                                icon: 'changeRole',
                                tooltip: 'Delete Student',
                                onClick: (event, rowData) => {
                                    setDeleteIndex(event.id);
                                    setRoles(event.roles);
                                    onOpenChnageRole();
                                }
                            }
                        ]}
                        components={{
                            Action: props => (
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <IconButton
                                        background={'#eeeeee'}
                                        p={"10px"}
                                        borderRadius={"10px"}
                                        aria-label='manage students'
                                        mr={"12px"}
                                        onClick={() => props.action.onClick(props.data)}
                                        icon={onGetIcon(props.action.icon)}
                                    />
                                </div>
                            ),
                        }}
                        //  onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                    />
                </ThemeProvider>
            </Box>
            <CoustomAlertDialog
                onClose={handleClose}
                onOpen={onOpen}
                isOpen={isOpen}
                onMainAction={onDeleteSchool}
                loading={loading}
            />
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpenChnagePassword}
                onClose={handleClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('resSetPassword')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl isRequired>
                            <InputGroup size='md'>

                                <Input
                                    background={"#ffffff"}
                                    _focus={{
                                        bg: '#ffffff', // Change 'customColor' to the color you want
                                    }}
                                    pr='4.5rem'
                                    type={show ? 'text' : 'password'}
                                    placeholder={t('login:enterPassword')}
                                    variant='filled'
                                    value={newPassword}
                                    onChange={(e)=> setNewPassword(e.target.value)}

                                />
                                <InputRightElement width='4.5rem'>
                                    <Button h='1.75rem' size='sm' onClick={()=>setShow(!show)}>
                                        {show ? t('login:hide') : t('login:show')}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                        </FormControl>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} isDisabled={!newPassword || loading}
                                 isLoading={loading}
                                onClick={()=>onChangePassword()}>
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose} isDisabled={loading}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpenChnageRole}
                onClose={handleClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{t('resSetPassword')}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Box background={'var(--chakra-colors-gray-100)'} borderRadius={'6px'} padding={'8px'}>

                            {Object.keys(Roles).map(key =>
                                <Checkbox colorScheme='red'  mr={'16px'} value={key}

                                          isChecked={roles?roles.includes(key as Roles):false}
                                          onChange={()=>{
                                              if(roles) {
                                                  let selectedRole = key as Roles;
                                                  let updatedRoles = roles;
                                                  if (roles.includes(selectedRole)) {
                                                      updatedRoles = updatedRoles.filter(item => item !== selectedRole);

                                                  } else {
                                                      updatedRoles.push(selectedRole);

                                                  }
                                                  setRoles([...updatedRoles])
                                              }

                                          }}>
                                    {t(`${key}`)}
                                </Checkbox>
                            )}


                        </Box>

                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} isDisabled={!roles.length || loading}
                                isLoading={loading}
                                onClick={()=>onChangeRole()}>
                            {t('save')}
                        </Button>
                        <Button onClick={handleClose} isDisabled={loading}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    )
}
