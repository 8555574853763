// chakra-theme.js
import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
    fonts: {
        body: "'Mukta', sans-serif",
        heading: "'Mukta', sans-serif",

    },
});

export default customTheme;
