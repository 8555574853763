import {stubFalse} from "lodash";

export function isLogin(){
    const user=localStorage.getItem('user');
    if(user){
        return true;
    }
    return false;
}

export function canAddStudent(){
    const user=localStorage.getItem('user');

    // @ts-ignore
    if(user ){
        const parsedUser=JSON.parse(user);
        // @ts-ignore
        return  parsedUser.roles.includes("TEACHER") ;
    }
    return false;
}

export function canTransferStudent(){
    const user=localStorage.getItem('user');

    // @ts-ignore
    if(user ){
        const parsedUser=JSON.parse(user);
        // @ts-ignore
        return  parsedUser.roles.includes("TEACHER") || parsedUser.roles.includes("ADMIN")  || parsedUser.roles.includes("SUPER_ADMIN") ;
    }
    return false;
}

export function isTeacher(){
    const user=localStorage.getItem('user');

    // @ts-ignore
    if(user ){
        const parsedUser=JSON.parse(user);
        // @ts-ignore
        return parsedUser.roles.includes("MODERATOR") || parsedUser.roles.includes("ADMIN") || parsedUser.roles.includes("TEACHER") ;
    }
    return false;
}
export function isTeacherOnly(){
    const user=localStorage.getItem('user');

    // @ts-ignore
    if(user ){
        const parsedUser=JSON.parse(user);
        // @ts-ignore
        if(parsedUser.roles.includes("ADMIN") || parsedUser.roles.includes("MODERATOR") || parsedUser.roles.includes("SUPER_ADMIN")){
            return false;
        }

        return parsedUser.roles.includes("TEACHER");
    }
    return false;
}
export function isAnswerContentGreaterThanTwo(obj:any) {
    return typeof obj.answer === 'object' && typeof obj.answer?.content === 'string' && parseFloat(obj.answer?.content) <= 2;
}
export function timeDifference(current:number, previous:number) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago';
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';
    }

    else {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';
    }
}
