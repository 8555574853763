import {Roles, User} from "./user";

export enum DisabilityType {
    Physicy_Disability = 'Physicy Disability',
    Visual_Disability = 'Visual Disability',
    Hearing_Disability = 'Hearing Disability',
    Speech_Related = 'Speech Related',
    Disability = 'Disability',
    Deafblindness = 'Deafblindness',
    Intellectual_Disability = 'Intellectual Disability',
    Autism = 'Autism',
    Hemophilia = 'Hemophilia',
    Multiple_Disabilities = 'Multiple Disabilities'
}


export interface Student {
    studentId?: string
    fullName: string
    dob: number
    gender: string
    language: string
    disabilityType: string
    category: string
    specialDisability: string
    contact: string
    schoolId: string
    province: string
    district: string
    localLevel: string
    localAddress: string
    tempProvince: string
    tempDistrict: string
    tempLocalLevel: string
    tempLocalAddress: string
    serviceArea: string
    parentId: string
    therapistId: string
    IEMISNumber: string
    createdByUserId?: string
    disabilityId: string
    created?: number
    updated?: number
    relationships?: RelationShip[]
    studentImage?:string
    disabilityDetail?:string
}

export interface RelationShip {
    relationshipId: string,
    relation: Roles,
    relatedUser: User

}
