export default {
    id: 'ID',
    name: 'नाम',
    gender: 'लिङ्ग',
    parentsName: 'अभिभावकको नाम',
    teacherName: 'शिक्षक नाम',
    therapist: 'थेरापिस्टको नाम',
    age: 'उमेर',
    studentList: 'विद्यार्थी सूची',
    students: 'विद्यार्थीहरू',
    addStudents: 'नयाँ विद्यार्थी थप्नुहोस्',
    upcommingReview: 'आउँदो समीक्षा',
    manageStudents: {
        age:'उमेर',
        title: 'नयाँ विद्यार्थी थप्नुहोस्',
        studnetsDetails: 'विद्यार्थी विवरण',
        fullName: 'पूरा नाम',
        enterfullName: 'पूरा नाम प्रविष्ट गर्नुहोस्',
        dob: 'जन्म मिति',
        gender: 'लिङ्ग',
        genderPlaceholder: 'लिङ्ग चयन गर्नुहोस्',
        male: 'पुरुष',
        female: 'महिला',
        others: 'अन्य',
        nativeLanguage: 'मातृभाषा',
        disability: ' मनाेसामाजिक अपाङ्गता',
        disabilityPlaceholder: 'अपाङ्‍गता छ वा छैन चयन गर्नुहोस्',
        yes: 'छ',
        no: 'छैन',
        disabilityType: 'अपाङ्‍गताको प्रकार',
        disabilityTypePlaceholder: 'अपाङ्‍गताको प्रकार चयन गर्नुहोस्',
        Physicy_Disability: 'शारीरिक अपाङ्‍गता',
        Visual_Disability: 'दृष्टि सम्बन्धी अपाङ्‍गता',
        Hearing_Disability: 'सुनाई सम्बन्धी अपाङ्‍गता',
        Speech_Related: 'स्वरबोलाइ सम्बन्धी',
        Disability: ' मनाेसामाजिक अपाङ्गता',
        Deafblindness: 'श्रवणदृष्टि विहिन अपाङ्गता',
        Intellectual_Disability: 'बौद्धिक अपाङ्‍गता',
        Autism: 'आटिजम',
        Hemophilia: 'अनुवंशीय रक्तश्राव (हेमेफेलिया)',
        Multiple_Disabilities: 'बहु अपाङ्‍गता',
        OTHERS: 'अन्य',
        uploadPhoto: 'तस्बिर अपलोड गर्नुहोस्',
        parentsDetails: 'अभिभावक चयन गर्नुहोस्',
        therapistDetails: 'थेरापिस्ट चयन गर्नुहोस्',
        schoolDetails: 'विद्यालय चयन गर्नुहोस्',
        address: 'ठेगाना',
        province: 'प्रदेश',
        district: 'जिल्ला',
        locallevel: 'स्थानीय तह',
        localAddress: 'स्थानीय ठेगाना',
	tempProvince: 'प्रदेश',
        tempDistrict: 'जिल्ला',
        tempLocalLevel: 'स्थानीय तह',
        tempLocalAddress: 'स्थानीय ठेगाना',
        serviceArea: 'सेवा क्षेत्र',
        school: 'विद्यालय',
        home: 'घर',
        community: 'समुदाय',
        cancel: 'रद्द गर्नुहोस्',
        save: 'सेभ गर्नुहोस्',
        sid: 'विद्यार्थी आईईएमआईएस आईडी',
        roll: 'रोल नम्बर',
        section: 'सेक्सन',
        level: 'कक्षा',
        NEPALI: 'नेपाली',
        Maithili: 'मैथिली',
        Bhojpuri:'भोजपुरी',
        'Tharu':'थारू',
        'Tamang':'तामाङ',
        'Nepal Bhasa':'नेपालभाषा( नेवारी)',
        Magar:'मगर',
        Doteli:'डोटेली',
        Limbu:'लिम्बु',
        Gurung:' गुरूङ',
        Rai:'राई',
        Chepang:' चेपाङ',
        category: 'कुनै टिप्पणी?',
        specialDisability: 'कार्यात्मक सिमितताहरू',
        contact: 'सम्पर्क',
        disabilityDetail: 'अपाङ्‍गताका थप विवरणहरू',
        IEMISNumber: 'आईईएमआईएस नम्बर',
        disabilityId: 'अपाङ्‍गता परिचयपत्र',
        'Profound Disability (Red Color ID)': 'पुर्ण अपाङ्‍गता (रातो रंगको आईडी)',
        'Severe Disability (Blue Color ID)': 'अति अशक्त अपाङ्‍गता (नीलो रंगको आईडी)',
        'Moderate Disability (Yellow Color ID)': 'मध्यम अपाङ्‍गता (पहेंलो रंगको आईडी)',
        'Mild Disability (White Color ID)': 'सामान्य अपाङ्‍गता (सेतो रंगको आईडी)',
        'NO ID':'परिचय पत्र छैन'
    },
     transfer: {
        heading: 'विद्यार्थी स्थानान्तरण',
        role: 'विद्यार्थी स्थानान्तरण भूमिका:',
        user: 'स्थानान्तरण गर्ने',
        transferStudent: 'विद्यार्थी स्थानान्तरण गर्नुहोस्',
        close: 'बन्द गर्नुहोस्'
    }
}
