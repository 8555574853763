import {
    Button,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter, AlertDialogHeader,
    AlertDialogOverlay,
    useDisclosure,
    AlertDialog, CircularProgress
} from "@chakra-ui/react";
import React from "react";
import {useTranslation} from "react-i18next";

interface CoustomAlertDialogProps{
    isOpen:boolean,
    loading:boolean,
    onOpen:()=>void,
    onClose:()=>void,
    onMainAction:()=>{}
    warning?:boolean

}

export default function CoustomAlertDialog({isOpen,loading,onOpen,onClose,onMainAction,warning}:CoustomAlertDialogProps) {
    const {t} = useTranslation('common');
    const cancelRef:React.MutableRefObject<any> = React.useRef()

    return (
        <>
            <AlertDialog
                isCentered
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>


                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {warning?t('warningTitle'):t('alertTitleDelete')}
                            </AlertDialogHeader>

                            <AlertDialogBody>
                                {warning?t('warningSubTitle'):t('aletrSubTitle')}

                            </AlertDialogBody>

                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose} isDisabled={loading}>
                                       {t('cancel')}
                                </Button>
                                <Button colorScheme='red' onClick={onMainAction} ml={3}  isLoading={loading} isDisabled={loading}>
                                    { warning ?  t('completeIEP'):t('delete')}

                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>

                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );

}
