export default {
    appName: 'वैयक्तिक शैक्षिक योजना व्यवस्थापन प्रणालीमा',
    loginText: 'वैयक्तिक शैक्षिक योजना व्यवस्थापन प्रणालीमा स्वागत छ',
    password: 'पासवर्ड',
    userName: 'फोन नम्बर',
    loginBtn: 'लग - इन',
    hide: 'लुकाउनुहोस्',
    show: 'देखाउनुहोस्',
    enterUserNmae: 'प्रयोगकर्ता नाम प्रविष्ट गर्नुहोस्',
    enterPassword: 'पासवर्ड प्रविष्ट गर्नुहोस्'
}
