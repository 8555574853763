import { ValidationErrorItem } from 'joi';
export type FormattedError = {
    label: string | undefined;
    message: string;
    type?: string;
};

export const errorFormat = (errors: ValidationErrorItem[] | null): FormattedError[] => {
    if (!errors) return [];
    return errors.map((err: ValidationErrorItem) => {
        return { label: err.context?.label, message: err.message };
    });
};

export const hasErrorMessage = (formErrors: FormattedError[], fieldName: string): boolean => {
    return !!formErrors.find((err) => err?.label?.split('[')[0] === fieldName);
};

export const getErrorMessage = (formErrors: FormattedError[], fieldName: string): string | undefined => {
    return formErrors.find((err) => err?.label?.split('[')[0] === fieldName)?.message;
};