import {Avatar, Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import i18n from "../locales/i18n";
import {MenuList} from "../utils/menuItem";
import {FaLanguage} from "react-icons/fa";
import {BiLogOutCircle} from "react-icons/bi";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getMyProfile} from "../http/users";
import _intersection from "lodash/intersection";
export default function MenuComponent() {
    const { t } = useTranslation('common');
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [lang,setLang]=useState<string>('en');
    const [user,setUser]=useState<any>(undefined);
    i18n.on('languageChanged', (locale) => {
        setLang(locale);
    });

    const onGetGreeting=()=>{
        const user=localStorage.getItem('user');
    if(user) {
        const currentHour = new Date().getHours();
        let greeting: string;

        if (currentHour >= 5 && currentHour < 12) {
            greeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = 'Good afternoon';
        } else {
            greeting = 'Good evening';
        }
        // @ts-ignore
        return greeting;
    }
    }
    useEffect(() => {
        onGetMyProfile();
    }, [true]);

    const onGetMyProfile=async () => {
        try {
            const response = await getMyProfile();
            setUser(response.data);
        } catch (e) {
            console.log(e)
        }
    }



    return <Box mt={"20px"} p={"12px"}>
        <Text fontSize="24px" fontWeight={900} textAlign={"center"} color={"#ffffff"}>
            {t('mainName')}
        </Text>
        <Box mt={"20px"}>
            {user && (
            <Flex flex='1' gap='4' alignItems='center'
                  cursor={'pointer'}
                  background='#ffffff' mb={'20px'} p={'10px'} borderRadius={'8px'} onClick={()=>navigate("/user/"+user.id,{state:user})}>
                <Box w={'20%'}>
                <Avatar name={user.displayName} src={user.image} w={'100%'}/>
                </Box>

                <Box w={'80%'}>
                    <Heading size='sm'>{onGetGreeting()}, {user.displayName}</Heading>
                    <Text>{user.address}</Text>
                </Box>
            </Flex>
            )}
            {MenuList.map((menu) =>
                // @ts-ignore
                user && (_intersection(user?.roles, menu.accessTo).length > 0)  && (
                <Button leftIcon={<menu.icon/>} background={pathname === menu.path ? "#ffffff" : "#1b73e8"}
                        onClick={() => navigate(menu.path)}
                        color={pathname !== menu.path ? "#ffffff" : "#1b73e8"}
                        _hover={pathname === menu.path ? {} : {
                            background: pathname !== menu.path ? "#ffffff" : "#1b73e8",
                            color: pathname === menu.path ? "#ffffff" : "#1b73e8"
                        }}

                        variant='solid' w={"100%"} borderRadius={"20px"} mb={"18px"}>
                    {t(`${menu.label}`)}
                </Button>
                    )
            )}
            <Button leftIcon={<FaLanguage/>} background={ "#1b73e8"}
                    onClick={() =>
                        i18n
                            .changeLanguage(i18n.language === 'ne' ? 'en' : 'ne')
                            .then(() => {})
                            .catch((error) => {
                              console.log(error);
                            })
            }
                    color={"#ffffff"}
                    _hover={{
                        background:  "#ffffff",
                        color:"#1b73e8"
                    }}

                    variant='solid' w={"100%"} borderRadius={"20px"} mb={"18px"}>
                {lang === 'ne'?'Change Language':'भाषा परिवर्तन'}
            </Button>
            <Button leftIcon={<BiLogOutCircle/>} background={ "#1b73e8"}
                    onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                    }
                    }
                    color={"#ffffff"}
                    _hover={{
                        background:  "#ffffff",
                        color:"#1b73e8"
                    }}

                    variant='solid' w={"100%"} borderRadius={"20px"} mb={"18px"}>
                {t('logout')}
            </Button>
        </Box>
    </Box>
}
