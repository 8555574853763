
export enum Roles{
    TEACHER='TEACHER', PARENT='PARENT', ADMIN='ADMIN', MODERATOR='MODERATOR', THERAPIST='THERAPIST',REPORT_ACCESS='REPORT_ACCESS'
}
export interface User {
    id?: string;
    displayName: string;
    userName?: string;
    image?: string;
    description: string;
    gender: string;
    address: string;
    phone: string;
    roles?: Roles[] ;
    age: number;
    blocked?: boolean;
    credencials?: Credencials ;
    createByUserId?: string;
}

export interface Credencials {
    username: string;
    password: string;
}
