import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Select,
    Text, useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {AiOutlineUpload} from "react-icons/ai";
import {Roles, User} from "../../interfaes/user";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Joi from "joi";
import {errorFormat, FormattedError, getErrorMessage, hasErrorMessage} from "../../utils/formUtils";
import {DEFAULTUSER} from "../../interfaes/fixture";
import {onAddNewUser, onUpdateUser} from "../../http/users";
import ImageUpload from "../../components/ImageUpload";
import {isTeacher, isTeacherOnly} from "../../utils/common";
import {StrengthAndLimitation} from "../../interfaes/iep";

type ManageUsersPropsType = {
    shouldReturn:boolean;
    role:string;
    returnUserID:(id:string)=> void;
};

export default function ManageUsers({shouldReturn,returnUserID,role}:ManageUsersPropsType) {
    const { state } = useLocation();

    const [loading,setLoading]=useState<boolean>(false);

    const { id } = useParams();
    const schema = Joi.object({
        displayName: Joi.string()
            .required(),

        address: Joi.string().required(),
        image:Joi.string().allow('').optional(),
        gender:Joi.string().required(),
        phone:Joi.string().regex(/^\d{10}$/) // Ensure it contains exactly 10 digits
            .message('Phone number must be exactly 10 digits').required(),
        age:Joi.number().optional(),
        description:Joi.string().allow('').optional(),
        roles:id === 'add' || shouldReturn?Joi.array().min(1).optional():Joi.optional().allow(null),
        credencials:id === 'add' || shouldReturn?Joi.object({
            username: Joi.number()
                .required(),
            password:Joi.string().required()
        }):Joi.optional().allow(null),
    });
    const toast = useToast();

    const [userObj,setUserObj]=useState<User>(
        state?{
            "displayName": state.displayName,
            "image": state.image?state.image:undefined,
            "description": "-",
            "gender": state.gender,
            "address": state.address,
            "phone": state.phone,
            "roles": id === 'add'?state.roles:
            undefined,
            "age": 22,
            "credencials":id === 'add' || shouldReturn ? {
                "username": state.userName,
                "password": ""
            }:undefined,
        }:DEFAULTUSER
    );
    const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
    const {t} = useTranslation('users');
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show);
    const navigate = useNavigate();
    const validateObj=()=>{
        const { error } = schema.validate(userObj);
        if (error) {
            setFormErrors(errorFormat(error.details));
            return false;
        }

        setFormErrors([]);
        return true;
    }
    const handleUpdateUser=async () => {
        if (validateObj()) {
            try {
                if(id) {
                    setLoading(true);
                    if (id === 'add' || shouldReturn) {
                        const response=await onAddNewUser(userObj);
                        if(shouldReturn){
                            returnUserID(response.data.id)
                        }else {
                            navigate(-1)
                        }
                        toast({
                            title: 'Added',
                            description: "User Added Successfully!",
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        })
                    } else {
                        await onUpdateUser(userObj, id);
                        navigate(-1)
                    }
                }

            } catch (e) {


                toast({
                    title: 'Error',
                    // @ts-ignore
                    description: e?.response?.data?.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        if(shouldReturn){
            let r:Roles=role as Roles;
            setUserObj({...userObj, roles: [r]})
        }
    }, [shouldReturn]);
    const callback = (url:string) => {
        let newObj={...userObj};
        newObj.image=url;
        setUserObj({...newObj});
    };
    return (
        <Box>
            <Text fontSize={"24px"} fontWeight={900}>
                {t('title')}
            </Text>
            <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
                <Flex>
                   <Box w={"20%"}>
                       <ImageUpload onSetImage={callback} imageUrl={userObj.image}/>
                   </Box>
                    <Box w={"80%"} ml={'14px'}>

                        <Flex>
                            <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'displayName')}>
                                <FormLabel>  {t('displayName')}</FormLabel>
                                <Input
                                    onChange={(e)=> setUserObj({...userObj,displayName:e.target.value || ''})}
                                    value={userObj.displayName}
                                    variant={"filled"} placeholder={t('displayName')} required/>
                                <FormErrorMessage>{getErrorMessage(formErrors, 'displayName')}</FormErrorMessage>
                            </FormControl>
                            <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'address')}>
                                <FormLabel>  {t('address')}</FormLabel>
                                <Input
                                    onChange={(e)=> setUserObj({...userObj,address:e.target.value || ''})}
                                    value={userObj.address}
                                    variant={"filled"} placeholder={t('address')} required/>
                                <FormErrorMessage>{getErrorMessage(formErrors, 'address')}</FormErrorMessage>
                            </FormControl>
                            <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'gender')}>
                                <FormLabel>{t('gender')}</FormLabel>
                                <Select placeholder={t('gender')}
                                        onChange={(e)=> setUserObj({...userObj,gender:e.target.value || ''})}
                                        value={userObj.gender}
                                        background={'var(--chakra-colors-gray-100)'}>
                                    <option value='M'>{t('male')}</option>
                                    <option value='F'>{t('female')}</option>
                                    <option value='U'>{t('others')}</option>
                                </Select>
                                <FormErrorMessage>{getErrorMessage(formErrors, 'gender')}</FormErrorMessage>
                            </FormControl>
                        </Flex>
                        {id === 'add' && !shouldReturn && (
                        <Box mt={'12px'}>
                            <Text fontSize={"16px"} fontWeight={500} mb={"6px"}>
                                {t('selectRole')}
                            </Text>

                            <Box background={'var(--chakra-colors-gray-100)'} borderRadius={'6px'} padding={'8px'} border={hasErrorMessage(formErrors, 'role')?'1px solid red':'none'}>

                                {
                                    isTeacherOnly()?
                                        ["THERAPIST","PARENT"].map(key =>
                                    <Checkbox colorScheme='red'  mr={'16px'} value={key}

                                              isChecked={userObj.roles?userObj.roles.includes(key as Roles):false}
                                              onChange={()=>{
                                                  if(userObj.roles) {
                                                      let selectedRole = key as Roles;
                                                      let updatedRoles = userObj.roles;
                                                      if (userObj?.roles.includes(selectedRole)) {
                                                          updatedRoles = updatedRoles.filter(item => item !== selectedRole);

                                                      } else {
                                                          updatedRoles.push(selectedRole);

                                                      }
                                                      setUserObj({...userObj, roles: updatedRoles})
                                                  }

                                    }}>
                                        {t(`${key}`)}
                                    </Checkbox>
                                ):
                                        Object.keys(Roles).map(key =>
                                            <Checkbox colorScheme='red'  mr={'16px'} value={key}

                                                      isChecked={userObj.roles?userObj.roles.includes(key as Roles):false}
                                                      onChange={()=>{
                                                          if(userObj.roles) {
                                                              let selectedRole = key as Roles;
                                                              let updatedRoles = userObj.roles;
                                                              if (userObj?.roles.includes(selectedRole)) {
                                                                  updatedRoles = updatedRoles.filter(item => item !== selectedRole);

                                                              } else {
                                                                  updatedRoles.push(selectedRole);

                                                              }
                                                              setUserObj({...userObj, roles: updatedRoles})
                                                          }

                                                      }}>
                                                {t(`${key}`)}
                                            </Checkbox>
                                        )

                                }


                            </Box>
                            <Text color={"red"}>{getErrorMessage(formErrors, 'role')}</Text>
                        </Box>
                        )}
                        {(id === 'add' || shouldReturn) && (
                        <Flex mt={'12px'}>
                            <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'phone')}>
                                <FormLabel>  {t('phone')}</FormLabel>
                                <Input
                                    onChange={(e)=>

                                    {
                                        if(userObj.credencials){
                                        setUserObj({...userObj,phone:e.target.value || '',credencials:{
                                        ...userObj.credencials,username:e.target.value || ''
                                        }})
                                    }
                                    }}
                                    value={userObj.phone}
                                    variant={"filled"} placeholder={t('phone')} required type={"number"}/>
                                <FormErrorMessage>{getErrorMessage(formErrors, 'phone')}</FormErrorMessage>
                            </FormControl>
                            <FormControl mr={"12px"} isInvalid={hasErrorMessage(formErrors, 'credencials.password')}>
                                <FormLabel>  {t('password')}</FormLabel>
                                <InputGroup size='md'>
                                <Input
                                    onChange={(e)=> {
                                        if(userObj.credencials) {
                                            setUserObj({
                                                ...userObj,
                                                credencials: {...userObj.credencials, password: e.target.value || ''}
                                            })
                                        }
                                    }
                                    }
                                    value={userObj?.credencials?userObj.credencials.password :''}
                                    variant={"filled"} placeholder={t('password')} required  type={show ? 'text' : 'password'}/>
                                    <InputRightElement width='4.5rem'>
                                        <Button h='1.75rem' size='sm' onClick={handleClick}>
                                            {show ? t('login:hide') : t('login:show')}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                {/*<FormHelperText>We'll never share your email.</FormHelperText>*/}
                            </FormControl>
                        </Flex>
                            )}
                    </Box>
                </Flex>

                <Flex mt={'16px'} justifyContent={'flex-end'}>

                    <Button colorScheme='gray' size='lg'  mr={'12px'} onClick={() => {
                        if (shouldReturn) {
                            returnUserID('');
                        } else {

                            navigate(-1)
                        }
                    }}  isDisabled={loading}>
                        {t('cancel')}
                    </Button>

                    <Button colorScheme='blue' size='lg' onClick={handleUpdateUser}  isLoading={loading} isDisabled={loading}>
                        {t('save')}
                    </Button>
                </Flex>
            </Box>
        </Box>
    )
}
