import {
    Avatar,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Text,
    useDisclosure, useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {AiOutlineUserAdd} from "react-icons/ai";
import {RelationShip, Student} from "../../interfaes/student";
import MaterialTable, {Column} from "material-table";
import {createTheme, ThemeProvider} from '@mui/material';
import {GrEdit} from "react-icons/gr";
import {MdDelete} from "react-icons/md";
import {CgArrowsExchange} from "react-icons/cg";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {onChangeRole, onDeleteStudent, onGetMyStudents, onGetStudents} from "../../http/student";
import {Roles, User} from "../../interfaes/user";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {School} from "../../interfaes/school";
import {onGetUsers} from "../../http/users";
import {canAddStudent, canTransferStudent, isTeacher} from "../../utils/common";


export default function StudentsPage() {
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const toast = useToast();

    const {t} = useTranslation('studentLocal');
    const [data, setData] = useState([]);
    const [relationShip, setRelationShip] = useState<RelationShip[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [deleteIndex, setDeleteIndex] = useState("");
    const [selectedRole, setSelectedRole] = useState(Roles.TEACHER);
    const [relationshipId, setRelationshipId] = useState("");
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen: isOpenTransfer, onOpen: onOpenTransfer, onClose: onCloseTransfer} = useDisclosure();
    const {pathname} = location;


    const calculateAgeFromTimestamp = (timestamp: number): number => {
        // Convert the timestamp to a Date object
        const birthDate = new Date(timestamp);
        // Multiply by 1000 to convert from seconds to milliseconds

        // Get the current date
        const currentDate = new Date();

        // Calculate the age
        const age = currentDate.getFullYear() - birthDate.getFullYear();

        // Check if the birthdate has occurred this year
        // If the birthdate hasn't occurred yet, subtract 1 from the age
        if (
            currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() &&
                currentDate.getDate() < birthDate.getDate())
        ) {
            return age - 1;
        }

        return age;
    }


    const columns: Column<Student>[] = [
        {title: t('id'), field: 'id', type: 'numeric', hidden: true},
        {
            title: t('name'), field: 'name',
            render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}
                     onClick={() => navigate("/students-profile/" + rowData.studentId, {state: rowData})}>

                        <Avatar name={rowData.fullName} src={rowData.studentImage} mr={'12px'} w={'40px'} h={'40px'} background={'#e2e8f0'}/>

                    {rowData.fullName}
                </div>
            ),
            customFilterAndSearch: (term, rowData) => (rowData.fullName).indexOf(term) !== -1
        },
        {
            title: t('manageStudents.age'), field: 'dob',
            render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {calculateAgeFromTimestamp(rowData.dob)}
                </div>
            )
        },
        {
            title: t('teacherName'), field: 'teacherName',
            render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {rowData?.relationships?.filter((r) => r.relation === Roles.TEACHER) ? rowData?.relationships?.filter((r) => r.relation === Roles.TEACHER)[0]?.relatedUser.displayName : '-'}
                </div>
            )
        },
        {
            title: t('parentsName'), field: 'parentsName',
            render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {rowData?.relationships?.filter((r) => r.relation === Roles.TEACHER) ? rowData?.relationships?.filter((r) => r.relation === Roles.PARENT)[0]?.relatedUser.displayName : '-'}
                </div>
            )
        },
        {
            title: t('manageStudents.address'), field: 'address',
            render: rowData => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {`${rowData?.localAddress}, ${rowData?.district}, ${rowData?.province}`}
                </div>
            ),
            customFilterAndSearch: (term, rowData) => (`${rowData?.localAddress}, ${rowData?.district}, ${rowData?.province}`).indexOf(term) !== -1

        },
    ];
    const defaultMaterialTheme = createTheme();

    const navigate = useNavigate();


    const getStudents = async () => {
        try {
            if (location.pathname === '/my_students') {
                const response = await onGetMyStudents();
                setData(response.data)
            } else {
                const response = await onGetStudents();
                setData(response.data.items)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getStudents();
        getUsers();
    }, [location.pathname])


    const getUsers = async () => {
        try {
            const response = await onGetUsers();
            setUsers(response.data.userResponseList)
        } catch (e) {
            console.log(e);
        }
    }


    const handleClose = () => {
        setDeleteIndex('');
        setLoading(false)
        onClose();

    }

    const onDeleteStudents = async () => {
        try {
            setLoading(true)
            await onDeleteStudent(deleteIndex);
            const arrayOfObjects = data.filter((obj: School) => obj.id !== deleteIndex);
            setData(arrayOfObjects);
            getStudents();
            toast({
                title: 'Deleted',
                description: "Student Deleted Successfully!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

        } catch (e) {
            toast({
                title: 'Error',
                // @ts-ignore
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            handleClose();
        }


    }
    const onTransferStudent = async () => {
        try {
            setLoading(true)
            const rID = relationShip.filter((r) => r.relation === selectedRole)[0].relationshipId;
            await onChangeRole(deleteIndex, rID, relationshipId, selectedRole);
            const arrayOfObjects = data.filter((obj: School) => obj.id !== deleteIndex);
            setData(arrayOfObjects);
            getStudents();
            onCloseTransfer();
            toast({
                title: 'Transferred',
                description: "Student Transferred Successfully!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

        } catch (e) {
            toast({
                title: 'Error',
                // @ts-ignore
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            handleClose();
        }


    }


    const onGetIcon = (id: string) => {
        if (id === 'edit') {
            return <GrEdit size="20px" color={"red"}/>;

        } else if (id === 'transfer') {
            return <CgArrowsExchange size="20px" color={"orange"}/>;
        } else {

            return <MdDelete size="20px" color={"red"}/>
        }


    }


    return <Box>
        <Flex justifyContent="space-between">
            <Text fontSize="30px" fontWeight={900}>
                {t('students')}
            </Text>
            {canAddStudent() && pathname === '/my_students' && (
                <Button leftIcon={<AiOutlineUserAdd/>} background={"#1b73e8"}
                        color={"#ffffff"}
                        _hover={{background: "#1d5094", color: "#ffffff"}}
                        variant='solid' borderRadius={"20px"} mb={"18px"}
                        onClick={() => navigate("/students/add")}
                >
                    {t('addStudents')}
                </Button>
            )}
        </Flex>

        <Box mt={"20px"}>
            <ThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                    title={t('studentList')}
                    columns={columns}
                    data={data}
                    // onRowClick={(e,rowData)=>{
                    //    if(rowData) {
                    //
                    //    }
                    // }}
                    options={{
                        search: true,
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: 50
                    }}
                    actions={[
                        canAddStudent() && pathname === '/my_students' && {
                            icon: 'edit',
                            tooltip: 'Edit Student',
                            onClick: (event, rowData) => {
                                navigate("/students/" + event.studentId, {state: event})
                            }
                        },
                        isTeacher()  && {
                            icon: 'delete',
                            tooltip: 'Delete Student',
                            onClick: (event, rowData) => {
                                setDeleteIndex(event.studentId);
                                onOpen();
                            }
                        },
                        canTransferStudent() &&  {
                            icon: 'transfer',
                            tooltip: 'Trasnfer Student',
                            onClick: (event, rowData) => {
                                setDeleteIndex(event.studentId);
                                setRelationShip(event.relationships)
                                onOpenTransfer();
                            }
                        }
                    ]}
                    components={{
                        Action: props => (
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <IconButton
                                    background={'#eeeeee'}
                                    p={"10px"}
                                    borderRadius={"10px"}
                                    aria-label='manage students'
                                    mr={"12px"}
                                    onClick={() => props.action.onClick(props.data)}
                                    icon={onGetIcon(props.action.icon)}
                                />
                            </div>
                        ),
                    }}
                    //  onRowClick={(event, rowData) => handleRowClick(event, rowData)}
                />
            </ThemeProvider>
            <CoustomAlertDialog
                onClose={handleClose}
                onOpen={onOpen}
                isOpen={isOpen}
                onMainAction={onDeleteStudents}
                loading={loading}
            />

            <Modal isOpen={isOpenTransfer} onClose={onCloseTransfer}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>{t('transfer.heading')}</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <FormControl mr={"12px"}>
                            <FormLabel>{t('transfer.role')}</FormLabel>
                            <Select placeholder={t('transfer.role')}
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value as Roles)}
                                    background={'var(--chakra-colors-gray-100)'}>
                                {[Roles.TEACHER, Roles.THERAPIST].map((a) =>
                                    <option value={a}>{a}</option>
                                )}

                            </Select>
                            {/*<FormErrorMessage>{getErrorMessage(formErrors, 'localLevel')}</FormErrorMessage>*/}
                        </FormControl>
                        <FormControl mr={"12px"}>
                            <FormLabel>{t('transfer.user')}</FormLabel>
                            <Select placeholder={t('transfer.user')}
                                    value={relationshipId}
                                    onChange={(e) => setRelationshipId(e.target.value)}

                                    background={'var(--chakra-colors-gray-100)'}>
                                {users.filter((r) => r.roles?.includes(selectedRole)).map((a) =>
                                    <option value={a.id}>{a.displayName}</option>
                                )}

                            </Select>
                            {/*<FormErrorMessage>{getErrorMessage(formErrors, 'localLevel')}</FormErrorMessage>*/}
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3} onClick={onCloseTransfer}>
                            {t('transfer.close')}
                        </Button>
                        <Button variant='ghost' onClick={onTransferStudent}> {t('transfer.transferStudent')}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    </Box>
}
