import {IEP, QuestionBody, UserAnswer} from "../interfaes/iep";
import axios from "axios";

export function addIEP(iep: IEP) {
    return axios.post('/iep', iep);
}

export function updateIEP(iep: IEP, id: string) {
    return axios.put('/iep/' + id, iep);
}

export function onGetIEP(studentId: string) {
    return axios.get('/iep/student/' + studentId)
}

export function onGetSingleIEP(studentId: string, iepID: string) {
    return axios.get('/iep/student/' + studentId + '/' + iepID)
}

export function onGetHistory(iepID: string,shiftedObjectiveId:string) {
    return axios.get(`/answers/shiftedObjectivesByParentId/${shiftedObjectiveId}`)
}

export function onSaveAnswers(ans: UserAnswer) {
    return axios.post('/answers', ans);
}

export function completeIEPStep(iepId: string,
                                step: number,complete:boolean) {
    return axios.put('/iep/update-step-complete', {
        "iepId": iepId,
        "step": step,
        "completed": complete
    });
}

export function completeIEP(iepId: string) {
    return axios.put('/iep/update-complete', {
        "iepId": iepId,
        "completed": true
    });
}
export function addNewGoal(iepID:string){
    return axios.post('/questions/goals', {
        "iepId": iepID,
        "questionType": "TEXT",
        "position": 0
    });
}

export function addNewObjectives(iepID:string,goalQuestionId:string,quarter:number){
    return axios.post('/questions/objectives', {

        "iepId": iepID,
        "goalQuestionId": goalQuestionId,
        "quarter": quarter,
        "questionType": "TEXT",
        "position": 0

    });
}
export function addBulkAnswers(answers:[]){
    return axios.post('/answers/bulk', answers);
}
export function addNewCheckList(obj:QuestionBody){
    return axios.post('/questions', obj);
}
export  async function onDeleteIEP(siD:string,id:String) {
    return await axios.delete(
        `iep/student/${siD}/${id}`
    );
}

export function deleteQuestion(queID:string){
    return axios.delete('questions/'+queID);
}export function deleteQuestionObjectives(queID:string){

    return axios.delete('questions/objective/'+queID);
}
export function deleteQuestionGoal(queID:string){
    return axios.delete('questions/goal/'+queID);
}

export function updateData(obj:any){
    return axios.put('/questions/update-shifted-objectives', obj);
}

export function addDailyLog(iepId:string,remarks:string,date:string,time:string){
    return axios.post('/dailyLog',{
        "date": date,
        "remarks": remarks,
        "iepId": iepId,
        workHours:parseInt(time)
    })
}

export function updateDailyLog(iepId:string,remarks:string,logID:string,date:string,time:string){
    return axios.put('/dailyLog/'+logID,{
        "date": date,
        "remarks": remarks,
        "iepId": iepId,
        workHours:parseInt(time)
    })
}

export function onGetLogs(logID:string){
    return axios.get('/dailyLog/my/'+logID);
}

export function deleteLog(logID:string){
    return axios.delete('/dailyLog/'+logID);
}
