export default {
    mainName:'वैयक्तिक शैक्षिक योजना व्यस्थापन प्रणाली',
    Student:'सबै विद्यार्थीहरू',
    Dashboard:'ड्यासबोर्ड',
    My_Students:'मेरा विद्यार्थीहरू',
    School:'विद्यालय',
    User:'प्रयोगकर्ता',
    alertTitleDelete:'हटाउने?',
    aletrSubTitle:' के तपाईँ पक्का हुनुहुन्छ? यो कार्य फेरी सच्चाउन सकिने छैन !',
    delete: 'हटाउनुहोस',
    cancel:'रद्द गर्नुहोस्',
    uploadPhoto: 'तस्बिर अपलोड',
    warningSubTitle:'यो कार्यलाई फेरी सच्चाउन सकिने छैन !!!',
    warningTitle:'सावधान',
    completeIEP:'आईईपी निर्माण प्रकिया पूरा गर्नुहोस्',
    logout:'लग आउट',
    plans:'योजनाहरू',
    'Student Progress':'विद्यार्थी प्रगति'
}
