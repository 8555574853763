export default {
    mainName:'IEP Management System ',
    Student:'All Students',
    Dashboard:'Dashboard',
    My_Students:'My Student',
    School:'Schools',
    User:'Users',
    alertTitleDelete:'Delete?',
    aletrSubTitle:'  Are you sure? You can\'t undo this action afterwards.',
    delete:'Delete',
    cancel:'Cancel',
    uploadPhoto: 'Upload Photo',
    warningSubTitle:'You cannot redo this action!!!',
    warningTitle:'Alert',
    completeIEP:'Complete',
    logout:'Logout',
    plans:'Plans',
    'Student Progress':'Student Progress'
}
