import {
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Divider,
    Flex,
    FormControl,
    FormLabel, Input,
    Text, Textarea, useDisclosure, useToast
} from "@chakra-ui/react";
import {Checklist, CheckListArea, StrengthAndLimitation, UserAnswer} from "../../interfaes/iep";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {addNewCheckList, completeIEPStep, deleteQuestion, onSaveAnswers} from "../../http/iepAPis";
import _findIndex from 'lodash/findIndex';
import {useNavigate} from "react-router-dom";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {onDeleteStudent} from "../../http/student";
import {School} from "../../interfaes/school";
import _groupBy from "lodash/groupBy";
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;
import {canAddStudent} from "../../utils/common";
import _find from "lodash/find";
import {MdDelete} from "react-icons/md";



type CheckListAreasPropsType = {
    checkListArea:CheckListArea[]
    checkListAreaOld:CheckListArea[]
    iepId:string
    title:string
    subTitle:string
    onGetData:()=>void;
    step:number;
    isCompleted:boolean | undefined;
};
export default function CheckListAreas({checkListArea,checkListAreaOld,iepId,title,subTitle,onGetData,step,isCompleted}:CheckListAreasPropsType){
    const {t} = useTranslation('iep');
    const [checklist,setChecklist]=useState<Checklist[]>(checkListArea?checkListArea[0].checklist:[]);
    const [selectedIndex,setSelectedIndex]=useState<string>(checkListArea[0].section);
    const [group,setGroup]=useState<string>(checkListArea[0].group);
    const [questionID,setQuestionID]=useState<string>('');
    const navigate = useNavigate();
    const [checkBoxAnswers,setCheckBoxAnswers]=useState(new Map());
    const [remarks,setRemarks]=useState(new Map());
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [loading, setLoading] = useState(false);
    const [newCheckList, setNewCheckList] = useState('');



    const handleSave=async (userAnswers:UserAnswer,qId:string) => {



            try {
                await onSaveAnswers(userAnswers);
                const userIndex = _findIndex(checklist, { 'questionId': qId });
                let updatedCheckList=checklist;
                if (userIndex !== -1) {
                    updatedCheckList[userIndex].withAnswer = true;
                }
                setChecklist([...updatedCheckList]);
                setTimeout(()=>{
                    onGetData();
                },5000)

            } catch (e) {
                console.log(e)
            }finally {
                setQuestionID('');
            }





    }
    useEffect(()=>{
        checklist.forEach((e)=>{
            setCheckBoxAnswers((prevMap) => {
                const newMap = new Map(prevMap);
                if(e.answer) {
                    newMap.set(e.questionId, e.answer.content)
                }

                return newMap;

            });
            setRemarks((prevMap) => {
                const newMap = new Map(prevMap);
                if(e.answer) {
                newMap.set(e.questionId,e.answer.remarks)
                }
                return newMap;

            });
        })
    },[checklist])

    const toast = useToast()


    const handleClose = () => {

        onClose();
        setqId(undefined);


    }

    const onCompleteIEP = async () => {
        try {
            setLoading(true)
            if(qId){
                await deleteQuestion(qId?qId:'');
                toast({
                    title: 'CheckList Removed',
                    description: "CheckList removed Successfully!!!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                setqId(undefined);
                setLoading(false);
                onClose();
            }else {
                await completeIEPStep(iepId, step, !isCompleted);
                setTimeout(() => {
                    onGetData();
                }, 5000)
                toast({
                    title: 'CheckList Completed',
                    description: "CheckList completed Successfully!!!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                onClose();
            }
        } catch (e) {
            toast({
                title: 'CheckList Failed',
                description: "CheckList could not be completed!!!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            console.log(e)
        } finally {
            setLoading(false);
            if(!qId) navigate(-1);
        }


    }
    const onAddNewQuestions=async () => {

        const obj = {
            "iepId": iepId,
            "group": group,
            "section": selectedIndex,
            "question": newCheckList,
            "questionNP": newCheckList,
            "questionType": "YES_NO",
            "options": [
                "आफै गर्न सक्छ",

                "कठिनाइ छ",

                "उस्लाई मिल्दैन"
            ],
            "position": 0,
            "iepStep": 2,
        };
        try {
            setLoading(true)
            await addNewCheckList(obj);

                onGetData();

            setNewCheckList('');

            toast({
                title: 'Checklist Added',
                description: "CheckList Added Successfully!!!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        } catch (e) {
            toast({
                title: 'CheckList Add Failed',
                description: "CheckList could not be added!!!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
            console.log(e)
        } finally {
            setLoading(false);
        }

    }

    const getStepTwoAnswer=(qId:String)=>{
        const ans=_find(_find(checkListAreaOld, { 'checklist': [{ 'questionId': 'Q-S2-00001' }] })?.checklist,['questionId', qId.replace('-F', '')]);
        if(ans?.answer?.content==='Can') {
            return 'आफै गर्न सक्छ';
        }
        if(ans?.answer?.content==='Cannot') {
            return 'कठिनाइ छ';
        }
        if(ans?.answer?.content==='Not possible') {
            return 'उस्लाई मिल्दैन';
        }

    }
    const [qId,setqId]=useState<string | undefined>(undefined);

    return (
        <Box mt={'12px'}>
            <Text fontWeight={'900'} fontSize={'16px'}>{title} : {subTitle}</Text>
            <Box>
                {
                    Object.entries(_groupBy(checkListArea,'group')).map(([key, value]) => (
                        <Box >
                            <Text  fontWeight={'900'} fontSize={'18px'} color={'blue'} mt={"12px"}>{key}</Text>
                            <Box display='grid'
                                 mt={'12px'}
                                 gridTemplateColumns={'24% 24% 24% 24%'}
                                 gridGap='14px'>
                            {value.map((c)=>


                            <Card border='1px solid'  cursor={'pointer'}
                                  background={selectedIndex===c.section?'blue.200':'white'}
                                  _hover={{background:'#f5f1f1'}} p={'8px'}    borderStyle='dashed' onClick={()=>{
                                setChecklist(c.checklist);
                                setSelectedIndex(c.section);
                                setGroup(c.group);
                                setNewCheckList('');
                            }}>
                                <Text fontWeight={'900'} fontSize={'16px'}>{c.section}</Text>
                            </Card>
                            )}
                            </Box>
                        </Box>

                ))}
            </Box>

            <Card mt={'20px'} border={'1px solid #000'}>
                <Flex background={'#1b73e8'} p={'8px'}>
                    <Text w={step===2?'42%':'40%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        Activities
                    </Text>
                    {step===7 && (

                    <Text w={'7%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        पहिलाको अवस्था
                    </Text>
                        )}
                    <Text w={'10%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        आफै सक्छ
                    </Text>
                    <Text w={'10%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        कठिनाई छ
                    </Text>
                    <Text w={'10%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        उसलाई मिल्दैन
                    </Text>
                    <Text w={step===2?'20%':'15%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        कैफियत
                    </Text>
                    <Text w={'8%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'}>
                        Action
                    </Text>
                </Flex>
            {checklist.length >0&& checklist.map((c,index)=>
                <Flex mt={'12px'} p={'8px'} alignItems={'center'}>
                    <Text w={step===2?'42%':'40%'} fontWeight={'900'} fontSize={'16px'} mr={"6px"}>
                        {c.question}
                    </Text>
                    {step===7 && (
                    <Text w={'7%'} fontWeight={'900'} fontSize={'16px'} mr={"6px"}>
                        {getStepTwoAnswer(c.questionId)}
                    </Text>
                    )}
                    {c.options.map((o)=>
                        <Flex w={'10%'} alignItems={'center'}>
                            <Checkbox  colorScheme='green'
                                       borderColor={'blue.500'}
                                       _disabled={{color:'green',border:'2px solid',borderColor: 'var(--chakra-colors-blue-500)'}}
                                       isChecked={checkBoxAnswers.get(c.questionId)===o}
                                       isDisabled={isCompleted || !canAddStudent()}
                                       onChange={(e)=>{
                                           setCheckBoxAnswers((prevMap) => {
                                               const newMap = new Map(prevMap);
                                               newMap.set(c.questionId,o)
                                               return newMap;

                                           });
                                           setQuestionID(c.questionId);
                                           const answer={
                                               questionId:c.questionId,
                                               iepId:iepId,
                                               type:"YES_NO",
                                               content:[o],
                                               remarks:""

                                           }
                                           handleSave(answer,c.questionId)
                                           }

                                       }/>
                        </Flex>
                    )}

                    <Box w={'20%'}>
                            <Input
                                isDisabled={isCompleted || !canAddStudent()}
                                _disabled={{background:  '#f5f1f1',color:'#000000'}}
                                value={remarks.get(c.questionId) || ''}
                                onChange={(e) => {

                                    setRemarks((prevMap) => {
                                        const newMap = new Map(prevMap);
                                        newMap.set(c.questionId,e.target.value)
                                        return newMap;

                                    });
                                }}

                                variant={"filled"} placeholder={"कैफियत"} required/>
                    </Box>
                    {remarks.get(c.questionId) && (
                    <Box w={'8%'} fontWeight={'900'} fontSize={'20px'} color={'#ffffff'} ml={'12px'}>
                        <Button onClick={()=>{
                            setQuestionID(c.questionId);
                            const answer={
                                questionId:c.questionId,
                                iepId:iepId,
                                type:"YES_NO",
                                content:[checkBoxAnswers.get(c.questionId)],
                                remarks:remarks.get(c.questionId)

                            }
                            handleSave(answer,c.questionId)
                        }}
                               colorScheme={"green"} isLoading={c.questionId===questionID} isDisabled={!checkBoxAnswers.get(c.questionId) || isCompleted}
                        >Done</Button>

                    </Box>
                        )}
                    {c.custom && (
                    <MdDelete size="20px" color={"red"} style={{marginLeft:'8px'}} cursor={'pointer'} onClick={()=>{
                        setqId(c.questionId);
                        onOpen();
                    }}/>
                        )}

                </Flex>
            )}
                {step===2 && (
                <Text mt={'12px'} pl='8px' fontWeight={'900'} fontSize={'16px'} mr={"6px"} >Add New Check List</Text>
                    )}
                {step===2 && (
                <Flex p={'8px'}>
                    <Box w={'92%'}>
                    <Input

                        isDisabled={isCompleted || !canAddStudent()}

                        value={newCheckList}
                        onChange={(e) => {

                           setNewCheckList(e.target.value)
                        }}

                        variant={"filled"} placeholder={"New Check List"} required/>
                    </Box>
                    <Box w={'8%'} ml={'12px'}>
                        <Button  colorScheme={"green"} onClick={onAddNewQuestions} isLoading={loading} isDisabled={loading || newCheckList===''}>Add</Button>
                    </Box>
                </Flex>
                    )}
            </Card>

            <Flex mt={'16px'} justifyContent={'flex-end'}>

                <Button colorScheme='green' size='lg' mr={'12px'}  onClick={()=>onOpen()}>
                    {isCompleted?t('redoStep'):t('completeStep')}
                </Button>


                <Button colorScheme='gray' size='lg' mr={'12px'}  onClick={()=>navigate(-1)}>
                    {t('back')}
                </Button>
            </Flex>

            <CoustomAlertDialog
                onClose={handleClose}
                onOpen={onOpen}
                isOpen={isOpen}
                onMainAction={onCompleteIEP}
                loading={loading}
                warning={qId?false:true}
            />

        </Box>
    )
}
